import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { useRoadStore } from './road'

import { fetchWrapper } from '@/api/fetchApi'
import useSegments from '@/composition/useSegments'
import useTabs from '@/composition/useTabs'

import { RoadSegment } from '@/types/Road'
import { Tabs } from '@/types/ui/Tabs'

const { sortSegmentsById } = useSegments()
const { dataToTabs } = useTabs()

interface SegmentState {
  segments: {
    data: RoadSegment[]
    current?: number
  }
  tabs: {
    data: Tabs[]
  }
}

export const useSegmentsStore = defineStore('road-segments', () => {
  const roadStore = useRoadStore()

  const state: SegmentState = reactive({
    segments: {
      data: [],
      current: undefined
    },
    tabs: {
      data: []
    }
  })

  const roadId = computed(() => roadStore.getRoad?.id)

  const segments = computed(() => state.segments.data)
  const currentSegment = computed(() => state.segments.current)
  const tabs = computed(() => state.tabs.data)
  const currentTab = computed(() => tabs.value.find((tab) => tab.id === currentSegment.value))

  function setSegments(roadSegments: RoadSegment[]) {
    state.segments.data = sortSegmentsById(roadSegments)

    const [first] = segments.value
    if (first) state.segments.current = first.id

    state.segments.current = segments.value.at(0)?.id

    state.tabs.data = dataToTabs({
      data: segments.value,
      key: 'name',
      active: currentSegment.value
    })
  }

  async function fetchSegments() {
    if (!roadId.value) throw new Error('Нет id дороги')

    try {
      const response = (await fetchWrapper.get(`/roads/${roadId.value}/segments/`)) as RoadSegment[]

      if (!response.length) return

      setSegments(response)
    } catch (e) {
      throw new Error(e)
    }
  }

  function changeSegment(id: number) {
    if (id !== currentSegment.value) state.segments.current = id
  }

  return {
    segments,
    currentSegment,
    tabs,
    currentTab,

    setSegments,
    fetchSegments,

    changeSegment
  }
})
