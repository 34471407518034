import { MapGeoJSONFeature } from 'maplibre-gl'
import { GEOMETRY_CONSTANTS } from '@/utils/consts/consts'
import { azimuth } from '@/utils/azimuth'

import { GostData, SignInCluster } from '@/types/RoadSign'
import { SignGost } from '@/utils/lists/sign-road-gost'

class GeometryCreateSign {
  signsWithText: string[] = [
    SignGost.STEEP_DESCENT,
    SignGost.THE_STEEP_CLIMB,
    SignGost.TRUCK_TRAFFIC_IS_PROHIBITED,
    SignGost.WEIGHT_LIMITATION,
    SignGost.LIMITATION_OF_MASS_PER_AXLE_OF_VEHICLES,
    SignGost.HEIGHT_LIMITATION,
    SignGost.WIDTH_LIMITATION,
    SignGost.LENGTH_LIMITATION,
    SignGost.MINIMUM_DISTANCE_LIMITATION,
    SignGost.MAXIMUM_SPEED_LIMIT,
    SignGost.END_OF_MAXIMUM_SPEED_LIMIT_ZONE,
    SignGost.MINIMUM_SPEED_LIMIT,
    SignGost.END_MINIMUM_SPEED_LIMIT,
    SignGost.START_LOCALITY,
    SignGost.END_LOCALITY,
    SignGost.BEGINNING_SETTLEMENT,
    SignGost.END_SETTLEMENT,
    SignGost.MAXIMUM_SPEED_ZONE,
    SignGost.END_MAXIMUM_SPEED_ZONE,
    SignGost.RECOMMENDED_SPEED,
    SignGost.TURNING_AREA,
    SignGost.ADVANCE_DIRECTION_INDICATOR,
    SignGost.ADVANCE_DIRECTION_INDICATOR_2,
    SignGost.DIRECTIONS_INDICATOR,
    SignGost.DIRECTIONS_INDICATOR_2,
    SignGost.OBJECT_NAME,
    SignGost.DISTANCE_INDICATOR,
    SignGost.KILOMETER_SIGN,
    SignGost.ROUTE_NUMBER,
    SignGost.ROUTE_NUMBER_2,
    SignGost.DISTANCE_TO_OBJECT,
    SignGost.DISTANCE_TO_OBJECT_2,
    SignGost.DISTANCE_TO_OBJECT_RIGHT,
    SignGost.DISTANCE_TO_OBJECT_LEFT,
    SignGost.COVERAGE_AREA,
    SignGost.COVERAGE_AREA_2,
    SignGost.COVERAGE_AREA_RIGHT,
    SignGost.COVERAGE_AREA_LEFT,
    SignGost.DAYS_OF_THE_WEEK,
    SignGost.TIME_OF_ACTION,
    SignGost.WEEKEND_TIME,
    SignGost.WORK_DAYS_TIME,
    SignGost.DAYS_OF_THE_WEEK_TIME,
    SignGost.PARKING_DURATION_LIMITATION,
    SignGost.MAXIMUM_WEIGHT_LIMIT,
    SignGost.DANGEROUS_GOODS_CLASS,
    SignGost.ECOLOGICAL_CLASS_OF_THE_VEHICLE
  ]
  onlyText: string[] = [
    SignGost.DAYS_OF_THE_WEEK,
    SignGost.TIME_OF_ACTION,
    SignGost.DISTANCE_INDICATOR,
    SignGost.OBJECT_NAME,
    SignGost.DIRECTIONS_INDICATOR,
    SignGost.DIRECTIONS_INDICATOR_2,
    SignGost.START_LOCALITY,
    SignGost.END_LOCALITY,
    SignGost.DISTANCE_TO_OBJECT,
    SignGost.DISTANCE_TO_OBJECT_2,
    SignGost.DISTANCE_TO_OBJECT_RIGHT,
    SignGost.DISTANCE_TO_OBJECT_LEFT,
    SignGost.ADVANCE_DIRECTION_INDICATOR,
    SignGost.ADVANCE_DIRECTION_INDICATOR_2,
    SignGost.COVERAGE_AREA,
    SignGost.COVERAGE_AREA_2,
    SignGost.COVERAGE_AREA_RIGHT,
    SignGost.COVERAGE_AREA_LEFT,
    SignGost.KILOMETER_SIGN,
    SignGost.BEGINNING_SETTLEMENT,
    SignGost.END_SETTLEMENT
  ]

  createSign(current: GostData, markerWrapper: HTMLElement, el: SignInCluster | MapGeoJSONFeature) {
    const div = document.createElement('div')

    this.addClasses(current, div)
    this.setDatasetToSign(div, el)

    this.appendTextToSign(el, div)

    const image = this.createImage(current)

    if (image) div.appendChild(image)

    const wrapper = this.getWrapper(current, markerWrapper)

    if (wrapper) wrapper.appendChild(div)
  }

  appendTextToSign(el: SignInCluster | MapGeoJSONFeature, div: HTMLElement) {
    const hasText = this.signsWithText.find(
      (gost) =>
        gost ===
        ((el as SignInCluster).gost ||
          (el as MapGeoJSONFeature).properties.sign_gost ||
          (el as MapGeoJSONFeature).properties.gost)
    )

    if (!hasText) return

    this.withText(div, el)
  }

  addClasses(current: GostData, div: HTMLElement) {
    const { name, width } = current

    const boxType = width === GEOMETRY_CONSTANTS.BOX_TYPE_2 ? 'type-2' : 'type-1'

    div.classList.add('road-sign', boxType)

    if (name) div.classList.add(name)
  }

  getWrapper(current: GostData, markerWrapper: HTMLElement) {
    return current.isSemaphore ? markerWrapper.children[0] : markerWrapper.children[1]
  }

  createImage(current: GostData) {
    if (!current.img) return

    const image = new Image(current.width)

    image.setAttribute('src', current.img)

    image.style.display = 'block'
    image.removeAttribute('width')
    image.removeAttribute('height')

    return image
  }

  setStyleToSign(div: HTMLDivElement) {
    div.style.display = 'flex'
    div.style.justifyContent = 'center'
  }

  setDatasetToSign(div: HTMLDivElement, el: SignInCluster | MapGeoJSONFeature) {
    this.setAzimuth(div, el)
    this.setOrder(div, el)
  }

  setAzimuth(div: HTMLDivElement, el: SignInCluster | MapGeoJSONFeature) {
    const azimuthValue =
      (el as MapGeoJSONFeature).properties?.sign_azimuth || (el as SignInCluster).azimuth

    if (azimuthValue) {
      div.dataset.azimuth = `${azimuth(+azimuthValue)}`
    }
  }

  setOrder(div: HTMLDivElement, el: SignInCluster | MapGeoJSONFeature) {
    const order = (el as MapGeoJSONFeature).properties?.height || (el as SignInCluster).order

    div.dataset.order = order

    const number = (el as MapGeoJSONFeature).properties?.sign_number || (el as SignInCluster).order

    div.dataset.order = order
    div.dataset.number = number
  }

  withText(markerWrapper: HTMLElement, feature: MapGeoJSONFeature | SignInCluster) {
    const textWrapper = document.createElement('span')
    textWrapper.classList.add('road-sign-text')

    const gost = this.onlyText.includes((feature as SignInCluster)?.gost)
    const signGost = this.onlyText.includes((feature as MapGeoJSONFeature).properties?.sign_gost)
    const propGost = this.onlyText.includes((feature as MapGeoJSONFeature).properties?.gost)

    if (gost || signGost || propGost) {
      textWrapper.classList.add('relative')
    }

    const text = this.defineText(feature)

    textWrapper.innerHTML = text
    markerWrapper.appendChild(textWrapper)
  }

  defineText(feature: MapGeoJSONFeature | SignInCluster) {
    let text

    const zipData =
      (feature as SignInCluster).zip || (feature as MapGeoJSONFeature).properties?.zip_data

    if (zipData) {
      const data = JSON.parse(zipData)

      text = Array.isArray(data)
        ? data.map((el) => `${el.name} ${el.distance || ''}<br>`).join('')
        : data
    }

    if (!text) {
      text = (feature as SignInCluster).text
    }

    if (!text) {
      text = (feature as MapGeoJSONFeature).properties?.sign_text
    }

    if (!text) {
      text = (feature as MapGeoJSONFeature).properties?.value
    }

    return text || ''
  }
}

export default GeometryCreateSign
