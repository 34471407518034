<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import * as echarts from 'echarts'

const props = defineProps<{
  data: echarts.SeriesOption[]
  colors: string[]
  tooltip: object
  xAxis: object
  yAxis: object
  labelStyle: object
}>()

const candleStickElement = ref()
let candleStick: echarts.ECharts

const data = computed(() => props.data)
const tooltip = computed(() => props.tooltip)
const colors = computed(() => props.colors)
const xAxis = computed(() => props.xAxis)
const yAxis = computed(() => props.yAxis)

const optionCandlestick = computed(() => ({
  tooltip: tooltip.value,
  color: colors.value,
  xAxis: xAxis.value,
  yAxis: yAxis.value,
  series: data.value
}))

function resizeChart() {
  if (candleStick) candleStick.resize()
}

onMounted(() => {
  candleStick = echarts.init(candleStickElement.value, null, {
    renderer: 'canvas'
  })

  candleStick.setOption(optionCandlestick.value)

  window.addEventListener('resize', resizeChart)
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeChart)
})
</script>

<template>
  <div ref="candleStickElement" class="chart-item__chart" />
</template>

<style lang="scss"></style>
