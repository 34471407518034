import { useDefectChartStore } from '@/stores/road/defects/road-defects-chart'
import { ECHARTS_ACTION } from '@/utils/lists/lists'

import { ChartData, SelectedChartItem } from '@/types/ui/Chart'
import { ECharts } from 'echarts'
import { computed } from 'vue'

function highlightPoint(chart: ECharts, points: SelectedChartItem[]) {
  points.forEach((point) => {
    const { dataIndex, seriesName } = point

    chart.dispatchAction({
      dataIndex,
      seriesName,
      type: ECHARTS_ACTION.HIGHLIGHT
    })
  })
}

function downplayPoint(chart: ECharts, points: SelectedChartItem[]) {
  points.forEach((point) => {
    const { dataIndex, seriesName } = point

    chart.dispatchAction({
      dataIndex,
      seriesName,
      type: ECHARTS_ACTION.DOWNPLAY
    })
  })
}

function updateChart(event: SelectedChartItem[]) {
  const chartDefectsStore = useDefectChartStore()
  const chartData = computed(() => chartDefectsStore.chartData)

  const indexes: number[] = []
  const selectedItems = event.filter(
    (el) => el.data?.length === 2 && el.seriesIndex != undefined && el.seriesName
  )

  for (const selected of selectedItems) {
    const { seriesIndex, data } = selected
    const [position, max_square] = data!

    const currentChartData: ChartData | undefined = chartData.value[seriesIndex!]
    if (!currentChartData) continue

    const dataIndex = currentChartData.data.findIndex(
      ([pos, sq]) => pos === position && sq === max_square
    )
    indexes.push(dataIndex)
  }

  chartDefectsStore.setSelected(
    event.map((selected, index) => ({
      dataIndex: indexes[index] > -1 ? indexes[index] : -1,
      seriesName: selected.seriesName,
      seriesIndex: selected.seriesIndex,
      data: selected.data
    }))
  )
}

export default function useChartHighlight() {
  return {
    updateChart,
    downplayPoint,
    highlightPoint
  }
}
