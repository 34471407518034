import { ItemNav } from '@/types/ui/Nav'

class Nav {
  public getTree(navigation: ItemNav[]) {
    const root = navigation.filter((el) => !el.parentId)
    const nested = navigation.filter((el) => el.parentId)

    this.setNestedToRoot(root, nested)

    return root
  }

  public getNestedIds(nested: ItemNav[]) {
    return [...new Set(nested.map((el) => el.parentId))]
  }

  public setNestedToRoot(root: ItemNav[], nested: ItemNav[]) {
    const nestedId = this.getNestedIds(nested)

    let i = nestedId.length - 1

    while (nestedId.length !== 0) {
      const item = nestedId[i]

      const current = root.find((el) => el.id === item)

      if (current) current.children = nested.filter((el) => el.parentId === item)

      nestedId.pop()

      i--
    }
  }

  public getItemNav(id: string, navigation: ItemNav[]) {
    return navigation.find((el) => el.id === id)
  }

  public clearNavItems(navigation: ItemNav[]) {
    navigation.forEach((item) => (item.active = false))
  }
}

export default Nav
