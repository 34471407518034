import useDefects from './useDefects'
import { uniqFieldsArray } from '@/utils/uniq-fields-array'

import { RoadNeiroDefect } from '@/types/RoadDiagnostic'
import { LimitFilter } from '@/types/Defects'

const { defectsToInfo } = useDefects()

/**
 * Вычисление максимальных/минимальных значений для фильтра
 */
function calcLimitValues(prefix: 'max' | 'min', params: LimitFilter) {
  const { defects, name, payload } = params

  const defectsParams = defectsToInfo(defects)

  payload.data = defectsParams.filter((el) => el.class_name === name)

  const width = `${prefix}_width`
  const length = `${prefix}_length`
  const square = `${prefix}_square`

  payload[width] = Math[prefix](...payload.data.map((el) => el[width]))
  payload[length] = Math[prefix](...payload.data.map((el) => el[length]))
  payload[square] = Math[prefix](...payload.data.map((el) => el[square]))

  delete payload.data
}

/**
 * Определение payload фильтра
 */
function definePayload(params: LimitFilter) {
  const { defects, payload } = params

  calcLimitValues('max', params)
  calcLimitValues('min', params)

  return defects.length ? { ...payload } : {}
}

function createLinesOptions(defects: RoadNeiroDefect[]) {
  const lines = uniqFieldsArray<RoadNeiroDefect, number>(defects, 'line_number')

  return lines.map((line, index) => {
    const counter = index + 1

    return {
      value: line,
      title: `Полоса ${counter}`,
      id: counter,
      active: false
    }
  })
}

export default function () {
  return {
    calcLimitValues,
    definePayload,
    createLinesOptions
  }
}
