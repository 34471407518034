import useDefects from '@/composition/defects/useDefects'

import type { DefectTypeMaskStyle, OptionsMask, RoadNeiroDefect } from '@/types/RoadDiagnostic'
import type { ToChartDataParams } from '@/types/ui/Chart'
import type { ComputedRef } from 'vue'

const { updateDefectInfo, defectsToInfo, removeZeroPos } = useDefects()

/**
 * Преобразование данных дефектов в данные для диаграммы
 * */
function defectsToChart(params: ToChartDataParams, roadId: number) {
  const { defects, names, currentSegment } = params

  const segmentDefects = defects.filter((el) => el.segmentId === currentSegment.id)
  const existed = removeZeroPos(segmentDefects)
  const updated: RoadNeiroDefect[] = updateDefectInfo(existed, roadId)
  const defectsInfos = defectsToInfo(updated)

  return names.map((defectName) => ({
    name: defectName,
    data: defectsInfos
      .filter((el) => el.class_name === defectName)
      .map((el) => [el.position, el.max_square])
  }))
}

/**
 * Получение данных для легенды графика
 */
function defectLegends(
  defectTypes: ComputedRef<DefectTypeMaskStyle[]>,
  style: ComputedRef<OptionsMask[]>
) {
  const legends = defectTypes.value.filter((el) => el.show)

  return legends.map((el) => ({
    label: el.label,
    id: el.id,
    color: style.value?.find((item) => item.id === el.id)?.color
  }))
}

export default function () {
  return {
    defectsToChart,
    defectLegends
  }
}
