import { BOUNDS } from '../utils/consts/consts'
import { fetchWrapper } from '@/api/fetchApi'
import { MapFeatureRendered } from '@/types/map/Map'
import { RoadGeoJSON, RoadGeoJSONResponse, RoadsState } from '@/types/Road'
import { FeatureState } from 'maplibre-gl'
import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { LocationQueryValue } from 'vue-router'

export const useRoadsStore = defineStore('roads', () => {
  const state: RoadsState = reactive({
    roads: undefined,
    bbox: BOUNDS.KUZBASS,
    roadInfoMenuIsOpen: false,
    interactiveFeature: undefined,
    ortos: undefined
  })

  const roads = computed(() => state.roads)
  const ortos = computed(() => state.ortos)

  async function fetchAllRoads(idRoad?: LocationQueryValue | LocationQueryValue[]) {
    try {
      const roads = (await fetchWrapper.get('/roads/geojson/')) as RoadGeoJSON

      if (idRoad) {
        const roadBbox = await fetchWrapper.get(`/roads/${idRoad}/geojson`)

        state.bbox = (roadBbox as RoadGeoJSONResponse).bbox
      } else {
        state.bbox = BOUNDS.KUZBASS
      }

      roads.features = roads.features.map((el: FeatureState) => ({
        ...el,
        properties: {
          id: el.geometry?.id
        }
      }))

      state.roads = roads
    } catch (e) {
      console.error(e)
    }
  }

  const infoMenuIsOpen = computed(() => state.roadInfoMenuIsOpen)
  const interactiveFeature = computed(() => state.interactiveFeature)

  function toggleRoadInfoMenu() {
    state.roadInfoMenuIsOpen = !state.roadInfoMenuIsOpen
  }

  function setInteractiveFeature(feature?: MapFeatureRendered) {
    state.interactiveFeature = feature
  }

  const roadsGeoJSON = computed(() => state.roads)
  const bbox = computed(() => state.bbox)

  return {
    roadsGeoJSON,
    bbox,
    interactiveFeature,
    roads,
    ortos,

    infoMenuIsOpen,
    setInteractiveFeature,

    toggleRoadInfoMenu,

    fetchAllRoads
  }
})
