<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { onMounted, computed, ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useAuthStore, useUserStore, useThemeStore, useMapStore } from '@/stores'

import useTheme from '@/composition/useTheme'
import { useExportRoadStore } from '@/stores/export'
import { APP_THEMES } from '@/utils/lists/lists'

import PopupSkdfLogin from '@/components/layouts/PopupSkdfLogin.vue'
import AppTitle from '@/components/layouts/header/AppTitle.vue'
import IconProfile from '@/components/icons/IconProfile.vue'
import IconBase from '@/components/icons/IconBase.vue'
import IconLogout from '@/components/icons/IconLogout.vue'
import AppPopup from '@/components/ui/AppPopup.vue'
import IconTheme from '@/components/icons/IconTheme.vue'

const route = useRoute()

const { logout } = useAuthStore()
const userStore = useUserStore()
const themeStore = useThemeStore()
const mapStore = useMapStore()
const exportRoadStore = useExportRoadStore()

const themeWrapper = new useTheme()

const { VITE_MAPBOX_STYLE_SCHEME_DARK, VITE_MAPBOX_STYLE_SCHEME_LIGHT } = import.meta.env

const { skdfForm, skdfToken } = storeToRefs(exportRoadStore)
const { user: userData } = storeToRefs(userStore)

const authIsOpen = ref(false)
const username = computed<string>(() => userStore.getName)
const theme = computed(() => themeStore.theme)
const isLightTheme = computed(() => theme.value !== APP_THEMES.DARK)
const currentMapStyle = computed(() => mapStore.currentStyle)

if (route.name !== 'Login' && userData.value?.is_admin) {
  setInterval(() => {
    exportRoadStore.checkSkdfToken()
  }, 1_000)
}

onMounted(async () => {
  themeWrapper.animateWhiteTheme(theme.value)
})

function toggleTheme() {
  const selectedTheme = isLightTheme.value ? APP_THEMES.DARK : APP_THEMES.LIGHT

  themeStore.changeTheme(selectedTheme)
  changeMapStyle()
  themeWrapper.animateWhiteTheme(theme.value)
}

function changeMapStyle() {
  const needChangeMapStyle =
    currentMapStyle.value === VITE_MAPBOX_STYLE_SCHEME_DARK ||
    currentMapStyle.value === VITE_MAPBOX_STYLE_SCHEME_LIGHT

  if (needChangeMapStyle) {
    mapStore.setCurrentStyle(
      isLightTheme.value ? VITE_MAPBOX_STYLE_SCHEME_LIGHT : VITE_MAPBOX_STYLE_SCHEME_DARK
    )
  }
}

function openLoginSkdfModal() {
  authIsOpen.value = true
}

function closeModal() {
  authIsOpen.value = false

  skdfForm.value.username = ''
  skdfForm.value.password = ''
}
</script>

<template>
  <header class="header">
    <AppTitle />
    <nav class="header__nav">
      <div class="header__user">
        <div class="header__profiles">
          <RouterLink
            :to="'/profile'"
            class="header__item header-user__item header-user__item--profile"
            aria-label="Профиль пользователя"
          >
            <div class="header__icon">
              <IconBase width="25" height="25" view-box="12.5 12.5 25 25">
                <IconProfile />
              </IconBase>
            </div>
            <span> Профиль </span>
          </RouterLink>
          <div
            v-if="userData?.is_admin && route.name === 'Admin'"
            class="header__item header__item--skdf"
            @click="openLoginSkdfModal"
          >
            <div class="header__icon">
              <IconBase width="25" height="25" view-box="12.5 12.5 25 25">
                <IconProfile />
              </IconBase>
            </div>
            <span v-if="skdfToken">{{ JSON.parse(skdfToken).profile?.sub }}</span>
            <span v-else>Авторизация в СКДФ</span>
          </div>
        </div>

        <div class="header__item header-user__item header-user__item--logout">
          <span>{{ username }}</span>
          <div class="header__icon" @click="logout">
            <IconBase width="12" height="16" view-box="0 0 12 16">
              <IconLogout />
            </IconBase>
          </div>
        </div>
      </div>
      <div class="header__item header-user__item header-user__item--theme" @click="toggleTheme">
        <div class="header__icon">
          <IconBase width="25" height="25" view-box="12.5 12.5 25 25">
            <IconTheme />
          </IconBase>
        </div>
      </div>
    </nav>
  </header>
  <AppPopup title="Авторизация в СКДФ" :is-open="authIsOpen" @close-modal="closeModal">
    <PopupSkdfLogin @close-modal="closeModal" />
  </AppPopup>
</template>

<style lang="scss" scoped>
.header {
  display: grid;
  position: relative;
  z-index: 5;
  grid-template-columns: 55.31rem max-content;
  justify-content: space-between;
  height: 115px;
  padding: 1.53rem 0;
  color: $c-white;

  &__profiles {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 35px;
  }

  &__icon {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    transition: all 0.2s $main-animation 0.1s;
    border-radius: 100%;

    @include background-color('headerNavIconBG');

    cursor: pointer;

    svg {
      transition: all 0.2s $main-animation;

      @include fill('headerNavIcon');
    }

    &:hover {
      transition: all 0.2s $main-animation;

      @include background-color('headerNavIconHover');
    }
  }

  &__nav {
    display: grid;
    grid-template-columns: 1fr 50px;
    gap: 50px;
    align-items: center;
    justify-content: end;

    a {
      font-weight: 700;
      text-decoration: none;
    }
  }

  &__user {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 170px;
  }

  &__item {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 20px;
    align-items: center;
    justify-content: start;
    width: max-content;
    transition: all 0.2s $main-animation 0.1s;

    @include color('fontColor');

    &.header-user__item--theme {
      .header__icon svg {
        position: relative;
        top: -1px;
        left: -0.5px;
        transition: all 0.2s $main-animation;

        @include fill('fontColor');
      }
    }

    &:hover {
      &:not(.header-user__item--logout) {
        .header__icon {
          transition: all 0.2s $main-animation;

          @include background-color('headerNavIconHover');

          svg {
            fill: $c-orange;
          }
        }
      }
    }

    &--skdf {
      cursor: pointer;

      &.disabled {
        pointer-events: none;
      }
    }
  }
}

.export {
  &__modal {
    display: grid;
    grid-gap: 45px 0;
    padding: 35px 45px;
    color: $c-white;
  }

  &__form {
    width: 450px;
  }

  &__input {
    height: 45px;
    margin-top: 15px;
  }

  &__item {
    &:last-child {
      margin-top: 35px;
    }
  }

  &__login {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 30px;
    justify-content: end;

    .export__btn {
      width: 150px;
      min-width: 150px;
      height: 40px;
      font-size: 16px;

      &--cancel {
        background-color: $c-gray-90;
      }
    }
  }

  &__name {
    font-size: 18px;
    font-weight: 800;
  }
}

:global(.export__cell--select .checkbox) {
  pointer-events: none;
}

:global(.export__row--header .export__cell--select .checkbox) {
  pointer-events: all;
}

@media (width <= 2030px) {
  .header {
    grid-template-columns: 1fr max-content;
    align-items: center;

    &__nav {
      grid-gap: 0 50px;
      grid-template-columns: 1fr 50px;
      justify-content: end;
    }

    &__outer {
      grid-column-gap: 0;
      width: max-content;
    }

    &__item {
      span {
        display: none;
      }
    }

    &__user {
      grid-gap: 0 25px;
      grid-template-columns: repeat(2, 50px);
    }
  }

  .header-user {
    &__item {
      &--logout {
        grid-template-columns: 50px;
        max-width: 280px;
      }

      &--profile {
        grid-column-gap: 0;
      }
    }
  }
}

@media (width <= 1940px) {
  .header {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
