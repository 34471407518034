import { CircleDiagramProps } from '@/types/ui/Diagrams'
import { DiagramTypes } from '@/utils/lists/lists'

class RenderDiagramValues {
  offset: number[] = []

  public render(options: CircleDiagramProps) {
    const diagramL = Math.PI * options.diametr

    const { style, type, wrapper, values, value } = options
    const { top, left } = style

    ;(wrapper as HTMLElement).style.top = `${top || 0}px`
    ;(wrapper as HTMLElement).style.left = `${left || 0}px`

    const isDiagram = type === DiagramTypes.DIAGRAM && values?.length
    const isScale = type === DiagramTypes.SCALE && value !== undefined

    if (isDiagram) {
      this.styleDiagramValues(options, values as Array<number>, diagramL)
    }

    if (isScale) {
      this.scaleRender(options, diagramL)
    }

    this.offset.length = 0
  }

  private styleDiagramValues(options: CircleDiagramProps, values: Array<number>, diagramL: number) {
    const diagrams = (options.wrapper as HTMLElement).querySelectorAll('circle')

    values.forEach((value, index) => this.addValue(value, index, diagrams, diagramL))
  }

  private addValue(
    value: number,
    index: number,
    diagrams: NodeListOf<SVGCircleElement>,
    diagramL: number
  ) {
    const diagram = diagrams[index]

    const dashArray = this.getDashArray(diagramL, value)
    this.offset.push(-dashArray)

    this.setDiagramStyle(diagram, dashArray, diagramL, index, false)
  }

  public scaleRender(options: CircleDiagramProps, diagramL: number) {
    const diagram = (options.wrapper as HTMLElement).querySelectorAll('circle')?.[1]
    const dashArray = this.getDashArray(diagramL, options.value as number)

    this.setDiagramStyle(diagram, dashArray, diagramL, 1, true)
  }

  public getDashArray(diagramL: number, value: number) {
    return (diagramL / 100) * value
  }

  private setDiagramStyle(
    diagram: SVGCircleElement,
    dashArray: number,
    diagramL: number,
    i: number,
    isScale: boolean
  ) {
    if (!this.offset.length && !isScale) return

    diagram.style.strokeDasharray = `${dashArray} ${diagramL}`

    const offsetValue = this.offset.slice(0, i).reduce((initial, current) => {
      return initial + current
    }, 0)

    diagram.style.strokeDashoffset = `${offsetValue}`
  }
}

export default RenderDiagramValues
