<script setup lang="ts">
import { computed, shallowRef, onBeforeMount, onUnmounted, onMounted, ref } from 'vue'

import AppPreloaderInteractive from './AppPreloaderInteractive.vue'

import { AccordionProps } from '@/types/ui/Accordion'

const props = defineProps<AccordionProps>()

const emits = defineEmits(['accordion-opened', 'accordion-mounted'])

const isLoading = ref(false)

const body = shallowRef(null)
const slot = shallowRef(null)
const isOpen = shallowRef(false)

const accordionClasses = computed(() => ({
  opened: isOpen.value
}))

const toggleAccordion = async () => {
  if (props.asyncCb && !isOpen.value) {
    isLoading.value = true

    await props.asyncCb?.(!isOpen.value)

    isLoading.value = false
  }

  isOpen.value = !isOpen.value

  if (isOpen.value) emits('accordion-opened', slot)

  changeBodyHeight()
}

const changeBodyHeight = () => {
  if (!(slot.value && body.value)) return

  const bodyElement = body.value as HTMLElement
  const slotElement = slot.value as HTMLElement

  if (isOpen.value) {
    const height = slotElement.offsetHeight

    bodyElement.style.maxHeight = `${height}px`
  } else {
    bodyElement.style.maxHeight = '0px'
  }
}

onBeforeMount(() => {
  window.addEventListener('resize', changeBodyHeight)
})

onUnmounted(() => {
  window.removeEventListener('resize', changeBodyHeight)
})

function setLoading(value: boolean) {
  isLoading.value = value
}

defineExpose({
  body,
  setLoading
})

onMounted(() => {
  emits('accordion-mounted')
})
</script>

<template>
  <div class="accordion" :class="[accordionClasses]">
    <div class="accordion__head" @click="toggleAccordion">
      <div class="accordion-head__left">
        <p class="accordion__title">
          {{ props.title }}
        </p>
        <span class="accordion__icon"></span>
      </div>
      <AppPreloaderInteractive
        v-show="isLoading"
        class="accordion__preloader"
        :transparent-bg="true"
      ></AppPreloaderInteractive>
    </div>
    <div ref="body" class="accordion__body">
      <div ref="slot" class="accordion__slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accordion {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 30px;

  &__head {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 0 15px;
    padding: 2.14rem;
    transition: all 0.1s linear;
    font-size: 1.43em;
    font-weight: 750;
    letter-spacing: 0;
    text-align: left;
    cursor: pointer;
  }

  &__icon {
    display: block;
    width: 2.86rem;
    height: 2.86rem;
    transition: all 0.25s ease-in-out;
  }

  &__body {
    height: 100%;
    max-height: 0;
    padding: 0 30px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
  }

  &__slot {
    transition: all 0.25s ease-in-out;
    opacity: 0;
  }

  &.opened {
    .accordion {
      &__head {
        &:hover {
          background-color: transparent;
          cursor: pointer;
        }
      }

      &__slot {
        transition: all 0.25s ease-in-out;
        opacity: 1;
      }
    }
  }
}

.accordion-head {
  &__left {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 0 20px;
  }
}
</style>

<style lang="scss">
.accordion {
  &__preloader {
    &.pre {
      position: relative;
      width: 20px;
      height: 20px;
    }

    svg.pre__icon {
      position: absolute;
      top: -72px;
      left: -86px;
      animation: preloader-accordion 0.9s steps(1, end) infinite;
    }
  }
}

@keyframes preloader-accordion {
  0% {
    transform: scale(0.12) rotate(0deg);
  }

  13% {
    transform: scale(0.12) rotate(45deg);
  }

  26% {
    transform: scale(0.12) rotate(90deg);
  }

  39% {
    transform: scale(0.12) rotate(135deg);
  }

  52% {
    transform: scale(0.12) rotate(180deg);
  }

  65% {
    transform: scale(0.12) rotate(225deg);
  }

  78% {
    transform: scale(0.12) rotate(270deg);
  }

  91% {
    transform: scale(0.12) rotate(315deg);
  }

  100% {
    transform: scale(0.12) rotate(360deg);
  }
}
</style>
