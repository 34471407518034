<script setup lang="ts">
import { Ref, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useDebounceFn } from '@vueuse/core'

import { exportRoadsApi } from '@/api/export'
import { useExportRoadStore } from '@/stores/export'
import { DEBOUNCE_INPUT_DELAY } from '@/utils/consts/consts'

import AppPopup from '@/components/ui/AppPopup.vue'
import AppPagination from '@/components/ui/AppPagination.vue'
import InputField from '@/components/ui/InputField.vue'
import AppCheckbox from '@/components/ui/AppCheckbox.vue'
import SkdfPopup from './SkdfPopup.vue'

import type { RoadResponse } from '@/types/Road'

const exportRoadStore = useExportRoadStore()

const { search, selectedRoads, pagination, roads, skdfToken } = storeToRefs(exportRoadStore)

const isOpen = ref(false)
const selectAllCb = ref(false)
const withoutSkdf: Ref<RoadResponse[]> = ref([])

async function changePage(page: number) {
  await exportRoadStore.fetchRoads(page)

  const selected = roads.value.filter((road) =>
    selectedRoads.value.find((selected) => selected.id === road.id)
  )

  selectAllCb.value = !!selected.length
}

function startSearch(event: InputEvent) {
  const t = event.target as HTMLInputElement
  search.value = t.value

  exportRoadStore.fetchRoads()
}

const searchRoad = useDebounceFn(startSearch, DEBOUNCE_INPUT_DELAY)

function select(road: RoadResponse) {
  const index = selectedRoads.value.findIndex((selected) => selected.id === road.id)

  if (index > -1) {
    selectedRoads.value.splice(index, 1)
  } else {
    selectedRoads.value.push(road)
  }
}

function selectAll(e: Event) {
  const t = e.target as HTMLInputElement
  selectAllCb.value = t.checked

  if (selectAllCb.value) {
    selectedRoads.value = [...new Set([...selectedRoads.value, ...roads.value])]
  } else {
    const selectedRoadsOnPage = selectedRoads.value.filter((selected) =>
      roads.value.find((road) => selected.id === road.id)
    )

    selectedRoadsOnPage.forEach((selected) => {
      const index = selectedRoads.value.indexOf(selected)
      if (index > -1) selectedRoads.value.splice(index, 1)
    })
  }
}

function clear() {
  selectedRoads.value.length = 0
  selectAllCb.value = false

  exportRoadStore.fetchRoads()
}

function selectedOnly() {
  roads.value = selectedRoads.value

  pagination.value = Object.assign(pagination.value, {
    current: 1,
    lastPage: 1
  })
}

async function updateRoads() {
  const withSkdf = selectedRoads.value.filter((el) => el.skdf_number)

  if (withSkdf.length !== selectedRoads.value.length) {
    isOpen.value = true
    withoutSkdf.value = selectedRoads.value.filter((el) => !el.skdf_number)
  } else {
    dataToSkdf()
  }
}

function closeModal() {
  isOpen.value = false
}

function fillSkdf(params: { value: string; id: number }) {
  const road = selectedRoads.value.find((el) => el.id === params.id)

  if (road) road.skdf_number = params.value
}

function removeSelected(id: number) {
  withoutSkdf.value = withoutSkdf.value.filter((el) => el.id !== id)
  selectedRoads.value = selectedRoads.value.filter((el) => el.id !== id)
}

async function dataToSkdf() {
  closeModal()

  if (exportRoadStore.skdfToken) {
    const token = JSON.parse(exportRoadStore.skdfToken)

    for (let i = 0; i < selectedRoads.value.length; i++) {
      exportRoadsApi.sendGeometryToSkdf({
        token: token.access_token,
        roadId: +selectedRoads.value[i].id,
        idSkdf: +selectedRoads.value[i].skdf_number!
      })
    }
  }
}
</script>

<template>
  <div class="export">
    <h2 class="export__title">Экспорт дорог в СКДФ</h2>
    <section class="export__nav">
      <InputField
        class="export__search"
        type="search"
        :model-value="search"
        placeholder="Найти..."
        @input="searchRoad"
      />
      <div v-if="selectedRoads.length && skdfToken" class="export__selection">
        <span class="export__clear-select" @click="clear"> Отменить выделение </span>
        <span class="export__select-only" @click="selectedOnly"> Только выделенные </span>
        <span class="export__select-only" @click="updateRoads"> Экспортировать данные </span>
      </div>
    </section>
    <section class="export__table">
      <div class="export__row export__row--header">
        <div class="export__cell export__cell--select">
          <AppCheckbox name="" :checked="selectAllCb" @toggle-checkbox="selectAll" />
        </div>
        <div class="export__cell export__cell--name">Наименование</div>
        <div class="export__cell">Идентификационный номер</div>
        <div class="export__cell">Учетный номер</div>
        <div class="export__cell">СКДФ номер</div>
      </div>
      <div
        v-for="(road, index) in roads"
        :key="index + '-export-road'"
        class="export__row"
        @click="() => select(road)"
      >
        <div class="export__cell export__cell--select">
          <AppCheckbox
            name=""
            :checked="!!selectedRoads.find((selected) => selected.id === road.id)"
          />
        </div>
        <div class="export__cell export__cell--name" :title="road.short_name">
          <span>{{ road.name }}</span>
        </div>
        <div class="export__cell">{{ road.identification_number }}</div>
        <div class="export__cell">{{ road.account_number }}</div>
        <div class="export__cell">{{ road.skdf_number }}</div>
      </div>
    </section>
    <section class="export__pagination">
      <AppPagination
        :current="pagination.current"
        :max="pagination.lastPage"
        :left="5"
        :navigation="true"
        @change-page="changePage"
      ></AppPagination>
    </section>
  </div>
  <AppPopup :is-open="isOpen" title="Заполните недостающие данные!" @close-modal="closeModal">
    <SkdfPopup
      :roads="withoutSkdf"
      @fill-skdf="fillSkdf"
      @remove-selected="removeSelected"
      @to-skdf="dataToSkdf"
    ></SkdfPopup>
  </AppPopup>
</template>

<style lang="scss" scoped>
.export {
  display: grid;
  align-content: start;
  height: 100%;
  min-height: calc(100vh - 115px - 3.75rem - 75px);
  margin-block-end: 45px;

  &__title {
    color: $c-white;
  }

  &__nav {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    height: 45px;
    margin-block-start: 65px;
  }

  &__table {
    display: grid;
    margin-block-start: 40px;
    color: $c-white;
  }

  &__cell {
    display: grid;
    align-items: center;
    justify-content: center;
    padding-block: 10px;
    padding-inline: 15px;
    border-right: 1px solid $c-gray-20;

    &--select {
      border-left: 1px solid $c-gray-20;
    }

    &--name {
      justify-content: start;
      line-height: 28px;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  &__selection {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 15px;
    width: max-content;
    margin-block-start: 20px;
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 50px 1fr 220px 180px 180px;
    height: auto;
    min-height: 60px;
    transition: all 0.1s linear;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid $c-gray-20;
    cursor: pointer;

    &--header {
      height: auto;
      background-color: rgba($c-gray-90, 0.1);
      font-size: 18px;
      font-weight: 800;
      cursor: default;

      .export__cell {
        text-align: center;

        &--name {
          justify-content: center;
          color: $c-white;
        }
      }
    }

    &:hover {
      background-color: rgba($c-gray-90, 0.03);
    }
  }

  &__search {
    display: grid;
    width: 450px;
    height: 100%;
    font-family: $f-family-base;
  }

  &__buttons {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 35px;
  }

  &__button {
    height: 100%;
    color: $c-white;
    font-size: 17px;

    &--clear {
      width: 300px;
    }
  }

  &__clear-select,
  &__select-only {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid $c-orange;
    background-color: transparent;
    color: $c-gray-90;
    cursor: pointer;
  }

  &__pagination {
    margin-block-start: 45px;
  }
}
</style>
