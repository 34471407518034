<script lang="ts" setup>
import { useToastStore } from '@/stores'

import AppToastsItem from '@/components/ui/AppToastsItem.vue'

const DURATION = 3000

const { toasts } = useToastStore()
</script>

<template>
  <Teleport to="#app">
    <div class="toasts">
      <AppToastsItem
        v-for="(toast, key) of toasts"
        :key="key"
        :type="toast.type"
        :title="toast.title"
        :text="toast.text"
        :duration="toast.duration === -1 ? DURATION : (toast.duration as number)"
      />
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.toasts {
  position: fixed;
  z-index: 9999;
  right: 2rem;
  bottom: 2rem;
}
</style>
