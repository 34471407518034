import { createApp } from 'vue'
import { createPinia } from 'pinia'

import router from '@/router'
import clickOutside from './directives/ClickOutside'
import './global'

import App from '@/App.vue'

import 'air-datepicker/air-datepicker.css'
import 'maplibre-gl/dist/maplibre-gl.css'
import '@/assets/styles/main.scss'

const app = createApp(App)

app.use(router)
app.use(createPinia())

app.directive('click-outside', clickOutside)

app.mount('#app')
