import { Defect, DefectTypeMaskStyle } from '@/types/RoadDiagnostic'

export function defectsTochartPieData(defects: Defect[], types: DefectTypeMaskStyle[]) {
  if (!defects.length) return []

  return types.map((type) => ({
    value: defects.filter((el) => el.label === type.label).length,
    name: type.label
  }))
}
