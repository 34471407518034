<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'
import { useRoute } from 'vue-router'

import { useDefectsStore, useRoadDiagnosticStore, useRoadStore } from '@/stores'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'
import { Breadcrumb } from '@/utils/breadcrumbs'
import { defectsTochartPieData } from '@/composition/rating/useChartPieDefects'
import useDefects from '@/composition/defects/useDefects'
import { defectCandleStickPopup } from '@/utils/templates/defectsCandleStickPopup'
import { uniqFieldsArray } from '@/utils/uniq-fields-array'
import { defectsToChartStack } from '@/composition/rating/useChartStackDefects'
import { defectsToCandleStickData } from '@/composition/rating/useCandleStickDefects'

import ChartCandleStick from '@/components/ui/ChartCandleStick.vue'
import ChartStack from '@/components/ui/ChartStack.vue'
import ChartPie from '@/components/ui/ChartPie.vue'

import type { ICandleStickDefects, ILegendStyle, IlabelStyle } from '@/types/RoadRating'
import type { TBreadcrumb } from '@/types/ui/Breadcrumb'
import { Defect, DefectTypeMaskStyle, TooltipParams } from '@/types/RoadDiagnostic'

const emits = defineEmits(['setBr'])

const route = useRoute()

const { defectsToInfo, updateDefectInfo } = useDefects()

const defectFilterStore = useDefectFilterStore()
const defectStore = useDefectsStore()
const roadDiagnosticStore = useRoadDiagnosticStore()
const roadStore = useRoadStore()

const road = computed(() => roadStore.getRoad)
const defects = computed(() => defectStore.defects)
const defectTypes = computed(() => defectFilterStore.defectTypes)
const rating = computed(() => roadStore.rating)
const roadDefectRating = computed(() => roadDiagnosticStore.getAvgIri)
const iriChart = computed(() => roadDiagnosticStore.getAvgIriChart)
const limitPosition = computed(() => `${iriChart.value?.at(0)}%`)
const avgPsoition = computed(() => `${iriChart.value?.at(1)}%`)
const limitPositionEnd = computed(() => `${(iriChart.value?.at(0) as number) + 8}%`)

const colorStyle = ['#3366CC', '#B82E2E', '#4B0092', '#FF9900', '#0099C6', '#66AA00', '#DD4477']
const labelStyle: IlabelStyle = {
  show: true,
  color: 'white',
  fontFamily: 'Bender',
  fontSize: 18,
  fontWeight: 'bold'
}
const legendStyle: ILegendStyle = {
  orient: 'horizontal',
  left: 'center',
  textStyle: {
    color: '#686868',
    fontFamily: 'Bender',
    fontSize: 18,
    fontWeight: 'bold'
  }
}

const defectInfo = defectsToInfo(updateDefectInfo(defects.value, +route.params.id))
const lines = uniqFieldsArray<Defect, number>(defectInfo, 'line_number').sort((a, b) => a - b)
const legendItems = lines.map((...rest) => {
  const [, index] = rest

  return {
    value: `Полоса ${index + 1}`,
    textStyle: labelStyle
  }
})
const types = uniqFieldsArray<DefectTypeMaskStyle, string>(defectTypes.value, 'label')
const params = {
  types: defectTypes.value,
  lines,
  label: labelStyle,
  colors: colorStyle
}

const chartPieData = defectsTochartPieData(defectInfo, defectTypes.value)
const chartStackData = defectsToChartStack(defectInfo, {
  ...params,
  label: { ...params.label, color: 'white' }
})
const candleStickData: ICandleStickDefects = defectsToCandleStickData(defectInfo, {
  ...params,
  label: { ...params.label, color: '#686868' }
})

const maskGradientWidth = computed(() => {
  // отнимаем от 100% ширину, если качество дороги 0, то отнимаем 99 процентов для того что градиент не был пустой
  return `${100 - (rating.value || 99)}%`
})

onBeforeMount(() => {
  emits('setBr', new (Breadcrumb as unknown as TBreadcrumb)('Общая оценка', null))
})
</script>

<template>
  <div class="app-road-rating">
    <div v-if="defects?.length">
      <h2>Общая оценка</h2>
      <div class="app-road-rating__wrapper">
        <div class="analytics-item">
          <h3 class="analytics-item__title">Итоговая оценка качества дороги</h3>
          <div class="analytics-item__line">
            <div class="analytics-item__line-value" />
          </div>
          <span class="analytics-item__value">{{ rating }}%</span>
        </div>

        <div class="analytics-item">
          <h3 class="analytics-item__title">Среднее значение IRI</h3>
          <div class="analytics-item__line-iri">
            <div v-if="road?.has_iri" class="analytics-item__line-iri-limit">
              <div class="analytics-item__line-iri-value"></div>
            </div>
          </div>
          <span class="analytics-item__value" :class="{ empty: !road?.has_iri }">{{
            road?.has_iri ? roadDefectRating : 'Нет данных'
          }}</span>
        </div>

        <div class="chart-item">
          <h3>Общее количество и структура дефектов</h3>
          <ChartPie
            :data="chartPieData"
            :legend="legendStyle"
            :tooltip="{
              trigger: 'item'
            }"
            :colors="colorStyle"
            :label="labelStyle"
            :label-inside="labelStyle"
          ></ChartPie>
        </div>

        <div class="chart-item">
          <h3>Структура дефектов полос дорожного движения</h3>
          <ChartStack
            :data="chartStackData"
            :tooltip="{
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            }"
            :label="{}"
            :colors="colorStyle"
            :legend="legendStyle"
            :grid="{
              left: '6%',
              right: '3%',
              bottom: '3%',
              containLabel: true
            }"
            :x-axis="{
              type: 'value'
            }"
            :y-axis="{
              type: 'category',
              data: legendItems
            }"
          />
        </div>

        <div class="chart-item">
          <h3>Средняя площадь и вариативность дефектов</h3>
          <ChartCandleStick
            :data="candleStickData.series"
            :colors="colorStyle"
            :tooltip="{
              trigger: 'item',
              formatter: (params: TooltipParams) => defectCandleStickPopup(params, types)
            }"
            :x-axis="candleStickData.xAxisData"
            :y-axis="{
              name: 'м²'
            }"
            :label-style="{
              ...labelStyle,
              color: '#686868'
            }"
          ></ChartCandleStick>
        </div>
      </div>
    </div>
    <h3 v-else class="no-data-text">Нет данных</h3>
  </div>
</template>

<style lang="scss" scoped>
.app-road-rating {
  * {
    transition: 0.3s ease;
    transition-property: color, background;

    @include color('fontColor');
  }

  margin-bottom: 40px;

  & > h2 {
    margin-bottom: 40px;
  }

  & h3 {
    margin-bottom: 30px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 60px;

    & > div {
      padding: 30px;

      @include background-color('widgetBG');
    }

    @media (min-width: calc(1840px + 1px)) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.analytics-item {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  &__title {
    font-family: $f-family-base;
  }

  &__line {
    width: max(400px);
    height: 36px;
    margin: 34px 0 44px;
    padding: 4px;
    border: 1px solid $c-gray-40;

    &-value {
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, red, yellow, green);
      mask: linear-gradient(to left, transparent v-bind(maskGradientWidth), black 0);
    }
  }

  &__line-iri {
    position: relative;
    width: max(400px);
    height: 36px;
    margin: 34px 0 44px;
    border: 1px solid $c-gray-40;

    &-limit {
      position: absolute;
      inset: 4px;
      overflow: hidden;
      background: linear-gradient(
        to right,

        #7ed900 v-bind(limitPosition),
        #ff3200 v-bind(limitPositionEnd)
      );
    }

    &-value {
      position: absolute;
      top: 0;
      left: v-bind(avgPsoition);
      width: 5px;
      height: 30px;
      background-color: $c-white;
    }
  }

  &__icon {
    margin: 16px 0 30px;
  }

  &__value {
    font-size: 1.2rem;
    font-weight: bold;

    &.empty {
      color: $c-gray-65;
    }
  }
}

.chart-item {
  display: flex;
  flex-direction: column;
  width: 705px;
  height: 680px;

  &__chart {
    flex: 1 1 auto;
  }
}

@media (width <= 1840px) {
  .chart-item {
    width: calc(100vw - 490px);
  }
}

@media (width <= 1490px) {
  .chart-item {
    width: calc(100vw - 395px);
  }
}
</style>
