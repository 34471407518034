<script setup lang="ts">
import { computed, Ref, ref, watch } from 'vue'

import { useRoadDefectsMapStore } from '@/stores/road/defects/road-defects-map'
import { useRoadStore } from '@/stores/road/road'
import { useDefectsStore } from '@/stores/road/road-defects'
import { defectDataToGeoJSON, mapDefectsHighlight } from '@/composition/defects/useMapDefects'
import { getDataForRendering } from '@/composition/defects/useMapDefectsRender'

import AppMap from '@/components/layouts/AppMap.vue'

import { AppMapIds, GeometryMapLayers } from '@/utils/lists/lists'
import { ROAD_DEFECTS_SOURCE_NAME } from '@/utils/consts/consts'

import { MapRenderParams } from '@/types/map/Map'
import { GeoJSONSource, Map, MapMouseEvent } from 'maplibre-gl'

const emits = defineEmits(['map-clicked'])

const roadDefectsMapStore = useRoadDefectsMapStore()
const roadStore = useRoadStore()
const defectsStore = useDefectsStore()

const layers: Ref<MapRenderParams[]> = ref([])

const bboxRoad = computed(() => roadStore.bbox)
const road = computed(() => roadStore.getRoad)
const renderData = computed(() => roadStore.renderData)
const defects = computed(() => defectsStore.defects)
const map = computed(() => roadDefectsMapStore.map)
const selected = computed(() => roadDefectsMapStore.selectedFeature)

/**
 * Карта cмонтирована (хук)
 */
async function mapMounted(e: Map) {
  roadDefectsMapStore.updateInstance(e)
  if (!road.value?.id || !map.value.map) throw new Error('Нет данных')

  const geometryRoad = roadStore.findRenderData(GeometryMapLayers.ROAD_WAY, renderData.value)

  layers.value = getDataForRendering(
    {
      map: map.value.map,
      defects: defects.value,
      renderData: geometryRoad
    },
    road.value.id
  )

  if (!bboxRoad.value) return

  map.value.map.fitBounds(bboxRoad.value, {
    duration: 0
  })

  map.value.map.on('sourcedata', mapDefectsHighlight)
}

watch(
  defects,
  (val) => {
    if (!map.value.map || !bboxRoad.value || !road.value?.id) throw new Error('Недостаточно данных')

    const source = map.value.map.getSource(ROAD_DEFECTS_SOURCE_NAME) as GeoJSONSource
    if (!source) throw new Error(`Нет такого источника - ${ROAD_DEFECTS_SOURCE_NAME}`)

    source.setData({
      type: 'FeatureCollection',
      features: defectDataToGeoJSON(val, road.value.id) as never[]
    })
  },
  {
    deep: true
  }
)

/**
 * Клик по дефекту на карте
 */
function click(e: MapMouseEvent) {
  emits('map-clicked', e)
}
</script>

<template>
  <div>
    <AppMap
      :id="AppMapIds.ROAD_DIAGNOSTIC_DEFECTS_PAGE"
      height="550px"
      :active-layers="[`${ROAD_DEFECTS_SOURCE_NAME}-circle`]"
      :layers="layers"
      :map-options="{ maxZoom: 19, minZoom: 13.5 }"
      :click="true"
      :hover="true"
      :selected="selected"
      @map-mounted="mapMounted"
      @map-clicked="click"
    />
  </div>
</template>

<style lang="scss" scoped></style>
