import type { Defect } from '@/types/RoadDiagnostic'
import { ICandleStickDefects } from '@/types/RoadRating'
import type { ChartStackParams } from '@/types/ui/Chart'
import { fromSmToSqM } from '@/utils/units'

export function defectsToCandleStickData(defects: Defect[], params: ChartStackParams) {
  if (!defects.length) return {} as ICandleStickDefects
  const { lines, types, label, colors } = params

  const xAxisData = lines.map((...rest) => {
    const [, index] = rest

    return types.map((type) => ({
      value: `П-${index + 1} ${(type.label as string).slice(0, 3)}`,
      textStyle: {
        ...label,
        fontSize: 12
      }
    }))
  })

  const prepare = lines
    .map((line) =>
      types.map((type, index) => ({
        line,
        type: type.label,
        index
      }))
    )
    .flat()

  const seriesData = prepare.map((el) => {
    const currentDefects = defects
      .filter((defect) => el.line === defect.line_number && defect.label === el.type)
      .map((defect) => fromSmToSqM(defect.sum_square))
      .sort((a, b) => b - a)

    const middle = currentDefects.length / 2
    const minMedian = Math.round(middle / 2)
    const maxMedian = Math.round((currentDefects.length - middle) / 2 + middle)

    return {
      value: [
        currentDefects.at(maxMedian),
        currentDefects.at(minMedian),
        currentDefects.at(0),
        currentDefects.at(-1)
      ],
      itemStyle: {
        color: colors ? colors[el.index] : '#fff',
        borderColor: colors ? colors[el.index] : '#fff'
      }
    }
  })

  return {
    xAxisData: {
      data: xAxisData.flat()
    },
    series: [
      {
        type: 'candlestick',
        data: seriesData
      }
    ]
  }
}
