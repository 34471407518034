<script lang="ts" setup>
import { computed, ref } from 'vue'

import { useSegmentsStore } from '@/stores'
import { useDefectsStore } from '@/stores'
import { useDefectChartStore } from '@/stores'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'
import { useRoadDefectsMapStore } from '@/stores/road/defects/road-defects-map'

import AppCheckbox from '@/components/ui/AppCheckbox.vue'
import AppSelect from '@/components/ui/AppSelect.vue'
import TransitionHeight from '@/components/ui/TransitionHeight/TransitionHeight.vue'

import type { SelectOption } from '@/types/ui/Select'
import type { LikeEvent } from '@/types/ui/Checkbox'

import Cross from '@/assets/images/icons/crossAddition.svg'
import IconFilter from '@/assets/images/icons/filtrations.svg'
import IconRemove from '@/assets/images/icons/delete.svg'
import { DefectFilter } from '@/types/RoadDiagnostic'

const defectsStore = useDefectsStore()
const defectFilterStore = useDefectFilterStore()
const segmentsStore = useSegmentsStore()
const roadDefectsMapStore = useRoadDefectsMapStore()
const defectChartStore = useDefectChartStore()

const options = computed(() => defectFilterStore.roadLines)
const activeRoadLine = computed(() => defectFilterStore.activeRoadLine)
const defectTypes = computed(() => defectFilterStore.defectTypes)
const segmentId = computed(() => segmentsStore.currentSegment)
const chart = computed(() => defectChartStore.chart)
const map = computed(() => roadDefectsMapStore.map)
const defects = computed(() => defectsStore.defects)
const initialFilter = computed(() => defectFilterStore.initialFilter)

const isShowFilter = ref(false)

const changeShowFilter = () => {
  isShowFilter.value = !isShowFilter.value
}

function changeTypeVisibility(e: Event | LikeEvent, id: number) {
  if (!chart.value?.chart) throw new Error('Нет объекта графика')
  const visible = (e.target as HTMLInputElement).checked

  defectFilterStore.changeDefectVisibility(id, visible)
  defectsStore.fetchDefects(segmentId.value)
}

async function resetFilter() {
  if (!segmentId.value) return

  defectFilterStore.resetFilter()
  await defectFilterStore.initializeFilter()
}

async function changeRoadLine(value: SelectOption) {
  if (!chart.value?.chart || !map.value) return
  defectFilterStore.changeActiveRoadLine(value.value)

  const currentFilter: DefectFilter = { ...initialFilter.value }

  defectTypes.value.forEach((type) => {
    if (!type.show) delete currentFilter[type.name]
  })

  await defectsStore.fetchDefects(segmentId.value, currentFilter)
  defectFilterStore.calcFilterValues(defects.value)
}
</script>

<template>
  <div class="defectsWrapper">
    <div class="btnShowFilter" @click="changeShowFilter">
      <h3 class="btnShowFilter__title">
        <IconFilter class="btnShowFilter__filter-icon" />
        Фильтрация
      </h3>
      <Cross class="crossSvg" :class="{ filterActive: isShowFilter }" />
    </div>
    <TransitionHeight :show="isShowFilter">
      <div class="defectsWrapper__options">
        <div class="defectsWrapper__choiceLineAndMaskDefect">
          <div class="filterLine">
            <AppSelect
              :options="options"
              :model-value="activeRoadLine!"
              :placeholder="options.at(0)!.title"
              class="filterLine__select"
              @update:model-value="changeRoadLine"
            />
            <div class="filterLine__navigation">
              <button class="filterLine__defaultValueFilter" @click="resetFilter">
                <span class="filterLine__clearIcon">
                  <IconRemove />
                </span>
                Очистить фильтр
              </button>
            </div>
          </div>
        </div>
        <div class="filterCheckbox">
          <AppCheckbox
            v-for="defect in defectTypes"
            :key="defect.id + '-defect-cb'"
            :checked="defect.show"
            :name="defect.label"
            @toggle-checkbox="(e) => changeTypeVisibility(e, defect.id)"
          />
        </div>
        <div class="defectsWrapper__optionsItem">
          <slot />
        </div>
      </div>
    </TransitionHeight>
  </div>
</template>

<style lang="scss">
.filterLine {
  &__select {
    .select {
      &__input {
        padding: 0 1.25rem;
        font-family: $f-family-base;
        font-size: 0.875rem;
      }

      &__icon {
        right: 20px;
      }
    }
  }

  &__clearIcon {
    svg {
      fill: $c-orange;
    }
  }
}

.crossSvg path {
  @include fill('fontColor');
}

.btnShowFilter {
  &__filter-icon {
    fill: $c-orange;
  }
}
</style>

<style lang="scss" scoped>
.filterLine {
  &__navigation {
    display: flex;
    gap: 50px;
  }

  &__clearIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;

    @include background-color('headerNavIconBG');
  }
}

.defectsWrapper {
  position: relative;
  max-width: 100%;

  .btnShowFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 30px;
    border: 1px solid #686868;
    background: rgba(255 255 255 / 7%);
    cursor: pointer;

    &__title {
      display: flex;
      align-items: center;
      gap: 20px;
      font-family: $f-family-caption;

      @include color('fontColor');

      font-size: 1.2rem;
    }

    .filterActive {
      transform: rotate(45deg);
    }
  }

  &__options {
    border-right: 1px solid #686868;
    border-bottom: 1px solid #686868;
    border-left: 1px solid #686868;
  }

  &__choiceLineAndMaskDefect {
    width: 100%;
    padding: 20px 30px;
    border-bottom: 1px solid #686868;
    background: rgba(255 255 255 / 7%);
  }

  .filterLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    &__select {
      width: 320px;
      border: 1px solid #a7a7a7;

      &::after {
        content: '';
        position: absolute;
        inset: -2px;
        background:
          linear-gradient(to right, #d68166 4px, transparent 4px) 0 0,
          linear-gradient(to right, #d68166 4px, transparent 4px) 0 100%,
          linear-gradient(to left, #d68166 4px, transparent 4px) 100% 0,
          linear-gradient(to left, #d68166 4px, transparent 4px) 100% 100%,
          linear-gradient(to bottom, #d68166 4px, transparent 4px) 0 0,
          linear-gradient(to bottom, #d68166 4px, transparent 4px) 100% 0,
          linear-gradient(to top, #d68166 4px, transparent 4px) 0 100%,
          linear-gradient(to top, #d68166 4px, transparent 4px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        pointer-events: none;
      }
    }

    &__defaultValueFilter {
      display: flex;
      align-items: center;
      gap: 10px;
      border: none;
      background: none;
      font-family: $f-family-caption;
      font-size: 0.75rem;
      font-weight: 700;

      @include color('fontColor');

      cursor: pointer;
    }

    .iconDelete {
      width: 35px;
      height: 35px;

      .part1 {
        fill: #d68166;
      }
    }
  }

  .crossSvg {
    transition: 0.2s;
  }

  .filterCheckbox {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    height: max-content;
    min-height: 60px;
    gap: 20px;
    padding: 25px 30px;
    border-bottom: 1px solid #686868;

    .checkbox__name {
      font-family: $f-family-caption;
    }
  }

  &__optionsItem {
    width: 100%;
  }
}

@media (width <= 1650px) {
  .defectsWrapper {
    .btnShowFilter {
      padding: 0 20px;
    }

    .filterCheckbox {
      padding: 25px 20px;
    }

    &__choiceLineAndMaskDefect {
      padding: 25px 20px;
    }
  }
}
</style>
