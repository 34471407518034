import { computed, reactive, ref } from 'vue'
import { defineStore } from 'pinia'

import { fetchWrapper } from '@/api/fetchApi.ts'
import { l } from '@/utils/lang'

import type {
  IFilterObject,
  IMainTablePagination,
  IMainTableRow,
  IMainTableStore,
  RoadTableProperty,
  TableProperty
} from '@/types/ui/MainTable'
import type { CheckboxItem } from '@/types/ui/Checkbox'

import { filterCheckbox, mainTableHead } from '@/assets/data/main-table'
import { dict } from '@/utils/consts/consts'

export const useMainTableStore = defineStore('main-table', () => {
  const state: IMainTableStore = reactive({
    settings: {
      search: '',
      checkboxList: filterCheckbox
    },
    table: {
      head: mainTableHead,
      rows: [],
      hidden: [],
      pagination: {
        current: 1,
        left: 7,
        max: 0
      }
    }
  })

  const activeFilters = ref<boolean>(false)
  const actualFilterValue = ref<{ [key: string]: string | number }>({})

  const getCheckboxList = computed(() => state.settings.checkboxList)
  const getSearchValue = computed({
    get() {
      return state.settings.search
    },
    set(value) {
      state.settings.search = value
    }
  })
  const getTableColumns = computed(() => state.table.head)
  const getTableRows = computed(() => state.table.rows)

  function changeCheckbox(checkbox: CheckboxItem) {
    state.settings.checkboxList = state.settings.checkboxList.map((el) => {
      if (el.id === checkbox.id) el.checked = !checkbox.checked

      return el
    })
  }

  function searchForColumn(value: string) {
    if (!value) {
      state.settings.checkboxList.forEach((el) => (el.hidden = false))

      return
    }

    state.settings.checkboxList = state.settings.checkboxList.map((el) => {
      if (typeof el.name === 'string') {
        const search = new RegExp(state.settings.search, 'ig')
        const coincedence = el.name.search(search)

        el.hidden = coincedence <= -1
      }

      return el
    })
  }

  function toggleVisibleColumn(checkbox: CheckboxItem) {
    state.table.head.forEach((item) => {
      if (item.id === checkbox.id) item.hidden = !checkbox.checked
    })

    state.table.rows.forEach((item) => {
      if (typeof checkbox.name === 'string') {
        const key = l(checkbox.name.toLocaleLowerCase(), true)

        if (item[key]) {
          item[key].hidden = !checkbox.checked
        }
      }
    })
  }

  function setRoads(roads: Array<IMainTableRow>) {
    state.table.rows = roads.map((...rest) => {
      const [, index] = rest
      const road = {} as RoadTableProperty

      for (const key in dict) {
        const prop = {} as TableProperty

        prop.hidden = state.table.rows.at(0)?.[dict[key]]?.hidden || false
        prop.value = roads[index][dict[key]]

        road[dict[key]] = prop
      }

      return road
    })
  }

  function setPagination(pagination: IMainTablePagination) {
    state.table.pagination = pagination
  }

  function getPagination() {
    return state.table.pagination
  }

  async function getRoads(
    current: number,
    elementsOnPage: number,
    left: number,
    body?: Partial<IFilterObject>
  ) {
    try {
      const request = () => {
        return new Promise((resolve) => {
          setTimeout(async () => {
            const response = (await fetchWrapper.post(
              `/roads/collection/?page_number=${current}&elements_on_page=${elementsOnPage}`,
              body && activeFilters.value ? body : {} /** если фильтры активны , то передаем body*/
            )) as { page_number: number; number_of_pages: number; data: IMainTableRow[] }
            resolve(response)
          }, 100)
        }) as Promise<{ page_number: number; number_of_pages: number; data: IMainTableRow[] }>
      }

      const response = await request()
      setRoads(response.data)
      setPagination({
        current: response.page_number > response.number_of_pages ? 1 : response.page_number,
        max: response.number_of_pages,
        left
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  const changeActiveFilters = (actionState: boolean) => {
    activeFilters.value = actionState
  }

  return {
    state,
    getCheckboxList,
    getSearchValue,
    getTableColumns,
    activeFilters,
    actualFilterValue,
    getPagination,
    getTableRows,
    getRoads,
    changeCheckbox,
    searchForColumn,
    toggleVisibleColumn,
    setRoads,
    setPagination,
    changeActiveFilters
  }
})
