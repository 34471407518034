<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'

import { useToastStore } from '@/stores'
import { useExportRoadStore } from '@/stores/export'
import { exportRoadsApi } from '@/api/export'

import InputField from '@/components/ui/InputField.vue'
import Button from '@/components/ui/Button.vue'

const emits = defineEmits(['close-modal'])

const exportRoadStore = useExportRoadStore()
const toastStore = useToastStore()

const { skdfForm, skdfToken } = storeToRefs(exportRoadStore)
const formLogin = ref()

async function login() {
  try {
    await exportRoadsApi.skdfAuthQueue(skdfForm.value)

    toastStore.toast.info(
      'Идет авторизация в СКДФ',
      'Запрос может идти долго',
      1_000 * 60 * 60 * 24
    )
    fetchSkdfToken()

    formLogin.value = skdfForm.value.username
    emits('close-modal')
  } catch {
    throw new Error()
  }
}

async function fetchSkdfToken() {
  try {
    const response = await exportRoadsApi.fetchToken(formLogin.value)
    skdfToken.value = JSON.stringify(response)
    toastStore.toasts.length = 0
  } catch (e) {
    setTimeout(() => {
      fetchSkdfToken()
    }, 1_000)
  }
}

function changeSkdfLogin(type: string, e: InputEvent) {
  const t = e.target as HTMLInputElement

  skdfForm.value[type] = t.value
}
</script>

<template>
  <form class="export__modal" @submit.prevent="login">
    <div class="export__form">
      <div class="export__item">
        <p class="export__name">Логин</p>
        <InputField
          class="export__input"
          :model-value="skdfForm.username"
          type="text"
          placeholder="Логин"
          @input="(e: InputEvent) => changeSkdfLogin('username', e)"
        ></InputField>
      </div>
      <div class="export__item">
        <p class="export__name">Пароль</p>
        <InputField
          :model-value="skdfForm.password"
          class="export__input"
          type="password"
          placeholder="Пароль"
          @input="(e: InputEvent) => changeSkdfLogin('password', e)"
        ></InputField>
      </div>
    </div>
    <div class="export__login">
      <Button
        class="export__btn export__btn--cancel"
        type="button"
        text="Отмена"
        @click="emits('close-modal')"
      ></Button>
      <Button class="export__btn" type="submit" text="Логин"></Button>
    </div>
  </form>
</template>

<style></style>
