import { capitalize } from '@/utils/string'
import { fromSmToSqM } from '@/utils/units'

import type { DefectImageParams } from '@/types/Defects'
import type { RoadNeiroDefect } from '@/types/RoadDiagnostic'

const LINE_NUMBER_IS_NULL = -2_913_287_450_123

function setDefectPhoto(params: DefectImageParams) {
  const { roadId, defect } = params

  const photoId = 'image_file_uuid' in defect ? defect.image_file_uuid : defect.mask_file_uuid

  return `/roads/${roadId}/defects/dowload?uuid_file=${photoId}`
}

function removeZeroPos(defects: RoadNeiroDefect[]) {
  return defects.filter((el) => el.position >= 0)
}

function defineLineNumber(defect: RoadNeiroDefect) {
  return defect.line_number === null ? LINE_NUMBER_IS_NULL : defect.line_number
}

/**
 * Обновляем информацию о дефекте (позицию, фото, маски, GeoJSON)
 */
function updateDefectInfo(defects: RoadNeiroDefect[], roadId: number) {
  return defects.map((defect) => {
    const def = defect.defect_list.map((el) => ({ ...el }))

    defect.position_geojson = defect.position_gejson
    defect.line_number = defineLineNumber(defect)

    const defect_list = def.map((photo) => {
      photo.position = defect.position
      photo.position_geojson = defect.position_gejson
      photo.line_number = defect.line_number
      photo.max_square = fromSmToSqM(photo.max_square)
      photo.photo = setDefectPhoto({ defect, roadId })
      photo.photo_mask = setDefectPhoto({ defect: photo, roadId })

      return photo
    })

    return {
      ...defect,
      defect_list
    }
  })
}

function defectsToInfo(defects: RoadNeiroDefect[]) {
  return defects.map((defect) => defect.defect_list).flat()
}

function changeClassNameDefects(defects: RoadNeiroDefect[]) {
  return defects.map((el) => ({
    ...el,
    defect_list: el.defect_list.map((item) => ({
      ...item,
      class_name: item.class_name,
      label: typeof item.label === 'string' ? capitalize(item.label) : item.label
    }))
  }))
}

function setSegementId(defects: RoadNeiroDefect[], segmentId?: number) {
  if (!segmentId) return defects

  return defects.map((defect) => ({ ...defect, segmentId }))
}

function getDefectNames(defects: RoadNeiroDefect[]) {
  const info = defectsToInfo(defects)
  const names = info.map((el) => ({
    eng: el.class_name,
    rus: el.label
  }))

  return names.filter((val, i) => names.findIndex((a) => a.eng === val.eng) === i)
}

function prepareDefects(defects: RoadNeiroDefect[], segmentId?: number) {
  return setSegementId(changeClassNameDefects(removeZeroPos(defects)), segmentId)
}

export default function () {
  return {
    setDefectPhoto,
    getDefectNames,

    updateDefectInfo,
    defectsToInfo,
    setSegementId,
    prepareDefects,

    removeZeroPos,
    changeClassNameDefects
  }
}
