<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import maplibregl, {
  Map,
  MapMouseEvent,
  MapOptions,
  RequestParameters,
  StyleSpecification
} from 'maplibre-gl'
import { isMapboxURL, transformMapboxUrl } from 'maplibregl-mapbox-request-transformer'

import MapToolLesson from '@/composition/map/useMapToolLesson'
import { useToastStore, useMapStore, useThemeStore } from '@/stores'
import MapControls from '@/composition/map/useMapControls'
import useMapHover from '@/composition/map/useMapHover'
import renderFeature from '@/utils/map/renderFeature'
import MapRoadSignRender from '@/composition/map/map-road-marker-render'
import { toggleEffectOnFeature } from '@/utils/map'

import type { CustomMapOptions, MapSettings } from '@/types/map/Map'

const { VITE_MAPBOX_KEY } = import.meta.env

const props = withDefaults(defineProps<MapSettings>(), {
  id: 'map',
  height: '100%',
  hover: false,
  click: false
})

const emits = defineEmits(['map-mounted', 'change-map-style', 'map-clicked'])

const STANDART_ZOOM = 7

const { toast } = useToastStore()
const themeStore = useThemeStore()
const mapStore = useMapStore()

const mapControls = new MapControls()
const signRoadRender = new MapRoadSignRender()

let map: Map | undefined = undefined

const text = computed(() => mapStore.measureLessonText)
const passed = computed(() => mapStore.measureLessonPassed)
const theme = computed(() => themeStore.theme)
const options = computed(() => props.mapOptions)
const style = computed(() => mapStore.currentStyle)
const activeLayers = computed(() => props.activeLayers)
const hover = computed(() => props.hover)
const click = computed(() => props.click)
const layers = computed(() => props.layers)
const cluster = computed(() => props.cluster)
const selected = computed(() => props.selected)

const { renderFeatures } = renderFeature({ cluster: cluster.value as string[] })
const { hoverOnFeature } = useMapHover(activeLayers.value || [])

const mapToolLesson = new MapToolLesson(toast, text.value, passed.value)
const startLesson = mapToolLesson.start.bind(mapToolLesson)
const nextLesson = mapToolLesson.next.bind(mapToolLesson)
const endLesson = mapToolLesson.end.bind(mapToolLesson)

const transformRequest = (url: string, resourceType?: string): RequestParameters | undefined => {
  if (isMapboxURL(url)) {
    return transformMapboxUrl(url, resourceType as string, VITE_MAPBOX_KEY)
  }

  return { url }
}

onMounted(() => initialize(options.value))

onBeforeUnmount(() => {
  if (!map) return

  map.off('load', onMapLoad)
  map.off('styledata', changeStyle)

  map.off('mousemove', hoverWrapper)
  map.off('click', clickWrapper)

  map.off('rotate', roadShadowWrapper)
  map.off('zoom', roadShadowWrapper)
  window.removeEventListener('resize', resizeMap)

  const tools = document.querySelector('.maplibregl-ctrl-top-right')
  if (!tools) return

  tools.removeEventListener('click', startLesson, true)
  document.body.removeEventListener('click', nextLesson)
  document.body.removeEventListener('keyup', endLesson)
})

function initialize(value?: CustomMapOptions) {
  mapControls.removeZoomBtns()
  const options = { ...value }

  if (options && !options.zoom) delete options.zoom

  delete options?.fitToBounds

  const settings = {
    container: props.id,
    attributionControl: false,
    style: style.value,
    ...value,
    antialias: true,
    maxPitch: 45,
    collectResourceTiming: true,
    maxZoom: 19.45,
    maxTileCacheSize: 2048,
    transformRequest
  } as MapOptions

  map = new maplibregl.Map(settings)

  const zoom = value?.zoom ?? STANDART_ZOOM
  map.setZoom(zoom)

  addMapListeners()
}

function addMapListeners() {
  if (!map) return

  map.on('load', onMapLoad)
}

function roadShadowWrapper() {
  if (!map) return

  signRoadRender.defineShadow(map)
}

function addFirstZIndex(map: Map) {
  if (map.getSource('first')) return

  map.addSource('first', {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] }
  })
  map.addLayer({
    id: 'first-z-index',
    type: 'symbol',
    source: 'first'
  })
}

function onMapLoad() {
  if (!map) return

  addFirstZIndex(map)

  const tools = document.querySelector('.maplibregl-ctrl-top-right')
  if (tools) {
    tools.addEventListener('click', startLesson, true)
    document.body.addEventListener('click', nextLesson)
    document.body.addEventListener('keyup', endLesson)
  }

  if (hover.value) map.on('mousemove', hoverWrapper)
  if (click.value) map.on('click', clickWrapper)

  map.on('rotate', roadShadowWrapper)
  map.on('zoom', roadShadowWrapper)

  window.addEventListener('resize', resizeMap)

  resizeMap()

  emits('map-mounted', map)

  map.on('styledata', changeStyle)
}

function changeStyle() {
  if (!map) return

  addFirstZIndex(map)

  layers.value.forEach((layer) => renderFeatures(layer, map as Map))
}

function hoverWrapper(e: MapMouseEvent) {
  if (!map) return

  hoverOnFeature(e, map)
}

function clickWrapper(e: MapMouseEvent) {
  if (!map || !activeLayers.value) return

  emits('map-clicked', e)
}

function resizeMap() {
  if (!map) return
  map.resize()
}

watch(
  theme,
  () => {
    if (!map) return

    map.setStyle('')
    map.setStyle(style.value as StyleSpecification)
  },
  {
    deep: true
  }
)

onBeforeRouteLeave(() => {
  if (!map) return

  mapControls.removeZoomBtns()
})

watch(
  layers,
  (values) => {
    values.forEach((layer) => renderFeatures(layer, map as Map))
  },
  {
    deep: true
  }
)

watch(
  selected,
  (val, old) => {
    if (!map) return
    if (old) toggleEffectOnFeature(map, old, { active: false })
    if (val) toggleEffectOnFeature(map, val, { active: true })
  },
  { deep: true }
)
</script>

<template>
  <div :id="props.id" :style="{ height: props.height }"></div>
</template>

<style lang="scss" scoped>
#map {
  display: block;
  width: 100%;
}
</style>
