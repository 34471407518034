<script setup lang="ts">
import { PopupProps } from '@/types/ui/Popup'

const props = withDefaults(defineProps<PopupProps>(), {
  title: '',
  isOpen: false
})

const emits = defineEmits(['close-modal'])

function closeModal() {
  emits('close-modal')
}
</script>

<template>
  <Teleport to=".page">
    <Transition name="fade">
      <div v-if="props.isOpen" class="modal" @mousedown.self="closeModal">
        <div class="modal__wrapper">
          <div class="modal__header-background">
            <div class="modal__header">
              <h2 class="modal__title">{{ props.title }}</h2>
              <span class="modal__close" @click="closeModal"></span>
            </div>
          </div>
          <div class="modal__slot">
            <slot></slot>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped lang="scss">
.modal {
  display: grid;
  position: fixed;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  background-color: rgb(32 31 34 / 90%);
  inset: 0;

  &__wrapper {
    display: grid;
    grid-template-rows: 60px 1fr;
    width: 1180px;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
    padding-left: 40px;
    background-image: url('@/assets/images/noise-dark.png');
  }

  &__close {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: $c-orange;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      background-color: $c-black;
      mask-image: url('@/assets/images/icons/close.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
    }
  }

  &__title {
    color: $c-white;
    font-family: $f-family-caption;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.32px;
  }

  &__header-background {
    background-color: #313032e5;
  }
}
</style>
