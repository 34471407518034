import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

import { EgrnLayers } from '@/utils/lists/lists'
import { useRoadsStore } from '@/stores/roads'
import { fetchWrapper } from '@/api/fetchApi'
import { roadApi } from '@/api/roads'

import type { MapRenderParams, RoadLayersState } from '@/types/map/Map'
import type { OrtoInfo, RoadGeoJSON } from '@/types/Road'

export const useRoadLayersStore = defineStore('road-layers', () => {
  const roadsStore = useRoadsStore()

  const roads = computed(() => roadsStore.roads)

  const state: RoadLayersState = reactive({
    layers: [
      {
        id: EgrnLayers.LANDS,
        data: {} as RoadGeoJSON,
        params: {
          showed: false,
          name: EgrnLayers.LANDS,
          label: 'Земельные участки'
        }
      },
      {
        id: EgrnLayers.BUILDS,
        data: {} as RoadGeoJSON,
        params: {
          showed: false,
          name: EgrnLayers.BUILDS,
          label: 'Здания'
        }
      },
      {
        id: EgrnLayers.CONSTRUCTIONS,
        data: {} as RoadGeoJSON,
        params: {
          showed: false,
          name: EgrnLayers.CONSTRUCTIONS,
          label: 'Сооружения'
        }
      }
    ],
    mapPageLayers: [
      {
        id: 'orto-',
        data: {} as RoadGeoJSON,
        params: {
          showed: false,
          name: 'orto-',
          label: 'Ортофотопланы'
        }
      }
    ],
    substrate: []
  })

  const layers = computed(() => state.layers)
  const egrn = computed(() => state.layers.filter((el) => el.params.name.startsWith('egrn-custom')))
  const orto = computed(() => state.layers.filter((el) => el.params.name.startsWith('orto-')))
  const activeLayersCounter = computed(() => state.layers.filter((el) => el.params.showed).length)
  const mapPageLayers = computed(() => state.mapPageLayers)
  const activeMapPageLayers = computed(
    () => state.mapPageLayers.filter((el) => el.params.showed).length
  )
  const showAllOrtos = computed(() => state.mapPageLayers[0]?.params.showed)
  const substrate = computed(() => state.substrate)

  function toggleLayers(item: MapRenderParams, checked: boolean) {
    state.layers = state.layers.map((el) => {
      if (el.id === item.id) {
        return {
          ...el,
          params: Object.assign(el.params, { showed: checked })
        }
      }

      return el
    })
  }

  async function fetchOrto(roadId: number) {
    try {
      const data = await fetchWrapper.get(`/roads/roads/${roadId}/tails/`)

      state.layers.push(
        ...(data as OrtoInfo[]).map((el) => ({
          id: `orto-${el.name}`,
          data: {} as RoadGeoJSON,
          params: {
            showed: false,
            name: `orto-${el.name}`,
            bbox_geojson: el.bbox_geojson,
            label: el.name,
            roadId,
            tailsId: el.id
          }
        }))
      )
    } catch (e) {
      throw new Error(e)
    }
  }

  async function fetchAllOrtos() {
    const ids = roads.value?.features.map((el) => el.properties.id)
    if (!ids) return

    try {
      const data = await roadApi.fetchAllOrtos()
      const hasRoadId = data.filter((el) => el.roads_id.length && el.type === 'orto')
      state.substrate = data.filter((el) => el.type === 'Подложка')

      state.layers.push(
        ...hasRoadId.map((el, index) => ({
          id: `orto-${el.name}-${index}`,
          data: {} as RoadGeoJSON,
          params: {
            showed: false,
            name: `orto-${el.name}`,
            bbox_geojson: el.bbox_geojson,
            label: el.name,
            tailsId: el.id
          }
        }))
      )
    } catch {
      throw new Error('Fetch all ortos error')
    }
  }

  function resetRoadLayer() {
    state.layers = state.layers.filter((el) => !el.params.name.startsWith('orto-'))

    state.layers.forEach((item) => {
      toggleLayers(item, false)
    })
  }

  function toggleAllOrtos(value: boolean) {
    state.mapPageLayers[0].params.showed = value
  }

  return {
    layers,
    egrn,
    orto,
    activeLayersCounter,
    showAllOrtos,
    mapPageLayers,
    activeMapPageLayers,
    substrate,

    toggleLayers,
    toggleAllOrtos,
    fetchOrto,
    fetchAllOrtos,
    resetRoadLayer
  }
})
