<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue'

import {
  useDefectsStore,
  useDefectChartStore,
  useRoadDefectsPopupStore,
  useRoadDefectsMapStore
} from '@/stores'
import { useRoadStore } from '@/stores/road/road'
import useDefects from '@/composition/defects/useDefects'
import { mapDefectsHighlight } from '@/composition/defects/useMapDefects'
import { flyToMap } from '@/composition/defects/useMapDefects'
import useChartHighlight from '@/composition/defects/useChartHighlight'

import DiagnosticDefectPopup from '@/components/layouts/road/diagnostic/defects/DiagnosticDefectPopup.vue'
import DefectsMap from '@/components/layouts/road/diagnostic/defects/DefectsMap.vue'
import DefectsChart from '@/components/layouts/road/diagnostic/defects/DefectsChart.vue'

import type { Point } from 'chart.js'
import type { ECElementEvent } from 'echarts'
import type { MapMouseEvent } from 'maplibre-gl'
import type { ChartData, SelectedChartItem } from '@/types/ui/Chart'

const roadStore = useRoadStore()
const defectsStore = useDefectsStore()
const chartDefectsStore = useDefectChartStore()
const roadDefectsPopupStore = useRoadDefectsPopupStore()
const roadDefectsMapStore = useRoadDefectsMapStore()
const { defectsToInfo, updateDefectInfo } = useDefects()
const { updateChart } = useChartHighlight()

const road = computed(() => roadStore.getRoad)
const defects = computed(() => defectsStore.defects)
const chartData = computed(() => chartDefectsStore.chartData)
const selectedDefect = computed(() => defectsStore.selectedDefect)
const map = computed(() => roadDefectsMapStore.map)
const coordinates = computed(() => roadDefectsPopupStore.coordinates)
const isDraggable = computed(() => roadDefectsPopupStore.isDraggable)

const isOpen = ref(false)
let event: SelectedChartItem | undefined = undefined

onUnmounted(() => {
  chartDefectsStore.setSelected([])
  defectsStore.updateSelectedDefect([])
  roadDefectsMapStore.updateSelectedFeature(undefined)
  isOpen.value = false
  roadDefectsPopupStore.updateFromDraggbale(false)
})

function changeDefectPopupPosition(e?: Partial<ECElementEvent>) {
  const event = e?.event?.event as Point

  if (isDraggable.value) return

  const coors = {
    x: event ? event.x : 150,
    y: event ? event.y : 150
  }

  roadDefectsPopupStore.updateCoordinates(coors)
}

function chartClick(e: Partial<ECElementEvent>) {
  if (!road.value?.id) return
  event = e as SelectedChartItem

  const { seriesIndex, data } = event
  const [position, max_square] = data as number[]

  const photo = defectsToInfo(
    updateDefectInfo(
      defects.value.filter((photo) => photo.position === position),
      road.value.id
    )
  )
  const defect = photo.find((defect) => defect.max_square === max_square)
  if (!defect || seriesIndex == undefined) return

  defectsStore.updateSelectedDefect([defect])
  updateChart([event])

  changeDefectPopupPosition(e)
  isOpen.value = true

  if (!map.value.map) return
  flyToMap(map.value.map)

  map.value.map.once('moveend', mapDefectsHighlight)
}

function mapClick(e: MapMouseEvent) {
  if (!map.value.map || !road.value?.id) return
  const [first] = map.value.map.queryRenderedFeatures(e.point)

  const choosedDefects = defectsToInfo(updateDefectInfo(defects.value, road.value.id)).filter(
    (el) =>
      el.position === first.properties.position && el.line_number === first.properties.line_number
  )

  defectsStore.updateSelectedDefect(choosedDefects)
  if (!selectedDefect.value.length) throw new Error('Недостаточно данных')

  const selectedChartData: SelectedChartItem[] = chartData.value
    .map((el: ChartData, index) => {
      const dataIndex = el.data.findIndex(([pos]) => pos === first.properties.position)

      return {
        dataIndex,
        seriesName: el.name,
        data: el.data[dataIndex],
        seriesIndex: index
      }
    })
    .filter((el) => el.dataIndex > -1)

  if (selectedChartData.length) chartDefectsStore.setSelected(selectedChartData)

  const zoom = map.value.map.getZoom()

  if (zoom < 16) {
    flyToMap(map.value.map)
    map.value.map.once('moveend', mapDefectsHighlight)
  } else mapDefectsHighlight()

  changeDefectPopupPosition()
  isOpen.value = true
}

function closePopup() {
  isOpen.value = false
}

function openPopup() {
  isOpen.value = true
}
</script>

<template>
  <Transition name="fade">
    <div class="defects__chart">
      <DefectsChart @chart-clicked="chartClick" />
    </div>
  </Transition>
  <Transition name="fade">
    <DiagnosticDefectPopup
      v-show="isOpen"
      :data="selectedDefect"
      :position="coordinates"
      @close="closePopup"
      @open="openPopup"
    ></DiagnosticDefectPopup>
  </Transition>
  <div class="defects__map">
    <DefectsMap @map-clicked="mapClick" />
  </div>
</template>

<style lang="scss" scoped>
.defects {
  &__chart {
    width: 100%;
    height: 620px;
    margin-top: 60px;
  }

  &__map {
    width: 100%;
    height: auto;
    min-height: 550px;
    margin-top: 75px;
    margin-bottom: 120px;
    overflow: hidden;
    border-radius: 25px;

    @include box-shadow('boxShadowDefectMap');

    .maplibregl-map {
      height: 100%;
    }
  }
}

.defects-navigation {
  &__btn {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: $c-orange;
    cursor: pointer;

    &.disabled {
      background-color: $c-gray-15;
      pointer-events: none;
    }
  }
}

@media (width <= 1650px) {
  .defects__map {
    margin-top: 150px;
  }
}
</style>
