<script lang="ts" setup>
import { computed } from 'vue'

import { RoadInfoNames } from '@/utils/lists/lists'

import isEmpty from '@/utils/is-empty.ts'
import useRoadAboutInfo from '@/stores/road/road-about'

const roadAboutStore = useRoadAboutInfo()

const PLACEHOLDER = 'Нет данных'

const about = computed(() => Object.entries(roadAboutStore.getInfo))
</script>

<template>
  <div class="road-page__about">
    <ul v-for="(item, index) in about" :key="`info-${index}`" :class="`road-about__${item[0]}`">
      <li
        v-for="(elem, count) in item[1]"
        :key="`info-sub-${count}`"
        :class="`road-${item[0]}__item`"
      >
        <p class="road-main__title">{{ elem.name }}</p>
        <p
          v-if="elem.name !== RoadInfoNames.SKDF_NUMBER"
          :class="{ empty: isEmpty(elem.value, true) }"
          class="road-main__value"
        >
          {{ isEmpty(elem.value, true) ? PLACEHOLDER : elem.value }}
        </p>
        <p v-else class="road-main__value" :class="{ empty: isEmpty(elem.value, true) }">
          <span v-if="isEmpty(elem.value, true)">{{ PLACEHOLDER }}</span>

          <a v-else :href="`${elem.value}`" target="_blank">
            {{ (elem.value as string).split('/').at(-1) }}
          </a>
        </p>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.road-page {
  &__about {
    display: grid;
    grid-gap: 1.25rem;
    grid-template-areas:
      'id location'
      'belong location';
    grid-template-columns: repeat(2, 1fr);
  }
}

.road-about {
  &__id,
  &__owner,
  &__location {
    padding: 1.875rem;
    transition: background-color 0.3s $main-animation;

    @include background-color('widgetBG');
  }

  &__id {
    display: grid;
    grid-area: id;
    grid-gap: 30px 140px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: space-between;
    height: max-content;
    padding-right: 10.81rem;

    a {
      @include color('fontColor');
    }
  }

  &__owner {
    display: grid;
    grid-area: belong;
    grid-gap: 30px 106px;
    grid-template-areas:
      'owner owner'
      'balance residual';
  }

  &__location {
    display: grid;
    grid-area: location;
    grid-row-gap: 1.875rem;
    grid-template-columns: repeat(2, 300px);
    align-content: start;
    justify-content: space-between;
  }

  &__loc {
    height: max-content;
  }
}

.road-owner {
  &__item {
    &:first-child {
      grid-area: owner;
    }

    &:nth-child(2) {
      grid-area: balance;
    }

    &:nth-child(3) {
      grid-area: residual;
    }
  }
}

@media (width <= 1845px) {
  .road-page {
    &__about {
      grid-template-areas:
        'id'
        'belong'
        'location';
    }
  }

  .road-about {
    &__id {
      grid-gap: 30px 245px;
      justify-content: start;
    }

    &__location {
      grid-gap: 30px 110px;
      justify-content: start;
    }
  }
}

@media (width <= 1600px) {
  .road-about {
    &__id {
      grid-gap: 30px 180px;
    }
  }
}
</style>
