import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

import { roadApi } from '@/api/roads'

import type { PaginationState } from '@/types/ui/Pagination'
import type { RoadResponse } from '@/types/Road'
import type { SkdfLoginForm } from '@/types/User'

export const useExportRoadStore = defineStore('export', () => {
  const roads: Ref<RoadResponse[]> = ref([])
  const pagination: Ref<PaginationState> = ref({
    current: 1,
    total: 0,
    elementsOnPage: 15,
    lastPage: 0
  })
  const search = ref('')
  const selectedRoads: Ref<RoadResponse[]> = ref([])
  const skdfForm: Ref<SkdfLoginForm> = ref({
    username: '',
    password: ''
  })
  const skdfToken = useLocalStorage('skdf-token', localStorage.getItem('skdf-token'))

  async function fetchRoads(page = 1) {
    const params = {
      page,
      elementsOnPage: pagination.value.elementsOnPage
    }

    Object.assign(params, {
      name: search.value,
      skdf_number: ''
    })

    const response = await roadApi.getRoads(params)

    roads.value = response.data
    pagination.value = Object.assign({}, pagination.value, {
      current: response.page_number,
      total: response.total,
      lastPage: response.number_of_pages
    })
  }

  function checkSkdfToken() {
    if (!skdfToken.value) return

    const { profile } = JSON.parse(skdfToken.value)
    const { exp, iat } = profile

    const liveTimeInMiliSec = +new Date(exp * 1000) - +new Date(iat * 1000)
    const endTimeToken = profile.loginDate + liveTimeInMiliSec

    if (endTimeToken >= +new Date()) skdfToken.value = undefined
  }

  return {
    roads,
    pagination,
    search,
    selectedRoads,
    skdfForm,
    skdfToken,

    fetchRoads,
    checkSkdfToken
  }
})
