<script setup lang="ts">
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useRoadStore, useRoadWayStore } from '@/stores'
import { Breadcrumb } from '@/utils/breadcrumbs'

import AppMap from '@/components/layouts/AppMap.vue'
import AppMapBtn from '@/components/ui/AppMapBtn.vue'

import { TBreadcrumb } from '@/types/ui/Breadcrumb'
import { LngLatBoundsLike, Map } from 'maplibre-gl'
import { useRoadTableGenerationStore } from '@/stores/road/road-tableGeneration.ts'
import WayAccordion from '@/components/layouts/road/WayAccordion.vue'

const PLACEHOLDER = '—'

const emits = defineEmits(['setBr'])

const route = useRoute()
const roadWay = useRoadWayStore()
const roadStore = useRoadStore()
const objectTypeStore = useRoadTableGenerationStore()

const renderParams = reactive({
  showed: true,
  name: 'road',
  lineWidth: ['interpolate', ['exponential', 2], ['zoom'], 1, 2, 15, 15],
  color: '#D68166'
})

const roadWayLane = computed(() =>
  roadWay.roadWayLane.map((lanes) => [
    lanes[0] || PLACEHOLDER,
    lanes[1] || PLACEHOLDER,
    lanes[2] || PLACEHOLDER
  ])
)

const roadWayWidth = computed(() => roadWay.roadWayWidth)
const roadWayClothes = computed(() => roadWay.roadWayClothes)
const bbox = computed(() => roadStore.bbox)
const axis = computed(() => roadStore.roadAxis)

const showMap = ref(true)
const partial = ref(true)
const mapOptions = ref({
  bounds: undefined as LngLatBoundsLike | undefined,
  interactive: false,
  fitToBounds: 0
})
const map = ref()
const layers = reactive([
  {
    data: computed(() => axis.value!),
    params: renderParams
  }
])

const roadId = +route.params.id

onBeforeMount(async () => {
  emits('setBr', new (Breadcrumb as unknown as TBreadcrumb)('Дорожные объекты', null))

  if (!roadWayLane.value.length || !roadWayWidth.value.length || !roadWayClothes.value.length) {
    await roadWay.fetchRoadWay(roadId)
  }

  mapOptions.value.bounds = bbox.value
})

function mapMounted(mapInstance: Map) {
  if (map.value) map.value.remove()

  map.value = mapInstance

  const needFitToBounds = mapOptions.value.fitToBounds != null && mapOptions.value?.bounds

  if (needFitToBounds) {
    map.value.fitBounds(mapOptions.value?.bounds, { duration: mapOptions.value.fitToBounds })
  }
}

onMounted(() => {
  objectTypeStore.resObjectTypes()
})
</script>

<template>
  <div class="roadway">
    <h2 class="roadway__title">Дорожные объекты</h2>
    <div class="roadway__wrapper">
      <div class="roadway__head" :class="{ partial: partial }">
        <div class="roadway__map-wrapper">
          <div class="to-map">
            <AppMapBtn :animate="true" :to="{ name: 'Map', query: { id: route.params.id } }" />
          </div>
          <AppMap
            v-show="showMap && mapOptions.bounds"
            class="roadway__map"
            :layers="layers"
            :map-options="mapOptions"
            @map-mounted="mapMounted"
          />
        </div>
      </div>
      <div class="roadway__items">
        <div
          v-for="(item, index) in objectTypeStore.roadObjectTypes"
          :key="index + '-way'"
          class="roadway-item__accordion"
        >
          <WayAccordion :item="item" :index="index" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.global-accordion {
  border-block-end: 1px solid $c-gray-40;
}

.roadway-item {
  &__accordion {
    &:first-child {
      .global-accordion {
        border-block-start: 1px solid $c-gray-40;
      }
    }
  }
}

.band-table,
.width-table,
.clothes-table {
  &__row {
    grid-template-columns: repeat(3, 1fr);
  }
}

.roadway {
  max-width: 1410px;

  &__map {
    canvas {
      display: block;
      height: 23.125rem;
    }
  }

  &__head {
    .roadway-head__map-btn {
      &::before {
        left: 1.25rem;
      }

      &::after {
        display: none;
      }
    }
  }
}

.to-map {
  display: grid;
  position: absolute;
  z-index: 100;
  top: 50px;
  left: 50px;
  width: 270px;
  height: 50px;

  .map-btn {
    border: 2px solid $c-orange;

    &::after {
      display: none;
    }

    &::before {
      left: 20px;
    }
  }
}
</style>

<style scoped lang="scss">
.roadway {
  &__title {
    font-family: $f-family-caption;
    font-size: 1.125rem;
    font-weight: 700;
    @include color('fontColor');
  }

  &__inner {
    margin-top: 3.75rem;
  }

  &__wrapper {
    display: grid;
    gap: 100px 0;
    margin-bottom: 100px;
  }

  &__button-map {
    display: grid;
    position: absolute;
    bottom: -5rem;
    left: 50%;
    grid-column-gap: 1.25rem;
    grid-template-columns: 1.625rem 1fr;
    align-items: center;
    width: max-content;
    transform: translateX(-50%);
    transition: bottom 0.2s ease-out;
    border: 0;
    background-color: transparent;
    color: $c-white;
    font-family: $f-family-caption;
    font-size: 1em;
    font-weight: 700;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 1.625rem;
      height: 1.625rem;
      mask-image: url('@/assets/images/icons/multiply.svg');
      background-color: $c-orange;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
    }
  }

  &__map-wrapper {
    position: relative;
    height: 310px;
    margin-top: 70px;
    overflow: hidden;

    &:hover {
      .roadway__button-map {
        bottom: 15px;
      }
    }
  }

  &__head {
    margin-top: 3.75rem;

    &.partial {
      grid-auto-flow: column;
      grid-column-gap: 7.5rem;
      grid-template-columns: 1fr 53.125rem;
      align-items: center;
      margin-top: 0;
    }
  }
}

.roadway-head {
  &__map-btn {
    width: 14.375rem;
    height: 3.125rem;
    margin-top: 1.875rem;
    border: 2px solid $c-orange;
    cursor: pointer;
  }

  &__text {
    font-family: $f-family-base;
    font-size: 1.125em;
    font-weight: 400;
    @include color('fontColor');
  }

  &__to-map {
    margin-bottom: 60px;
  }
}
</style>
