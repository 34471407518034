import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { fetchWrapper } from '@/api/fetchApi'
import { RoadObjectsTypeId } from '@/utils/lists/lists'

import type { RoadLight } from '@/types/Road'

export const useRoadFurnishingStore = defineStore('road-furnishing', () => {
  const state: { id?: number; light: RoadLight[] } = reactive({
    light: [],
    id: undefined
  })

  const light = computed(() => state.light)

  async function fetchFurnishing(roadId: number) {
    if (state.id === roadId && state.light.length) return

    state.id = roadId

    const response = (await fetchWrapper.get(
      `/roads/${roadId}/objects/${RoadObjectsTypeId.ROAD_LIGHT}?page_number=1&elements_on_page=50`,
      {}
    )) as { data: RoadLight[] }

    state.light = response.data
  }

  return {
    light,
    fetchFurnishing
  }
})
