import { LangDictionary } from '@/types/Language'
import { LngLatBoundsLike } from 'maplibre-gl'

export const DEBOUNCE_INPUT_DELAY = 250

export enum GEOMETRY_CONSTANTS {
  MAXIMUM_ZOOM = 19,
  ROAD_SIGNS_SHOW_ZOOM = 15,
  ROAD_OBJECTS_ZOOM = 17.51,
  DISTANCE_ROAD_SIGN_HIDE = 2,
  BOX_TYPE_1 = 96,
  BOX_TYPE_2 = 170
}

export const EGRN_SOURCE_NAME = 'egrn-custom'

interface IBounds {
  [key: string]: LngLatBoundsLike
}

export const BOUNDS: IBounds = {
  RUSSIA: [
    [16.347656, 31.428663],
    [201.621094, 76.142958]
  ],
  KUZBASS: [83.880615, 52.086257, 89.769287, 56.878999]
}

export const ROUNDING_IRI_VALUE = 2

export const LIMIT_IRI_VALUE = 'Предельно-допустимое значение'

export const ROAD_DEFECTS_SOURCE_NAME = 'road-neiro-defect'

export const dict: LangDictionary = {
  0: 'id',
  1: 'name',
  2: 'meaning',
  3: 'identification_number',
  4: 'length',
  5: 'skdf_number',
  6: 'technical_category',
  7: 'state',
  8: 'short_name'
}

export const colorBorderChart = '#1995AD'

export const lineColorsChart = [
  '#f06d30',
  '#8AAAE5',
  '#F9E795',
  '#FFBB00',
  '#F96167',
  '#20948B',
  '#2C5F2D',
  '#990011',
  '#FCF6F5',
  '#B85042',
  '#FEE715',
  '#FBEAEB'
]
