<script lang="ts" setup>
import { computed, reactive } from 'vue'
import { useRoute, RouteLocationRaw } from 'vue-router'

import router from '@/router'
import { useAuthStore } from '@/stores'
import { l } from '@/utils/lang'

import InputField from '@/components/ui/InputField.vue'
import ButtonCorner from '@/components/ui/ButtonCorner.vue'

type TLogin = {
  username: string | null
  password: string | null
  error: string | null
  locking: boolean
}

const route = useRoute()
const authStore = useAuthStore()

const supportIsOpen = computed(() => authStore.supportIsOpen)

if (authStore.token) {
  router.push('/')
}

const login: TLogin = reactive({
  username: null,
  password: null,
  error: null,
  locking: false
})

const onSubmit = (): Promise<void> => {
  login.locking = true

  return authStore
    .login(login.username!, login.password!)
    .then(() => {
      const path = (route.query?.returnUrl || '/') as RouteLocationRaw
      router.push(path)
    })
    .catch((error) => {
      login.password = null
      login.error = error
    })
    .finally(() => {
      login.locking = false
    })
}

const disabled = computed<boolean>(() => {
  return login.locking || !login.username || !login.password
})

function toggleSupport(e: Event) {
  e.stopPropagation()

  if (!supportIsOpen.value) authStore.toggleSupport()
}
</script>

<template>
  <form class="authForm" @submit.prevent="onSubmit">
    <h3 class="authForm__caption">Авторизация</h3>
    <div class="authForm__content">
      <div class="authForm__field">
        <input-field
          v-model="login.username"
          :has-error="!!login.error"
          type="text"
          placeholder="Логин"
        />
      </div>
      <div class="authForm__field">
        <input-field
          v-model="login.password"
          :has-error="!!login.error"
          type="password"
          placeholder="Пароль"
          @update:model-value="login.error = null"
        />
      </div>
      <div v-if="login.error" class="authForm__field authForm__error">
        {{ l(login.error.replaceAll('"', '')) }}
      </div>
      <div class="authForm__support">
        <button type="button" class="support-btn link link--bold" @click="toggleSupport">
          Служба поддержки
        </button>
      </div>
      <button-corner class="authForm__login" type="submit" :disabled="disabled" text="Войти" />
    </div>
  </form>
</template>

<style lang="scss">
.authForm input {
  padding: 0 30px;
  background-color: transparent;
  color: $c-white;
  font-family: $f-family-base;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.95px;
  text-align: left;

  &::placeholder {
    font-family: $f-family-base;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.95px;
    text-align: left;
  }

  &:-webkit-autofill {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
    font-family: $f-family-base;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.95px;
    text-align: left;
  }
}

.theme-light .authForm input {
  color: $c-white;
}

.authForm {
  display: grid;
  grid-gap: 50px 0;
  color: $c-white;

  &__field {
    text-align: center;

    &:not(:first-child) {
      margin-block-start: 35px;
    }
  }

  &__caption {
    font-family: $f-family-caption;
    font-size: 18px;
    font-weight: 700;
    line-height: 25.65px;
    text-align: left;
  }

  &__support {
    display: flex;
    justify-content: space-between;
    margin-block-start: 30px;
    font-family: $f-family-base;
    font-size: 16px;
    font-weight: 400;
    line-height: 15.82px;
    text-align: left;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;
    padding: 1rem 2rem;
    background-color: rgba($c-orange, 0.3);
    font-weight: 700;
  }
}
</style>

<style lang="scss" scoped>
.support-btn {
  border: 0;
  background-color: transparent;
  color: $c-orange;
  font-family: $f-family-base;
  font-size: 16px;
  font-weight: 700;
  line-height: 15.82px;
  text-align: right;
  cursor: pointer;

  &:hover {
    color: $c-white;
  }
}

button.authForm__login {
  display: block;
  margin: 60px auto 0;
}
</style>
