import geometryType from '@/utils/map/geometryType'
import { addSource } from '@/utils/map/source'

import type { Map } from 'maplibre-gl'
import type { MapRenderParams, RenderFeatureCluster, RenderFeaturesParams } from '@/types/map/Map'

const { defineGeometryType } = geometryType()

function renderFeatures(map: Map, props: RenderFeaturesParams) {
  const { layer, cluster } = props

  const types = defineGeometryType(layer)
  addSource(map, layer, cluster, types)

  types.forEach((el) => el.render(map, layer))
}

export default function (props?: RenderFeatureCluster) {
  return {
    renderFeatures: (layer: MapRenderParams, map: Map) =>
      renderFeatures(map, { cluster: props?.cluster, layer })
  }
}
