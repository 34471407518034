<script setup lang="ts">
import { computed, watch } from 'vue'

import { useDefectChartStore } from '@/stores/road/defects/road-defects-chart'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter.ts'
import { useDefectsStore } from '@/stores'
import useChartDefects from '@/composition/defects/useChartDefects'
import useChartHighlight from '@/composition/defects/useChartHighlight'

import AppChart from '@/components/ui/AppChart.vue'

import type { ECElementEvent, ECharts } from 'echarts'

const emits = defineEmits(['chart-clicked', 'mounted'])

const defectFilterStore = useDefectFilterStore()
const chartDefectsStore = useDefectChartStore()
const defectsStore = useDefectsStore()
const { defectLegends } = useChartDefects()

const { updateChart } = useChartHighlight()

const chartData = computed(() => chartDefectsStore.chartData)
const defects = computed(() => defectsStore.defects)
const defectsColor = computed(() => defectFilterStore.defectsColor.map((color) => color.value))
const series = computed(() => chartDefectsStore.series)
const defectTypes = computed(() => defectFilterStore.defectTypes)
const style = computed(() => defectFilterStore.style)
const legendItems = computed(() => defectLegends(defectTypes, style))
const selected = computed(() => chartDefectsStore.selectedItems)
const chart = computed(() => chartDefectsStore.chart)

function zoom() {
  chartDefectsStore.setSelected([...chartDefectsStore.selectedItems])
}

function updateData() {
  if (!chart.value.chart) throw new Error('Недостаточно данных')

  chart.value.chart.setOption({
    series: chartData.value.map((el, index) => ({
      ...el,
      itemStyle: {
        color: defectsColor.value[index]
      }
    }))
  })
}

function click(e: Partial<ECElementEvent>) {
  emits('chart-clicked', e)
}

function mounted(e: ECharts) {
  chartDefectsStore.updateInstance(e)
}

watch(
  defects,
  () => {
    chartDefectsStore.updateChartData()
    updateChart(selected.value)
  },
  {
    deep: true
  }
)
</script>

<template>
  <div>
    <AppChart
      id="chart__wrapper-photo"
      :legends="true"
      class="defects__chart"
      :selected="selected"
      :data="chartData"
      type="scatter"
      :width="'auto'"
      :height="620"
      :padding="45"
      :metric="{ x: 'м', y: 'м²' }"
      :left="55"
      :bottom="0"
      :colors="defectsColor"
      :series="series"
      :symbol-size="8"
      :data-zoom="{
        start: 0,
        end: 100,
        height: 35
      }"
      :legend-items="legendItems"
      :chart-style="style"
      :graphic="{
        type: 'circle',
        'elements-rect': {
          progressive: true
        }
      }"
      highlight-color="#03ffd1"
      @chart-mounted="mounted"
      @chart-clicked="click"
      @chart-zoomed="zoom"
      @chart-data-updated="updateData"
    />
  </div>
</template>

<style lang="scss" scoped></style>
