import MapboxDraw, { DrawCreateEvent, DrawSelectionChangeEvent } from '@mapbox/mapbox-gl-draw'
import { IControl, Map, NavigationControl } from 'maplibre-gl'

import { Geometry, Position, area, length } from '@turf/turf'
import { MapFeature } from '@/types/map/Map'
import { l } from '@/utils/lang'

const BASE_CLASS_ZOOM_NAV = 'maplibregl-ctrl-zoom'

interface MapControlsOptions {
  showZoom: boolean
}

class MapControls {
  features: Array<MapFeature> = []
  popup?: HTMLElement | null = undefined
  map?: Map = undefined
  draw: IControl | null = null
  navigation: IControl | null = null

  drawUpdate(e: DrawSelectionChangeEvent | DrawCreateEvent) {
    this.calcMetrics(e as DrawCreateEvent)
    this.showInfo(e as DrawSelectionChangeEvent)
  }

  addContol(map: Map, options?: MapControlsOptions) {
    this.map = map

    this.draw = new MapboxDraw({
      displayControlsDefault: true,
      controls: {
        combine_features: false,
        line_string: true,
        point: true,
        polygon: true,
        trash: true,
        uncombine_features: false
      }
    }) as unknown as IControl

    this.navigation = new NavigationControl({
      showZoom: Boolean(options?.showZoom)
    })

    this.map.addControl(this.draw)
    this.map.addControl(this.navigation)

    if (options?.showZoom) this.replaceZoomNav()

    this.translateToolsTitle()
  }

  private replaceZoomNav() {
    const buttons = [`.${BASE_CLASS_ZOOM_NAV}-in`, `.${BASE_CLASS_ZOOM_NAV}-out`]

    const appWrapper = document.querySelector('.page')

    buttons.forEach((el) => {
      const btn = document.querySelector(el)

      if (btn) appWrapper?.appendChild(btn)
    })
  }

  translateToolsTitle() {
    const tools = [
      document.querySelector('.mapboxgl-ctrl-group'),
      document.querySelector('.maplibregl-ctrl-group')
    ]

    const btns = tools
      .map((el) => {
        if (el) return [...el.children]
        return []
      })
      .flat()

    btns.forEach((btn) => {
      btn.setAttribute('title', l(btn.getAttribute('title')!))
    })
  }

  swapButtons() {
    const polygonBtn: Element | null = document.querySelector('.mapbox-gl-draw_polygon')
    const trashBtn = document.querySelector('.mapbox-gl-draw_trash')

    if (trashBtn && polygonBtn) {
      trashBtn.parentElement?.insertBefore(polygonBtn, trashBtn)
    }
  }

  calcMetrics(e: DrawCreateEvent) {
    const [area, point, length] = this.calcValues(e)

    const isArea = !!(area && length)
    const isPoint = !area && !length

    const { id } = e.features[0]

    const currentFeature = this.features.find((el) => el.id === id)

    const isAreaValue = isArea ? area : undefined
    const isPointValue = isPoint ? point : undefined

    const value = isAreaValue ?? (isPointValue || length)

    if (currentFeature) {
      currentFeature.value = value
      return
    }

    const data = {
      id: e.features[0].id as string,
      value,
      isArea,
      isPoint
    }

    this.features.push(data)
  }

  calcValues(e: DrawCreateEvent) {
    const areaValue = +area(e.features[0]).toFixed(2)
    const pointValue = (e.features[0].geometry as Geometry).coordinates as Array<Position>
    const lengthValue = +length(e.features[0]).toFixed(3)

    return [areaValue, pointValue, lengthValue]
  }

  showInfo(e: DrawSelectionChangeEvent) {
    if (!this.map) throw new Error('Нет экземпляра карты')

    if (this.popup) {
      this.popup.remove()
      this.popup = undefined
    }

    const [feature] = e.features

    if (!feature) return

    const { id } = feature

    const current = this.features.find((el) => el.id === id)

    if (!current) return

    const text = this.defineText(current)

    const template = `
        <div class="map-popup__map-tools">
          <p>${text}</p>
        </div>
      `

    this.map._container.insertAdjacentHTML('beforeend', template)

    this.popup = document.querySelector('.map-popup__map-tools')
  }

  defineText(current: MapFeature) {
    if (current.isArea) {
      const number = ((current.value as number) * 1e-6).toFixed(2)

      return `Площадь: ${(+number).toLocaleString()} км<sup>2</sup>`
    }

    if (current.isPoint) {
      const [lng, lat] = current.value as Array<Position>

      return `lng ${lng}<br>lat ${lat}`
    }

    return `Длина: ${(+(current.value as number).toFixed(2)).toLocaleString()} км`
  }

  public removeZoomBtns() {
    const zoomBtns = ['maplibregl-ctrl-zoom-in', 'maplibregl-ctrl-zoom-out']

    zoomBtns.forEach((el) => {
      const btn = document.querySelector(`.${el}`)

      if (btn) btn.remove()
    })
  }

  get() {
    return [this.draw, this.navigation]
  }

  public drawDelete() {
    const info = document.querySelector('.map-popup__map-tools')

    if (info) info.remove()
  }
}

export default MapControls
