<script lang="ts" setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

import { useThemeStore } from '@/stores'
import useTheme from '@/composition/useTheme'

import BackgroundContextMenu from './BackgroundContextMenu.vue'

import { APP_THEMES } from '@/utils/lists/lists'

const props = defineProps<{ customclass?: string }>()

const themeStore = useThemeStore()
const route = useRoute()
const themeWrapper = new useTheme()

const theme = computed(() => themeStore.theme)

const mapPages = ['/map', '/geometry']

const isMapPage = computed(() => mapPages.includes(route.path))
const isLightTheme = computed(() => theme.value === APP_THEMES.LIGHT)

const whiteTheme = ref()
const darkNoise = ref()
const whiteNoise = ref()

onMounted(() => {
  if (isLightTheme.value) setWhiteNoise()
  else setDarkNoise()
})

watch(
  whiteTheme,
  (value) => {
    if (!value) return

    const themeImmedParams = {
      value,
      mapPages,
      route,
      theme
    }

    themeWrapper.setWhiteThemeImmed(themeImmedParams)
  },
  {
    deep: true
  }
)

watchEffect(() => themeCb(''))

watch(isMapPage, () => themeCb('change-type-page'), {
  deep: true
})

function themeCb(type: string) {
  const timeoutCb = isLightTheme.value ? setWhiteNoise : setDarkNoise

  const timeout = type === 'change-type-page' ? 0 : isLightTheme.value ? 300 : 100

  setTimeout(timeoutCb, timeout)
}

function setWhiteNoise() {
  if (darkNoise.value && whiteNoise.value) {
    darkNoise.value.style.display = 'none'
    whiteNoise.value.style.display = 'block'
  }
}

function setDarkNoise() {
  if (darkNoise.value && whiteNoise.value) {
    darkNoise.value.style.display = 'block'
    whiteNoise.value.style.display = 'none'
  }
}
</script>

<template>
  <template v-if="!isMapPage">
    <div class="app-background-gradient"></div>
    <div class="app-background-gradient-two"></div>
    <div ref="darkNoise" class="app-background-noise theme-dark"></div>
    <div ref="whiteNoise" class="app-background-noise theme-light"></div>
    <div class="app-background-animate app-background-animate--black"></div>
    <div
      ref="whiteTheme"
      class="app-background-animate app-background-animate--white"
      :class="{ showed: APP_THEMES.LIGHT === theme, main: props.customclass }"
    ></div>
  </template>
  <template v-else>
    <BackgroundContextMenu :opacity="false"></BackgroundContextMenu>
  </template>
</template>

<style lang="scss" scoped>
.app-background-gradient {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(0% 124.03% at 100% 53.66%, #23141b 0%, rgb(40 40 40 / 10%) 100%);
}

.app-background-gradient-two {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: linear-gradient(106.94deg, #2c2827 0%, $c-gray-05 25.03%);
}

.app-background-noise {
  display: none;
  position: absolute;
  z-index: 3;
  inset: 0;
  width: 100%;
  height: 100%;
  transition: all 0s linear;
  background-repeat: repeat;

  &.theme-dark {
    background-image: url('@/assets/images/noise-dark.png');
  }

  &.theme-light {
    background-image: url('@/assets/images/noise-light.png');
  }
}

.app-background-animate {
  position: fixed;
  inset: 0;
  z-index: 4;
  transition: all 2.4s ease-in-out;

  &--black {
    background-color: rgba(255 255 255 / 7%);
  }

  &--white {
    display: block;
    position: fixed;
    top: 0;
    left: 100vw;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    transform-origin: 10px 10px 10px 10px;
    border-radius: 100%;
    background-color: rgba(255 255 255 / 95%);
    aspect-ratio: 1/1;
  }
}
</style>
