<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

import Button from '@/components/ui/Button.vue'
import IconBase from '@/components/icons/IconBase.vue'
import InputField from '@/components/ui/InputField.vue'

import Close from '@/assets/images/icons/close.svg'

import type { RoadResponse } from '@/types/Road'

const props = defineProps<{
  roads: RoadResponse[]
}>()

const emits = defineEmits(['fill-skdf', 'remove-selected', 'to-skdf'])

const roads = computed(() => props.roads)

function fillSkdfNumber(road: RoadResponse, e: InputEvent) {
  const params = {
    value: (e.target as HTMLInputElement).value,
    id: road.id
  }

  emits('fill-skdf', params)
}

function removeSelected(road: RoadResponse) {
  emits('remove-selected', road.id)
}

async function applySkdf() {
  roads.value.forEach((el) => {
    if (!el.skdf_number) emits('remove-selected', el.id)
  })

  emits('to-skdf')
}
</script>

<template>
  <form class="without__form" @submit.prevent="applySkdf">
    <p class="without__warning">
      У данных дорог нет номера СКДФ.<br />Их данные не будут экспортированы.
      <br />
      <br />
      Если номер СКДФ неизвестен, то можно снять выделение
    </p>
    <div v-for="(item, index) in roads" :key="index + '-skdf-road-without'" class="without__item">
      <div class="without__left">
        <span class="without__remove" @click="() => removeSelected(item)">
          <IconBase :width="15" :height="15" view-box="0 0 13 13" icon-color="red">
            <Close />
          </IconBase>
        </span>
        <RouterLink :to="{ name: 'Road', params: { id: item.id } }" class="without__name">
          {{ item.short_name }}
        </RouterLink>
      </div>
      <InputField
        class="without__input"
        type="text"
        @input="(e: InputEvent) => fillSkdfNumber(item, e)"
      ></InputField>
    </div>
    <div class="without__nav">
      <Button type="submit" text="Применить"></Button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.without {
  &__form {
    padding: 35px;
  }

  &__item {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 35px;
    grid-template-columns: 1fr 400px;
    padding: 10px 20px;
    border-right: 1px solid rgba($c-gray-90, 0.2);
    border-bottom: 1px solid rgba($c-gray-90, 0.2);
    border-left: 1px solid rgba($c-gray-90, 0.2);
    background-color: rgba($c-gray-90, 0.1);

    &:nth-child(2) {
      border-top: 1px solid rgba($c-gray-90, 0.2);
    }
  }

  &__name {
    display: grid;
    align-items: center;
    color: $c-white;
    font-size: 18px;
  }

  &__input {
    height: 40px;
  }

  &__warning {
    margin-block-end: 45px;
    color: rgba(200 200 0 / 100%);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  &__nav {
    display: grid;
    justify-content: end;
    margin-top: 45px;
  }

  &__left {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 20px;
    grid-template-columns: 15px 1fr;
    align-items: center;
  }

  &__remove {
    display: grid;
    align-items: center;
    height: max-content;
    cursor: pointer;
  }
}
</style>
