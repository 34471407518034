import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

// import { APP_THEMES } from '@/utils/lists/lists'

// const LOCAL_STORAGE_THEME_KEY = import.meta.env.VITE_LOCAL_STORAGE_THEME_KEY

export const useThemeStore = defineStore('theme-store', () => {
  const state = reactive({
    theme: 'theme-dark' // theme: ls.get(LOCAL_STORAGE_THEME_KEY) || APP_THEMES.DARK
  })

  const theme = computed(() => state.theme)

  function changeTheme(theme: string) {
    // ls.set(LOCAL_STORAGE_THEME_KEY, theme)

    state.theme = theme
  }

  return {
    theme,
    changeTheme
  }
})
