import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

import { fetchWrapper } from '@/api/fetchApi'
import useDefects from '@/composition/defects/useDefects'
import { uniqFieldsArray } from '@/utils/uniq-fields-array'
import { useRoadStore } from './road'
import { useDefectFilterStore } from './road-defects-filter'
import { getDefectUrl } from '@/utils/defect-url'

import type {
  Defect,
  DefectFilter,
  DefectUrlParams,
  RoadDefectResponse,
  RoadNeiroDefect
} from '@/types/RoadDiagnostic'

const { defectsToInfo, updateDefectInfo, prepareDefects } = useDefects()

export const useDefectsStore = defineStore('defects-store', () => {
  const roadStore = useRoadStore()
  const defectFilterStore = useDefectFilterStore()

  const state = reactive({
    defects: {
      selected: [] as Defect[],
      data: [] as RoadNeiroDefect[]
    },
    pagination: {
      elementsOnPage: 50,
      page: 1,
      total: 10
    },
    rating: 0
  })

  const roadId = computed(() => roadStore.getRoad?.id)

  const activeRoadLineId = computed(() => defectFilterStore.activeRoadLineId)
  const filter = computed(() => defectFilterStore.filter)
  const defectNames = computed(() => defectFilterStore.defectNames)

  const defects = computed(() => state.defects.data)
  const selectedDefect = computed(() => state.defects.selected)
  const pagination = computed(() => state.pagination)
  const ratingRoadDefect = computed(() => state.rating)

  function setDefects(response: RoadDefectResponse, segmentId?: number) {
    state.defects.data = prepareDefects(response.data, segmentId)
    state.pagination = createPagination(response)
  }

  /**
   * Фетчинг дефектов
   * */
  async function fetchDefects(segmentId?: number, payload?: DefectFilter) {
    if (!roadId.value) throw new Error('Недостаточно данных')

    try {
      const urlParams: DefectUrlParams = {
        roadId: roadId.value,
        segmentId,
        activeLine: activeRoadLineId.value
      }

      const url = getDefectUrl(urlParams)
      const body = payload ?? filter.value
      const response = (await fetchWrapper.post(url, body)) as RoadDefectResponse

      setDefects(response, segmentId)
    } catch (e) {
      throw new Error(e.message)
    }
  }

  /**
   * Создание пагинации
   * */
  function createPagination(response: RoadDefectResponse) {
    const update = {
      page: response.page_number,
      elementsOnPage: response.elements_on_page,
      total: response.total
    }

    return Object.assign({}, pagination.value, update)
  }

  function updateSelectedDefect(defect: Defect[]) {
    state.defects.selected = defect
  }

  function calcRoadQuality() {
    if (!roadId.value) throw new Error('Недостаточно данных')

    const infos = defectsToInfo(updateDefectInfo(defects.value, roadId.value))
    const lines = uniqFieldsArray<Defect, number>(infos, 'line_number')

    const [squarePothole, squreCrack] = defectNames.value.map((defectName) =>
      calcSquareDefect(defectName, infos, lines)
    )

    const rating = (1 - (squarePothole * 0.7 + squreCrack * 0.3) / 5000) * 100

    roadStore.setRating(rating < 0 ? 0 : Math.ceil(rating))
  }

  function calcSquareDefect(defectName: string, defects: Defect[], lines: number[]) {
    return lines.reduce((acc, line) => {
      const current = defects.filter(
        (el) => el.line_number === line && el.class_name === defectName
      )

      current.forEach((item) => {
        acc += Math.round(item.sum_square / item.count_class)
      })

      return acc / current.length
    }, 0)
  }

  return {
    filter,
    selectedDefect,
    defects,
    ratingRoadDefect,

    updateSelectedDefect,
    setDefects,
    calcRoadQuality,

    fetchDefects
  }
})
