<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useRoadRegulatory } from '@/stores'
import { Breadcrumb } from '@/utils/breadcrumbs'

import IconDoc from '@/components/icons/IconDoc.vue'
import IconRemove from '@/components/icons/IconRemove.vue'

import IconBase from '@/components/icons/IconBase.vue'
import AppConfirm from '@/components/ui/AppConfirm.vue'

import { TBreadcrumb } from '@/types/ui/Breadcrumb'

const { VITE_API_URL } = import.meta.env

const emits = defineEmits(['setBr'])

const route = useRoute()

const roadRegulatory = useRoadRegulatory()

const data = computed(() => roadRegulatory.getRegulatory)

const confirmIsOpen = ref(false)
let selectedDoc = 0

async function downloadDoc(id: number) {
  const roadId = +route.params.id

  const a = document.createElement('a')
  a.setAttribute('href', `${VITE_API_URL}/roads/roads/${roadId}/documents/${+id}/dowload/`)
  a.setAttribute('download', '')

  a.click()

  a.remove()
}

async function openRemoveConfirm(e: Event, id: number) {
  e.stopPropagation()

  confirmIsOpen.value = true
  selectedDoc = id
}

async function uploadDoc(e: Event) {
  const t: EventTarget | null = e.target

  if (!t) return

  const file = (t as HTMLInputElement).files?.[0]

  if (!file) return

  roadRegulatory.uploadDocument(+route.params.id, file)
}

onBeforeMount(() => {
  emits('setBr', new (Breadcrumb as unknown as TBreadcrumb)('Документы', null))
})

async function removeDocument() {
  await roadRegulatory.removeDocument(+route.params.id, selectedDoc)

  confirmIsOpen.value = false
  selectedDoc = 0
}

function getDocumentSize(size: number) {
  const greaterMb =
    size / 1_000_000 > 1 ? `${(size / 1_000_000).toLocaleString('ru')} Мб` : undefined
  const greaterKb = size / 1_000 > 1 ? `${(size / 1_000).toLocaleString('ru')} Кб` : undefined

  return greaterMb || greaterKb || `${size} Байт`
}
</script>

<template>
  <div class="regulatory">
    <h2 class="regulatory__title">Документы</h2>

    <div class="regulatory__docs">
      <label class="regulatory-docs__item regulatory-docs__item--upload" for="file">
        <div class="regulatory-docs__upload">
          <div class="regulatory-upload__icon"></div>
          <div class="regulatory-upload__label">
            <p class="regulatory-upload__title">Загрузить</p>
            <p class="regulatory-upload__exts">(.doc, .rtf, .pdf, .txt, .xls)</p>
          </div>
        </div>
        <input
          id="file"
          class="regulatory-docs__input"
          type="file"
          accept=".doc,.pdf,.txt,.xls,.rtf,.docx,.xlsx"
          @change="uploadDoc"
        />
      </label>
      <div
        v-for="(doc, index) in data"
        :key="index + '-regulatory-head'"
        class="regulatory-docs__item"
        @click="() => downloadDoc(doc.id)"
      >
        <div class="regulatory-docs__head">
          <div class="regulatory-docs__icons">
            <IconBase class="regulatory-docs__document" width="35" height="40" view-box="0 0 35 40">
              <IconDoc></IconDoc>
            </IconBase>

            <div class="regulatory-docs__remove" @click="(e) => openRemoveConfirm(e, doc.id)">
              <IconBase width="15" height="18" view-box="0 0 15 18">
                <IconRemove></IconRemove>
              </IconBase>
            </div>
          </div>
          <h2 class="regulatory-docs__name" :title="doc.name">{{ doc.name }}</h2>
        </div>

        <div class="regulatory-docs__info">
          <span class="regulatory-docs__extension">{{ doc.name?.split('.').at(-1) }}</span>
          <span class="regulatory-docs__size"> {{ getDocumentSize(doc.size) }} </span>
        </div>
      </div>
    </div>
    <Transition name="fade">
      <AppConfirm
        v-if="confirmIsOpen"
        title="Удаление файла"
        description="Вы уверены что хотите удалить этот файл без возможности восстановления?"
        accept-text-btn="Удалить"
        @close="confirmIsOpen = false"
        @accept="removeDocument"
      ></AppConfirm>
    </Transition>
  </div>
</template>

<style lang="scss">
.regulatory-upload {
  &__icon {
    display: grid;
    position: relative;
    width: 45px;
    height: 45px;
    transition: all 0.2s linear;
    border-radius: 100%;
    background-color: $c-orange;

    &::after,
    &::before {
      content: '';
      display: grid;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 2px;
      transform: translate(-50%, -50%);
      background-color: $c-gray-15;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &__label {
    display: grid;
    grid-gap: 5px 0;
    color: $c-gray-65;
  }

  &__title {
    transition: all 0.2s linear;
    font-family: $f-family-caption;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__exts {
    font-family: $f-family-base;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>

<style lang="scss">
.regulatory-docs {
  &__document {
    fill: #686868b2;
  }

  &__remove {
    transition: all 0.2s linear;

    svg {
      g {
        transition: all 0.2s linear;
        fill: $c-orange;
      }
    }
  }

  &__item {
    &:hover {
      .regulatory-docs__remove {
        @include background-color('removeButtonDocHover');

        svg {
          g {
            @include fill('removeButtonDocHoverIcon');
          }
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.regulatory {
  margin-bottom: 5rem;

  &__title {
    transition: color 0.3s $main-animation;
    font-family: $f-family-caption;
    font-size: 1.125em;
    font-weight: 700;

    @include color('fontColor');
  }

  &__docs {
    display: grid;
    grid-gap: 30px 26px;
    grid-template-columns: repeat(4, 333px);
    margin-top: 40px;
  }
}

.regulatory-docs {
  &__remove {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    @include background-color('removeButtonDoc');
  }

  &__item {
    display: grid;
    grid-auto-flow: row;
    height: 187px;
    padding: 20px;
    transition: all 0.2s linear;
    border: 1px solid $c-gray-40;
    cursor: pointer;

    &--upload {
      display: grid;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      &:hover {
        .regulatory-upload__icon {
          background-color: $c-orange-light;
        }

        .regulatory-upload__title {
          @include color('breadcrumbColor');
        }
      }
    }

    &:hover {
      @include background-color('widgetBG');
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }

  &__upload {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0 15px;
    align-items: center;
  }

  &__head {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 20px 0;
  }

  &__icons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
  }

  &__doc-icon {
    display: block;
    width: 35px;
    height: 40px;
  }

  &__info {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    margin-top: auto;
    color: $c-gray-65;
    font-family: $f-family-base;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: right;
  }

  &__name {
    display: -webkit-box;
    overflow: hidden;
    font-family: $f-family-caption;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 128%;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @include color('fontColor');
  }
}

@media (width <= 1860px) {
  .regulatory {
    &__docs {
      grid-template-columns: repeat(3, 336px);
    }
  }
}

@media (width <= 1580px) {
  .regulatory {
    &__docs {
      grid-template-columns: repeat(2, 336px);
    }
  }
}
</style>
