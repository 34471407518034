import { FetchRoadsPayload, FetchRoadsParams, RoadsInSystem, IAllOrtoInfo } from '@/types/Road'
import { fetchWrapper } from './fetchApi'

export const roadApi = {
  async getRoads(params: FetchRoadsParams) {
    const { page, elementsOnPage, name, skdf_number } = params

    const body: FetchRoadsPayload = {}

    if (name) body.name = name
    if (skdf_number) body.skdf_number = skdf_number

    try {
      const response = await fetchWrapper.post(
        `/roads/collection/?page_number=${page}&elements_on_page=${elementsOnPage}`,
        body
      )

      return response as RoadsInSystem
    } catch (e) {
      throw new Error('Error in getRoads')
    }
  },
  async fetchAllOrtos() {
    try {
      const response = await fetchWrapper.get(`/roads/roads/tails/`)

      return response as IAllOrtoInfo[]
    } catch {
      throw new Error('Fetch all ortos error')
    }
  },
  async getGeometry(roadId: number, skdfId: string) {
    try {
      const response = await fetchWrapper.post(
        `/external/skdf/get_geom?road_id=${roadId}&id_skdf=${skdfId}`
      )

      return response
    } catch (e) {
      throw new Error(e)
    }
  }
}
