import { useDefectsStore, useRoadDefectsMapStore } from '@/stores'

import useDefects from '@/composition/defects/useDefects'
import { ROAD_DEFECTS_SOURCE_NAME } from '@/utils/consts/consts'
import { MapFeature } from '@/utils/map'

import type { Map } from 'maplibre-gl'
import type { Defect, RoadNeiroDefect } from '@/types/RoadDiagnostic'
import type { MapFeatureRendered } from '@/types/map/Map'
import { computed } from 'vue'

const { updateDefectInfo } = useDefects()

/**
 * Преобразование информации о дефектах в GeoJSON
 * */
function defectInfoToGeoJSON(info: RoadNeiroDefect) {
  const position = info?.position_geojson ? JSON.parse(info.position_geojson) : {}

  return {
    type: 'Feature',
    properties: info,
    geometry: {
      type: position.type,
      coordinates: position.coordinates
    }
  }
}

/**
 * Фильтр дефектов, которые не имеют геометрии
 */
function withGeometry(defects: RoadNeiroDefect[]) {
  return defects.filter((defect) => defect.position_gejson)
}

/**
 * Преобразование дефектов в данные для карты(geojson)
 */
export function defectDataToGeoJSON(defects: RoadNeiroDefect[], roadId: number) {
  const copy = updateDefectInfo(withGeometry(defects), roadId)

  return copy.map((info) => {
    return defectInfoToGeoJSON(info)
  })
}

/**
 * Перелет на карте
 */
export function flyToMap(map: Map) {
  const roadDefectsMapStore = useRoadDefectsMapStore()
  roadDefectsMapStore.updateInstance(map)

  const defectStore = useDefectsStore()
  const [first] = defectStore.selectedDefect

  if (!first?.position_geojson) return

  const defectCoordinates = JSON.parse(first.position_geojson)

  map.flyTo({
    center: defectCoordinates.coordinates,
    essential: true,
    zoom: 17
  })
}

export function mapDefectsHighlight() {
  const roadDefectsMapStore = useRoadDefectsMapStore()
  const defectsStore = useDefectsStore()

  const selected = computed(() => defectsStore.selectedDefect)
  const map = computed(() => roadDefectsMapStore.map)

  if (!map.value.map || !selected.value.length) return

  const features = map.value.map.querySourceFeatures(ROAD_DEFECTS_SOURCE_NAME)

  const selectedMask = selected.value.map((defect) => defect.mask_file_uuid)

  const current = features.find((el) =>
    JSON.parse(el.properties.defect_list).find((item: Defect) =>
      selectedMask.includes(item.mask_file_uuid)
    )
  )

  if (!current) return

  const renderedFeatures = new (MapFeature as unknown as MapFeatureRendered)({
    source: ROAD_DEFECTS_SOURCE_NAME,
    id: current.id as string | number
  })

  roadDefectsMapStore.updateSelectedFeature(renderedFeatures)
}
