import { Defect, DefectTypeMaskStyle, OptionsMask } from '@/types/RoadDiagnostic.d'
import { ComputedRef } from 'vue'

const DEFECT_POPUP_WIDTH = 600
const MARGIN_POPUP = 50

/**
 * Подсчет X координаты попапа
 */
function calculateXCoordinate(coorX: number) {
  const isIntersection = window.innerWidth - coorX < DEFECT_POPUP_WIDTH
  const intersectionX = window.innerWidth - (DEFECT_POPUP_WIDTH + MARGIN_POPUP)

  return isIntersection ? intersectionX : coorX
}

function currentStyle(style: OptionsMask[], defect: Defect) {
  return style.find((el) => el.name === defect.class_name)
}

function visibleDefectInPopup(types: DefectTypeMaskStyle[], defects?: Defect[]) {
  if (!defects?.length) return []

  return defects.filter((el) => {
    const current = types.find((item) => item.name === el.class_name)

    return current?.show
  })
}

function calcOpacity(
  model: ComputedRef<number>,
  minValue: ComputedRef<number>,
  maxValue: ComputedRef<number>
) {
  return `${(100 * (model.value - minValue.value)) / (maxValue.value - minValue.value) || 0}%`
}

export default function () {
  return {
    currentStyle,
    calcOpacity,
    visibleDefectInPopup,
    calculateXCoordinate
  }
}
