import { GeometryMapLayers } from '@/utils/lists/lists'
import { EGRN_SOURCE_NAME } from '@/utils/consts/consts'

import type { Map } from 'maplibre-gl'
import type { MapRenderParams, MapRenderProperties } from '@/types/map/Map'

const { VITE_API_URL } = import.meta.env

function getOrtoBounds(bounds: number[][]): [number, number, number, number] {
  return [bounds[3][0], bounds[3][1], bounds[1][0], bounds[1][1]]
}

function roadSignSource(map: Map, layer: MapRenderParams) {
  if (!map.getSource(layer.params.name) && layer.data) {
    map.addSource(layer.params.name, {
      type: 'geojson',
      data: layer.data,
      cluster: true,
      clusterMaxZoom: 20,
      clusterRadius: 12,
      clusterProperties: {
        sign: ['concat', ['concat', ['get', 'sign_gost'], ','], ','],
        gost: ['concat', ['concat', ['get', 'gost'], ','], ','],
        height: ['concat', ['concat', ['get', 'sign_height'], ','], ','],
        sign_number: ['concat', ['concat', ['get', 'sign_number'], ','], ','],
        sign_text: ['concat', ['concat', ['get', 'sign_text'], ','], ','],
        sign_azimuth: ['concat', ['concat', ['get', 'sign_azimuth'], ','], ',']
      },
      generateId: true
    })
  }
}

export function addSource(
  map: Map,
  layer: MapRenderParams,
  cluster: string[] = [],
  type: Partial<MapRenderProperties>[]
) {
  const { params } = layer

  const isCluster = cluster.includes(params.name) && layer.data

  if (isCluster) {
    const isRoadSign = params.name === GeometryMapLayers.ROAD_SIGN

    if (isRoadSign) roadSignSource(map, layer)

    return
  }

  const isEgrn = type.find((el) => el.name === 'egrn')?.is
  const isOrto = type.find((el) => el.name === 'orto')?.is

  const isGeometry = !isEgrn && !isOrto

  if (isGeometry && layer.data) {
    if (!map.getSource(layer.params.name)) {
      map.addSource(layer.params.name, {
        type: 'geojson',
        data: layer.data,
        generateId: true
      })
    }

    return
  }

  if (isEgrn && layer.data) {
    if (!map.getSource(EGRN_SOURCE_NAME)) {
      map.addSource(EGRN_SOURCE_NAME, {
        type: 'vector',
        tiles: ['https://rn.i-digit.ru/api/lands/{z}/{x}/{y}.pbf']
      })
    }

    return
  }

  const { bbox_geojson, name, tailsId } = layer.params
  if (!bbox_geojson || !tailsId) return

  const bounds = JSON.parse(bbox_geojson)
  const ortoName = name.split('orto-').at(-1)

  if (map.getSource(name) || !ortoName) return

  map.addSource(name, {
    type: 'raster',
    tiles: [`${VITE_API_URL}/roads/roads/tails/${tailsId}/{z}/{x}/{y}/`],
    tileSize: 256,
    bounds: getOrtoBounds(bounds.coordinates[0])
  })
}
