import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { fetchWrapper } from '@/api/fetchApi'
import { useSegmentsStore } from './road-segments'
import RoadIRITransformData from '@/composition/useIRI'
import { useRoadStore } from './road'
import { standartIRI } from '@/assets/data/road-iri'
import { uniqFieldsArray } from '@/utils/uniq-fields-array'
import { LIMIT_IRI_VALUE } from '@/utils/consts/consts'

import type { RoadIRI } from '@/types/Road'
import type { RoadDiagnosticState } from '@/types/RoadDiagnostic'
import type { ChartData } from '@/types/ui/Chart'

const useIRI = new RoadIRITransformData()

export const useRoadDiagnosticStore = defineStore('road-diagnostic', () => {
  const roadStore = useRoadStore()
  const segmentsStore = useSegmentsStore()

  const road = computed(() => roadStore.getRoad)
  const currentSegment = computed(() => segmentsStore.currentSegment)

  const state: RoadDiagnosticState = reactive({
    iri: {
      data: [] as RoadIRI[],
      chart: {} as ChartData[],
      tables: [],
      correspondence: [],
      cached: {
        chart: [] as ChartData[],
        tables: []
      },
      labels: [],
      avg: undefined,
      avgChart: undefined
    },
    id: undefined
  })

  const getDiagnostic = computed(() => state.iri)
  const iri = computed(() => state.iri.data)
  const getDiagnosticChartData = computed(() => state.iri.chart)
  const getDiagnosticChartLabels = computed(() => state.iri.labels)
  const getDiagnosticTableData = computed(() => state.iri.tables)
  const getCorrespondenceData = computed(() => state.iri.correspondence)
  const getAvgIri = computed(() => state.iri.avg)
  const getAvgIriChart = computed(() => state.iri.avgChart)
  const getLimitIri = computed(
    () => standartIRI[useIRI.getRoadCategory(road.value!.technical_category)!]
  )

  const getState = computed(() => state)

  async function fetchIRI(roadId: number) {
    if (state.id === roadId) return
    state.id = roadId

    try {
      const response = (await fetchWrapper.get(`/roads/${roadId}/measure_iri/`, {})) as RoadIRI[]
      await segmentsStore.fetchSegments()

      state.iri.data = response
      state.iri.avg = calcAvgIri()
      state.iri.avgChart = calcAvgIriChart()
      toChartData()
      updateTable(iri.value)

      if (road.value?.technical_category)
        state.iri.correspondence = useIRI.getCorrespondence(response, road.value.technical_category)
    } catch (e) {
      throw new Error(e)
    }
  }

  function toChartData() {
    const iriBySegment = iri.value.filter((el) => el.segment_id === currentSegment.value)
    const lines = uniqFieldsArray<RoadIRI, number>(iriBySegment, 'line_number').sort(
      (a, b) => a - b
    )

    state.iri.chart = lines.map((line, index) => {
      const currentLineIri = iriBySegment.filter((el) => el.line_number === line)

      return {
        name: `Полоса ${index + 1}`,
        data: currentLineIri.map((iri) => [iri.first, iri.value] as number[])
      }
    })

    defineMaxXValue()
  }

  function defineMaxXValue() {
    const data = getDiagnosticChartData.value
      .map((el) => el.data)
      .flat()
      .map((el) => el[0])

    const maxX = Math.max(...data)

    state.iri.chart.push({
      name: LIMIT_IRI_VALUE,
      data: [
        [0, getLimitIri.value],
        [maxX, getLimitIri.value]
      ]
    })
  }

  function calcAvgIri() {
    if (!state.iri.data.length) return 0

    const sum = state.iri.data.reduce((initial, current) => initial + (current.value || 0), 0)
    if (sum === 0) return sum

    const avg = sum / state.iri.data.length

    return +avg.toFixed(2)
  }

  function setChartCache(iri: ChartData[]) {
    state.iri.cached.chart = iri
  }

  function calcAvgIriChart() {
    if (!state.iri.avg) return [0, 0, 0]

    const hasValue = state.iri.data.filter((el) => el.value)
    const value = hasValue.map((iri) => iri.value as number)

    if (!value.length) return [0, state.iri.avg, 0]

    const max = Math.max(...value)

    const limit = getLimitIri.value

    const maxValue = limit > max ? limit : max

    const percent = maxValue / 100

    const limitPercent = limit / percent
    const avgPercent = state.iri.avg / percent
    const maxPercent = max / percent

    return [limitPercent, avgPercent, maxPercent]
  }

  function updateTable(response: RoadIRI[]) {
    if (!road.value?.technical_category) return

    state.iri.cached.tables = useIRI.toTableData(response, road.value.technical_category)

    state.iri.tables = state.iri.cached.tables.filter((el) => el.segmentId === currentSegment.value)
  }

  return {
    iri,
    getDiagnostic,
    getDiagnosticChartData,
    getDiagnosticChartLabels,
    getDiagnosticTableData,
    getCorrespondenceData,
    getState,
    getAvgIri,
    getAvgIriChart,
    getLimitIri,

    setChartCache,

    fetchIRI
  }
})
