import * as echarts from 'echarts'

import { ChartProps, ChartTooltip } from '@/types/ui/Chart'
import { LIMIT_IRI_VALUE, colorBorderChart, lineColorsChart } from '@/utils/consts/consts'
import { ISeries } from '@/types/RoadRating'

class Chart {
  data: number[][] = []
  canvas = {} as HTMLCanvasElement
  ctx = {} as CanvasRenderingContext2D
  dpi = 2
  width = 1480
  height = 800
  padding = 0
  delta: string[] = []
  maxX = 0
  maxY = 0
  colors = ['red', 'green']
  scale = {} as HTMLCanvasElement
  sign = ''
  settings = {} as echarts.EChartsCoreOption
  series?: unknown[] = []
  options = {} as Partial<ChartProps>
  chart?: echarts.ECharts = undefined

  constructor(options: Partial<ChartProps>) {
    this.options = options
    this.series = this.getSeries(options)

    if (this.series?.length) this.settings = this.getSettings(this.series, options)
  }

  public getSeries(options: Partial<ChartProps>) {
    const series = options.data!

    return series.map((chart, index) => {
      const name = chart.name
      const isBorder = name === LIMIT_IRI_VALUE
      const isIri = options.id === 'chart__wrapper'

      const serie = {
        smooth: true,
        name: isBorder || !isIri ? name : `Полоса ${index + 1}`,
        type: options.type,
        showSymbol: !isBorder,
        symbolSize: options.symbolSize,
        data: chart.data,
        itemStyle: {
          color: options.colors
            ? options.colors[index]
            : isBorder
            ? colorBorderChart
            : lineColorsChart[index]
        },
        lineStyle: isBorder
          ? {
              width: 4
            }
          : {
              width: 2
            }
      }

      if (isBorder) {
        ;(serie as ISeries<number[][]>).areaStyle = {
          opacity: 0.1,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(128, 255, 165)'
            },
            {
              offset: 1,
              color: 'rgb(1, 191, 236)'
            }
          ])
        }
      }

      return serie
    })
  }

  private getSettings(series: unknown[], options: Partial<ChartProps>) {
    const tooltip: Partial<ChartTooltip> | undefined = options.tooltip

    const graphic = options.graphic ?? undefined

    const settings: echarts.EChartsCoreOption = {
      tooltip,
      grid: {
        left: options.left
      },
      xAxis: {
        splitLine: {
          show: false
        },
        name: options?.metric?.x
      },
      yAxis: {
        splitLine: {
          show: false
        },
        name: options?.metric?.y
      },
      series,
      dataZoom: this.zoom(options),
      graphic,
      legend: {
        show: false
      }
    }

    if (options.highlightColor) {
      settings.emphasis = {
        itemStyle: { color: options.highlightColor }
      }
    }

    return settings
  }

  public draw() {
    const chart: HTMLElement | null = document.querySelector(`.${this.options.id}`)
    if (!chart) return

    const height = this.options.height ? this.options.height : 0

    if (!this.chart) {
      this.chart = echarts.init(chart, null, {
        renderer: 'canvas',
        height
      })
    }

    this.settings.stateAnimation = {
      duration: 100,
      easing: 'cubicOut'
    }

    if (this.chart) {
      this.chart.setOption(this.settings, {
        lazyUpdate: true
      })
    }

    return this.chart
  }

  zoom(options: Partial<ChartProps>) {
    return [
      {
        type: 'inside',
        ...(options.dataZoom ? { ...options.dataZoom } : {})
      },
      {
        type: 'slider'
      }
    ]
  }
}

export default (options: Partial<ChartProps>) => {
  return new Chart(options)
}
