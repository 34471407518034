import { Map } from 'maplibre-gl'
import { MapRenderParams } from '@/types/map/Map'
import { EgrnLayers } from '@/utils/lists/lists'
import { EGRN_SOURCE_NAME } from '@/utils/consts/consts'

function addLands(map: Map, layer: MapRenderParams) {
  const { params } = layer

  if (!map.getLayer(`z-index-${EgrnLayers.LANDS}`)) {
    map.addLayer({
      id: `z-index-${EgrnLayers.LANDS}`,
      type: 'symbol',
      source: 'empty'
    })
  }

  if (!map.getLayer(EgrnLayers.LANDS)) {
    map.addLayer(
      {
        id: EgrnLayers.LANDS,
        type: 'fill',
        source: EGRN_SOURCE_NAME,
        'source-layer': 'lands',
        minzoom: 0,
        maxzoom: 20,
        layout: {
          visibility: params.showed ? 'visible' : 'none'
        },
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            'rgba(243, 243, 0, 0.5)',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(243, 82, 75, 0.5)',
            'rgba(243, 82, 75, 0.3)'
          ],
          'fill-outline-color': '#F3524B'
        }
      },
      `z-index-${EgrnLayers.LANDS}`
    )
  }
}

function addBuilds(map: Map, layer: MapRenderParams) {
  const { params } = layer

  if (!map.getLayer(`z-index-${EgrnLayers.BUILDS}`)) {
    map.addLayer({
      id: `z-index-${EgrnLayers.BUILDS}`,
      type: 'symbol',
      source: 'empty'
    })
  }

  if (!map.getLayer(EgrnLayers.BUILDS)) {
    map.addLayer(
      {
        id: EgrnLayers.BUILDS,
        type: 'fill',
        source: EGRN_SOURCE_NAME,
        'source-layer': 'builds',
        minzoom: 0,
        maxzoom: 20,
        layout: {
          visibility: params.showed ? 'visible' : 'none'
        },
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            'rgba(243, 243, 0, 0.5)',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(243, 82, 75, 0.5)',
            'rgba(243, 82, 75, 0.3)'
          ],
          'fill-outline-color': '#F3524B'
        }
      },
      `z-index-${EgrnLayers.BUILDS}`
    )
  }
}

function addConstructions(map: Map, layer: MapRenderParams) {
  const { params } = layer

  if (!map.getLayer(`z-index-${EgrnLayers.CONSTRUCTIONS_LINES}`)) {
    map.addLayer({
      id: `z-index-${EgrnLayers.CONSTRUCTIONS_LINES}`,
      type: 'symbol',
      source: 'empty'
    })
  }

  if (!map.getLayer(`z-index-${EgrnLayers.CONSTRUCTIONS_CURVES}`)) {
    map.addLayer({
      id: `z-index-${EgrnLayers.CONSTRUCTIONS_CURVES}`,
      type: 'symbol',
      source: 'empty'
    })
  }

  if (!map.getLayer(EgrnLayers.CONSTRUCTIONS)) {
    map.addLayer({
      id: EgrnLayers.CONSTRUCTIONS,
      type: 'fill',
      source: EGRN_SOURCE_NAME,
      'source-layer': 'constructions',
      minzoom: 0,
      maxzoom: 20,
      layout: {
        visibility: params.showed ? 'visible' : 'none'
      },
      paint: {
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'active'], false],
          'rgba(243, 243, 0, 0.5)',
          ['boolean', ['feature-state', 'hover'], false],
          'rgba(102, 0, 204, 0.5)',
          'rgba(102, 0, 204, 0.3)'
        ],
        'fill-outline-color': 'rgba(102, 0, 204, 1)'
      }
    })
  }

  if (!map.getLayer(EgrnLayers.CONSTRUCTIONS_LINES)) {
    map.addLayer(
      {
        id: EgrnLayers.CONSTRUCTIONS_LINES,
        type: 'line',
        source: EGRN_SOURCE_NAME,
        'source-layer': 'constructions_lines',
        minzoom: 0,
        maxzoom: 20,
        layout: {
          visibility: params.showed ? 'visible' : 'none'
        },
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            'rgba(243, 243, 0, 1)',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(102, 0, 204, 1)',
            'rgba(102, 0, 204, 0.5)'
          ],
          'line-width': 2
        }
      },
      `z-index-${EgrnLayers.CONSTRUCTIONS_LINES}`
    )
  }

  if (!map.getLayer(EgrnLayers.CONSTRUCTIONS_CURVES)) {
    map.addLayer(
      {
        id: EgrnLayers.CONSTRUCTIONS_CURVES,
        type: 'fill',
        source: EGRN_SOURCE_NAME,
        'source-layer': 'constructions_curves',
        minzoom: 0,
        maxzoom: 20,
        layout: {
          visibility: params.showed ? 'visible' : 'none'
        },
        paint: {
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            'rgba(243, 243, 0, 1)',
            ['boolean', ['feature-state', 'hover'], false],
            'rgba(102, 0, 204, 1)',
            'rgba(102, 0, 204, 0.5)'
          ]
        }
      },
      `z-index-${EgrnLayers.CONSTRUCTIONS_CURVES}`
    )
  }
}

function addEgrnLayers(map: Map, layer: MapRenderParams) {
  const { name } = layer.params

  if (!map.getSource('empty')) {
    map.addSource('empty', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })
  }

  if (name === EgrnLayers.LANDS) {
    addLands(map, layer)
  } else if (name === EgrnLayers.BUILDS) {
    addBuilds(map, layer)
  } else {
    addConstructions(map, layer)
  }
}

export default function () {
  return {
    addEgrnLayers: (map: Map, params: MapRenderParams) => addEgrnLayers(map, params)
  }
}
