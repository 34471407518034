import { reactive, ref, Ref, WritableComputedRef } from 'vue'
import { useElementBounding } from '@vueuse/core'
import { Point } from '@/types/ui/Diagrams'

export default function useDraggable(
  element: Ref<HTMLElement | undefined>,
  settings: WritableComputedRef<Point>,
  page?: Ref<HTMLElement | undefined>
) {
  const isDrag = ref(false)
  const mousePosInElement = reactive({
    x: 0,
    y: 0
  })

  function start(e: MouseEvent) {
    const isDraggable = (e.target as HTMLElement).closest('[draggable]')

    if (!element.value || !isDraggable) return

    const { left, top } = useElementBounding(element.value)

    isDrag.value = true

    mousePosInElement.x = e.clientX - left.value
    mousePosInElement.y = e.clientY - top.value
  }

  function move(e: MouseEvent) {
    if (!isDrag.value || !page?.value || !element.value) return

    settings.value = {
      x: e.clientX - mousePosInElement.x,
      y: e.clientY - mousePosInElement.y
    }
  }

  function drop() {
    isDrag.value = false
  }

  document.addEventListener('mousedown', start)
  document.addEventListener('mousemove', move)
  document.addEventListener('mouseup', drop)

  function removeDrag() {
    document.removeEventListener('mousedown', start)
    document.removeEventListener('mousemove', move)
    document.removeEventListener('mouseup', drop)
  }

  return {
    removeDrag
  }
}
