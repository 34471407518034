import { QueueTokenResponse, SkdfLoginForm } from '@/types/User'
import { fetchWrapper } from './fetchApi'

export const exportRoadsApi = {
  async fetchToken(username: string) {
    try {
      const response = <{ loginDate: number }>(
        await fetchWrapper.get(
          `/get_token_skdf/?username=${username}`,
          undefined,
          'http://10.10.10.79:40105'
        )
      )
      response.loginDate = +new Date()
      return response
    } catch (e) {
      throw new Error(e)
    }
  },
  async skdfAuthQueue(loginForm: SkdfLoginForm) {
    try {
      const response = (await fetchWrapper.post(
        '/auth/',
        {
          username: loginForm.username,
          password: loginForm.password
        },
        'http://10.10.10.79:40105'
      )) as QueueTokenResponse

      return response
    } catch (e) {
      throw new Error(e.message)
    }
  },
async sendGeometryToSkdf(params: {token: string, roadId: number, idSkdf: number}) {
    try {
      const response = await fetchWrapper.post(
        `/external/skdf/write_geom?skdf_token=${params.token}&road_id=${params.roadId}&id_skdf=${params.idSkdf}`
      )
      return response
    } catch (e) {
      throw new Error(e.message)
    }
  },
  async updateCard() {
    try {
      const response = await fetchWrapper.post(
        'skdf/roads/save-card',
        {
          isSkdf: true
        },
        import.meta.env.VITE_SKDF_API
      )

      return response
    } catch (e) {
      throw new Error(e)
    }
  }
}
