import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'
import { Map } from 'maplibre-gl'
import { MapFeatureRendered } from '@/types/map/Map'
import { DefectMapState } from '@/types/Defects'

export const useRoadDefectsMapStore = defineStore('road-defects-map', () => {
  const mapInstance = {
    map: undefined as Map | undefined
  }

  const state: DefectMapState = reactive({
    selectedFeature: undefined,
    hovered: undefined
  })

  const selectedFeature = computed(() => state.selectedFeature)
  const hoveredFeature = computed(() => state.hovered)
  const map = computed(() => mapInstance)

  /**
   * Добавление инстанса карты
   */
  function updateInstance(instance: Map) {
    mapInstance.map = instance
  }

  /**
   * Обновить выбранную фичу
   */
  function updateSelectedFeature(feature?: MapFeatureRendered) {
    state.selectedFeature = feature
  }

  /**
   * Обновить ховеред фичу
   */
  function updateHoveredFeature(feature?: MapFeatureRendered) {
    state.hovered = feature
  }

  return {
    map,

    selectedFeature,
    hoveredFeature,

    updateInstance,
    updateSelectedFeature,
    updateHoveredFeature
  }
})
