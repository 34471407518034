import { ref, Ref } from 'vue'

import { MapFeature, toggleEffectOnFeature } from '@/utils/map'

import type { Map, MapMouseEvent } from 'maplibre-gl'
import type { MapFeatureRendered } from '@/types/map/Map'
import type { HoverCallbackParams } from '@/types/map/MapHover'

function hoverEffect(params: HoverCallbackParams, isHover: boolean) {
  const { hoveredFeature, map } = params

  if (!hoveredFeature.value || !map) return

  map.getCanvasContainer().style.cursor = isHover ? 'pointer' : 'default'

  toggleEffectOnFeature(map, hoveredFeature.value, {
    hover: isHover
  })
}

/**
 * Ховер эффект на фиче
 */
function hover(params: HoverCallbackParams) {
  if (!params.event || !params.map) return

  const { hoveredFeature, map, layers, event } = params

  if (hoveredFeature.value) hoverEffect(params, false)

  const [feature] = map.queryRenderedFeatures(event.point)
  if (!feature) return

  const isHoveredFeature = layers.includes(feature.layer.id)

  if (isHoveredFeature) {
    hoveredFeature.value = new (MapFeature as unknown as MapFeatureRendered)(feature)

    hoverEffect(params, true)
  }
}

export default function (layers: string[]) {
  const hoveredFeature: Ref<MapFeatureRendered | undefined> = ref(undefined)

  const cbParams: HoverCallbackParams = {
    hoveredFeature,
    layers
  }

  const hoverOnFeature = (e: MapMouseEvent, map: Map) => {
    cbParams.map = map
    cbParams.event = e

    hover(cbParams)
  }

  return {
    hoveredFeature,

    hoverOnFeature
  }
}
