import { TooltipParams } from '@/types/RoadDiagnostic'

export function defectCandleStickPopup(params: TooltipParams, types: string[]) {
  const { name, marker, value } = params

  const startNameString = name.split(' ').at(1) ?? ''

  const fullName = types.find((type) => type.startsWith(startNameString)) ?? name

  return `
    <div>
      <div style='display: flex; justify-content: space-between; align-items: center; gap: 0 10px;'>
        <span style='display: flex; justify-content: space-between; align-items: center;'>
          ${marker}
          <span>${fullName}</span>
        </span>
        <span>
          м<sup>2</sup>
        </span>
      </div>
      <ul style='display: flex; gap: 3px 0; flex-direction: column; margin-top: 10px'>
        <li style='display: flex; justify-content: space-between; align-items: center; gap: 0 10px;'>
          <span>Наименьшая площадь:</span>
          <span>${value.at(2)?.toFixed(2) ?? '—'}</span>
        </li>
        <li style='display: flex; justify-content: space-between; align-items: center; gap: 0 10px;'>
          <span>Наибольшая площадь:</span>
          <span>${value.at(3)?.toFixed(2) ?? '—'}</span>
        </li>
        <li style='display: flex; justify-content: space-between; align-items: center; gap: 0 10px;'>
          <span>Медиана по наименьшей:</span>
          <span>${value.at(1)?.toFixed(2) ?? '—'}</span>
        </li>
        <li style='display: flex; justify-content: space-between; align-items: center; gap: 0 10px;'>
          <span>Медиана по наибольшей:</span>
          <span>${value.at(0)?.toFixed(2) ?? '—'}</span>
        </li>
      </ul>
    </div>
  `
}
