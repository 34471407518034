import type { ChartStackParams } from '@/types/ui/Chart'
import type { Defect } from '@/types/RoadDiagnostic'
import { ISeries } from '@/types/RoadRating'

export function defectsToChartStack(
  defects: Defect[],
  params: ChartStackParams
): ISeries<number[]>[] {
  if (!defects.length) return []
  const { types, lines, label } = params

  return types.map((type) => ({
    name: type.label,
    type: 'bar',
    stack: 'total',
    label: {
      ...label,
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: lines.map(
      (line) => defects.filter((el) => el.label === type.label && el.line_number === line).length
    )
  }))
}
