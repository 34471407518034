import { uniqFieldsArray } from '@/utils/uniq-fields-array'
import { ROUNDING_IRI_VALUE } from '@/utils/consts/consts'

import { RoadIRITableData } from '@/types/Road'
import { standartIRI } from '@/assets/data/road-iri'
import { RoadIRI } from '@/types/Road.d'

class RoadIRITransformData {
  public toTableData(response: RoadIRI[], category: string) {
    const roadCategory = this.getRoadCategory(category)
    if (!roadCategory) throw new Error('Отсутствует категория дороги')

    const legends = uniqFieldsArray<RoadIRI, number>(response, 'line_number').sort((a, b) => a - b)

    const positions = response
      .map((iri) => ({
        position: +(iri.last ?? 0).toFixed(3),
        standart: standartIRI[roadCategory] ? +standartIRI[roadCategory].toFixed(3) : 0,
        data: [] as RoadIRITableData[],
        segmentId: iri.segment_id
      }))
      .filter((iri) => !isNaN(iri.position))
      .sort((a, b) => a.position - b.position)

    positions.forEach((row) => {
      const rowLines = [
        ...response.filter(
          (iri) => +(iri.last ?? 0).toFixed(3) === row.position && row.segmentId === iri.segment_id
        )
      ]
      const otherLines = legends.filter(
        (el) => !rowLines.map((item) => item.line_number).includes(el)
      )
      const allLines = [...rowLines, ...otherLines.map((el) => ({ line_number: el, value: null }))]

      row.data = allLines
        .map((line) => ({
          line: line.line_number,
          value: line.value ? +line.value?.toFixed(ROUNDING_IRI_VALUE) : line.value
        }))
        .sort((a, b) => ((a.line as number) ?? 0) - ((b.line as number) ?? 0))
    })

    return positions
  }

  calcDistanceCb(initial: number, current: RoadIRI, allIri: RoadIRI[]) {
    const index = allIri.findIndex(
      (el) => el.last === current.last && el.line_number === current.line_number
    )

    const isExist = index >= 0

    if (!isExist) return initial

    const existPrevPoint =
      index - 1 >= 0 && allIri[index - 1].line_number === allIri[index].line_number

    const prevPoint = existPrevPoint ? allIri[index - 1] : { last: 0 }

    const diff = (current.last ?? 0) - (prevPoint.last ?? 0)

    return initial + diff
  }

  private distance(currentLineIri: RoadIRI[], allIri: RoadIRI[]) {
    return currentLineIri.reduce(
      (initial, current) => this.calcDistanceCb(initial, current, allIri),
      0
    )
  }

  private separateIriByCorrespondence(
    iri: RoadIRI[],
    separated: RoadIRI[][],
    roadCategory: string
  ) {
    const lines = uniqFieldsArray<RoadIRI, number>(iri, 'line_number')

    const sortByLines = lines.map((el) => {
      return iri.filter((item) => item.line_number === el)
    })

    sortByLines.forEach((line) => {
      line.forEach((iri) => {
        const index = iri.value! <= standartIRI[roadCategory] ? 0 : 1

        separated[index].push(iri)
      })
    })
  }

  getRoadCategory(category: string) {
    const categories = Object.keys(standartIRI)

    return categories.find((el) => category.includes(el))
  }

  private calculateDistance(currentLineIri: RoadIRI[], allIri: RoadIRI[]) {
    return currentLineIri.length ? this.distance(currentLineIri, allIri) : 0
  }

  public getCorrespondence(data: RoadIRI[], category: string) {
    const roadCategory = this.getRoadCategory(category)

    const iri = data.filter((iri) => iri.value)

    const separated: RoadIRI[][] = [[], []]

    if (iri.length && roadCategory) {
      this.separateIriByCorrespondence(iri, separated, roadCategory)
    }

    const totalDistance = separated.map((el) => this.calculateDistance(el, data))
    const percent = totalDistance.reduce((initial, current) => initial + current, 0) / 100

    return separated.map((el, index) => {
      const name = index === 0 ? 'Соответствует' : 'Не соответствует'
      const distance = this.calculateDistance(el, data)
      const method = index === 0 ? 'floor' : 'ceil'

      return {
        name,
        distance: totalDistance ? Math.trunc(distance * 1000) : 'Нет данных',
        percent: totalDistance ? Math[method](distance / percent) : 'Нет данных'
      }
    })
  }
}

export default RoadIRITransformData
