<script setup lang="ts">
import { computed } from 'vue'

import usePagination from '@/composition/usePagination'

import IconBase from '@/components/icons/IconBase.vue'
import IconPaginationPrev from '@/components/icons/IconPaginationPrev.vue'
import IconPaginationNext from '@/components/icons/IconPaginationNext.vue'

import { PaginationSettings } from '@/types/ui/Pagination'

const props = withDefaults(defineProps<PaginationSettings>(), {
  max: 14,
  current: 1,
  left: 1,
  navigation: false
})

const emits = defineEmits(['change-page'])

const options = computed(() => ({
  current: props.current,
  max: props.max,
  left: props.left,
  navigation: props.navigation
}))

const pagination = computed(() => usePagination(options.value))
const showNav = computed(() => pagination.value.showNav)
const items = computed(() => pagination.value.items)
const allowPrev = computed(() => pagination.value.allowPrev)
const allowNext = computed(() => pagination.value.allowNext)

function changePage(value: number) {
  const notValidate =
    value < 1 || value > props.max || props.current === value || Number.isNaN(+value)

  if (notValidate) return

  emits('change-page', value)
  pagination.value.change(value)
}
</script>

<template>
  <div class="pagination" :class="{ disabled: '' }">
    <div
      v-if="showNav"
      class="pagination__item pagination__prev"
      :class="[!allowPrev ? 'disabled' : '']"
      @click="() => changePage(props.current - 1)"
    >
      <IconBase width="20" height="20" view-box="0 0 20 20">
        <IconPaginationPrev></IconPaginationPrev>
      </IconBase>
    </div>
    <div v-if="items.length > 1" class="pagination__items">
      <div
        v-for="(item, index) in items"
        :key="index + 'pagination-item'"
        class="pagination__item"
        :class="{ active: item === props.current, delimiter: typeof item === 'string' }"
        @click="() => changePage(item as number)"
      >
        {{ item }}
      </div>
    </div>
    <div
      v-if="showNav"
      class="pagination__item pagination__next"
      :class="[!allowNext ? 'disabled' : '']"
      @click="() => changePage(props.current + 1)"
    >
      <IconBase width="20" height="20" view-box="0 0 20 20">
        <IconPaginationNext></IconPaginationNext>
      </IconBase>
    </div>
  </div>
</template>

<style lang="scss">
.pagination {
  &__prev {
    g {
      transform: translate(-12px, -11px);
    }
  }

  &__next {
    g {
      transform: translate(-9px, -11px);
    }
  }

  &__prev,
  &__next {
    &.disabled {
      g {
        fill: $c-gray-40;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.pagination {
  display: inline-grid;
  grid-auto-flow: column;
  color: $c-white;

  &__items {
    display: inline-grid;
    grid-auto-flow: column;
  }

  &__item {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    transition: all 0.1s linear;
    border: 1px solid transparent;
    @include color('fontColor');

    font-family: $f-family-base;
    font-size: 1.125rem;
    font-weight: 700;
    cursor: pointer;

    &.active {
      border: 1px solid $c-orange;
      cursor: default;
    }

    &.delimiter {
      cursor: default;
    }

    &:hover {
      &:not(.delimiter) {
        border: 1px solid $c-orange;
      }

      &.pagination__prev,
      &.pagination__next {
        border: 1px solid transparent;
      }
    }
  }

  &__next,
  &__prev {
    path {
      transition: all 0.1s linear;
    }

    &:not(.disabled) {
      fill: $c-orange;

      &:hover {
        fill: $c-orange-light;
      }
    }

    &.disabled {
      cursor: default;
    }
  }
}
</style>
