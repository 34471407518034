<script setup lang="ts">
import { ComputedRef, computed, ref } from 'vue'
import { ResponseTableData, TableHeaderTabs } from '@/types/ui/TableGeneration'

interface Props {
  data?: {
    header: TableHeaderTabs[]
    info: ResponseTableData[]
  }
  wayName: string
}

interface ItemType {
  name: string
  description: string
  type?: string
}

const props = defineProps<Props>()
const headerTabs = computed(() => props.data?.header || [])
const bodyData = computed(() => props.data?.info || [])
const tableHeaderName: ComputedRef<Partial<ItemType>[]> = computed(() => [
  { name: 'Начало', description: 'Начало', type: 'string' },
  {
    name: 'Конец',
    description: 'Конец',
    type: 'string'
  },
  ...headerTabs.value
])

const cell = ref<HTMLElement[]>([])

/**  Функция для фикса больших чисел таблицы */
function workIsNumber(num: string | number | null) {
  if (!isNaN(parseFloat(num as string))) {
    return parseFloat(num as string).toFixed(2)
  }
  return '—'
}

function isSemaphore(item: Partial<ItemType>, data: ResponseTableData) {
  if (!item.name) return

  const isSemaphore = props.wayName === 'Пешеходные переходы' && item.name === 'has_semaphore'

  if (isSemaphore) {
    return data.data[item.name] === '0' ? 'Нет' : 'Да'
  }
}

function getValue(item: Partial<ItemType>, data: ResponseTableData) {
  if (!item.name) return ''

  return item.name === 'Начало'
    ? data.start
    : item.name === 'Конец'
    ? data.end
    : item.type === 'float'
    ? data.data[item.name]
      ? workIsNumber(data.data[item.name])
      : '—'
    : isSemaphore(item, data)
    ? isSemaphore(item, data)
    : data.data[item.name]
    ? data.data[item.name]
    : '—'
}
</script>

<template>
  <div class="tableWay-container custom-scroll">
    <div class="tableWay">
      <div class="tableWay__string tableWay__string--header">
        <div
          v-for="(item, index) in tableHeaderName"
          :key="index + 'table-header'"
          ref="cell"
          class="tableWay__cell"
        >
          {{ item.description }}
        </div>
      </div>
      <div class="tableWay__body">
        <div
          v-for="(body, counter) in bodyData"
          :key="counter + 'table-body'"
          class="tableWay__string"
        >
          <div
            v-for="(item, iter) in tableHeaderName"
            :key="iter + 'table-header-name'"
            class="tableWay__cell"
          >
            <span class="tableWay-cell__value" :title="`${getValue(item, body)}`">
              {{ getValue(item, body) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tableWay-container {
  width: auto;
  min-width: 100%;
  overflow-x: auto;
}

.tableWay {
  width: max-content;
  min-width: 100%;

  &__cell {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 160px;
    padding: 8px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    border-inline-end: 1px solid #686868;

    @include color('fontColor');

    &:first-child {
      border-inline: 1px solid #686868;
    }
  }

  &__string {
    display: flex;
    border-block-end: 1px solid #686868;

    &--header {
      background: rgba(255 255 255 / 7%);
      font-weight: bold;
      border-block-start: 1px solid #686868;

      .tableWay__cell {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
</style>
