import type { LayerParams } from '@/types/map/Map'
import type { Map } from 'maplibre-gl'

export default function (map: Map, params: LayerParams) {
  if (
    map.getLayer(`${params.name}-clusters`) ||
    map.getLayer(`${params.name}-unclustered-point`) ||
    !params.showed
  )
    return

  map.addLayer({
    id: `${params.name}-clusters`,
    type: 'symbol',
    source: params.name,
    filter: ['has', 'point_count'],
    paint: {
      'icon-opacity': params.opacity
    }
  })

  map.addLayer({
    id: `${params.name}-unclustered-point`,
    type: 'symbol',
    source: params.name,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'icon-opacity': params.opacity
    }
  })
}
