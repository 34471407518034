import { computed, reactive, shallowRef } from 'vue'
import { defineStore } from 'pinia'

import Nav from '@/composition/nav/NavTree'
import { RoadNavigation } from '@/utils/lists/road-nav'
import {
  useDefectsStore,
  usePreloaderStore,
  useRoadCategoriesStore,
  useRoadDiagnosticStore,
  useRoadRegulatory,
  useRoadStore,
  useRoadWayStore,
  useSegmentsStore
} from '@/stores'

import { useExportRoadStore } from '@/stores/export'
import { useDefectChartStore } from '@/stores/road/defects/road-defects-chart'
import { useDefectFilterStore } from '@/stores/road/road-defects-filter'

import AppRoadDiagnostic from '@/components/layouts/road/diagnostic/iri/AppRoadDiagnostic.vue'
import AppRoadWay from '@/components/layouts/road/AppRoadWay.vue'
import AppRegulatoryDocs from '@/components/layouts/road/AppRegulatoryDocs.vue'
import OuterIndorRoad from '@/components/layouts/outer/OuterIndorRoad.vue'
import OuterSkdf from '@/components/layouts/outer/OuterSkdf.vue'
import AppMainRoad from '@/components/layouts/road/AppMainRoad.vue'
import AppRoadDefects from '@/components/layouts/road/AppRoadDefects.vue'
import AppRoadRating from '@/components/layouts/road/AppRoadRating.vue'
import UsersPage from '@/components/layouts/profile/Users/UsersPage.vue'
import ImportPage from '@/components/layouts/profile/Import/ImportPage.vue'
import ExportPage from '@/components/layouts/profile/Export/ExportPage.vue'
import ReferencesPage from '@/components/layouts/profile/References/ReferencesPage.vue'
import EditPage from '@/components/layouts/profile/Edit/EditPage.vue'
// import ChangePasswordPage from '@/components/layouts/profile/Password/ChangePasswordPage.vue'

import { StateNav } from '@/types/ui/Nav'
import { ProfileNavigation } from '@/utils/lists/lists'
import { RoadObjectsTypeId } from '@/utils/lists/lists'

const nav = new Nav()

export const useNavStore = defineStore('nav', () => {
  const roadStore = useRoadStore()
  const roadDefectsStore = useDefectsStore()
  const preloaderStore = usePreloaderStore()
  const roadWay = useRoadWayStore()
  const regulatoryStore = useRoadRegulatory()
  const roadCategoriesStore = useRoadCategoriesStore()
  const roadDiagnosticStore = useRoadDiagnosticStore()
  const segmentsStore = useSegmentsStore()
  const defectsStore = useDefectsStore()
  const defectFilterStore = useDefectFilterStore()
  const roadDefectChartStore = useDefectChartStore()
  const exportRoadStore = useExportRoadStore()

  const initialFilter = computed(() => defectFilterStore.initialFilter)
  const road = computed(() => roadStore.getRoad)
  const segmentId = computed(() => segmentsStore.currentSegment)
  const defects = computed(() => defectsStore.defects)

  const state: StateNav = reactive({
    nav: {
      road: {
        items: [
          {
            name: 'Общая информация',
            active: true,
            component: shallowRef(AppMainRoad),
            show: computed(() => true),
            isReady: true,
            id: RoadNavigation.INFO,
            async fetch(roadId: number) {
              preloaderStore.togglePreloaderWithoutDelay()

              // Получаем инфу о дороге
              await Promise.all([
                roadStore.fetchRoadAxis(roadId),
                roadWay.fetchRoadWay(roadId),
                roadStore.fetchRoadIDSPandOwner(roadId)
              ])

              // Подсчет износа
              if (road.value?.has_defects) {
                await defectsStore.fetchDefects(segmentId.value, initialFilter.value)
                defectFilterStore.setInitialPayloadFilter(defects.value)
                defectFilterStore.calcFilterValues(defects.value)
                roadDefectsStore.calcRoadQuality()
              }

              preloaderStore.togglePreloaderWithoutDelay()
            }
          },
          {
            name: 'Документы',
            id: RoadNavigation.REGULATORY,
            component: shallowRef(AppRegulatoryDocs),
            active: false,
            isReady: true,
            show: computed(() => true),
            async fetch(roadId: number) {
              preloaderStore.togglePreloader()

              await regulatoryStore.getRegulatoryDocs(roadId)

              preloaderStore.togglePreloader()
            }
          },
          {
            name: 'Дорожные объекты',
            id: RoadNavigation.ROADWAY,
            component: shallowRef(AppRoadWay),
            active: false,
            isReady: true,
            show: computed(() => true),
            async fetch(roadId: number) {
              preloaderStore.togglePreloader()

              await roadCategoriesStore.fetchSegments(roadId)

              preloaderStore.togglePreloader()
            }
          },
          {
            name: 'Диагностика',
            active: true,
            id: RoadNavigation.DIAGNOSTIC,
            component: shallowRef(AppRoadDiagnostic),
            isReady: true,
            show: computed(() => Boolean(road.value?.has_iri || road.value?.has_defects))
          },
          {
            name: 'Геометрия дороги',
            active: false,
            id: RoadNavigation.GEOMETRY,
            isReady: true,
            link: 'Geometry',
            show: computed(() => true)
          },
          {
            name: 'Модель дороги',
            active: false,
            id: RoadNavigation.THREED,
            isReady: true,
            link: 'Model',
            show: computed(() => Boolean(road.value?.has_point_cloud))
          },
          {
            name: 'Общая оценка',
            id: 'rating',
            component: shallowRef(AppRoadRating),
            active: true,
            parentId: RoadNavigation.DIAGNOSTIC,
            isReady: true,
            show: computed(() => Boolean(road.value?.has_defects)),
            async fetch(roadId: number) {
              preloaderStore.togglePreloader()

              await roadDiagnosticStore.fetchIRI(roadId)
              preloaderStore.togglePreloader()
            }
          },
          {
            name: 'IRI',
            id: 'iri',
            component: shallowRef(AppRoadDiagnostic),
            active: false,
            parentId: RoadNavigation.DIAGNOSTIC,
            isReady: true,
            show: computed(() => Boolean(road.value?.has_iri))
          },
          {
            name: 'Дефекты',
            id: 'defects',
            component: shallowRef(AppRoadDefects),
            active: true,
            show: computed(() => Boolean(road.value?.has_defects)),
            parentId: RoadNavigation.DIAGNOSTIC,
            isReady: true,
            async fetch() {
              preloaderStore.togglePreloader()

              await roadStore.fetchRoadGeometry(RoadObjectsTypeId.ROADWAY)
              await segmentsStore.fetchSegments()

              await defectFilterStore.initializeFilter()
              roadDefectChartStore.updateChartData()

              preloaderStore.togglePreloader()
            }
          }
        ],
        active: undefined
      },
      outer: {
        items: [
          {
            id: 'indor',
            name: 'IndorRoad',
            component: shallowRef(OuterIndorRoad),
            active: true,
            isReady: false,
            show: computed(() => true)
          },
          {
            id: 'skdf',
            name: 'СКДФ',
            component: shallowRef(OuterSkdf),
            active: false,
            isReady: false,
            show: computed(() => true)
          }
        ],
        active: undefined
      },
      profileMenu: {
        items: [
          {
            id: ProfileNavigation.PROFILE,
            name: 'Пользователи',
            component: shallowRef(UsersPage),
            active: true,
            isReady: false,
            show: computed(() => true)
          },
          {
            id: ProfileNavigation.REFERENCES,
            name: 'Справочники',
            component: shallowRef(ReferencesPage),
            active: false,
            isReady: false,
            show: computed(() => true)
          },
          {
            id: ProfileNavigation.IMPORT,
            name: 'Импорт',
            component: shallowRef(ImportPage),
            active: false,
            isReady: false,
            show: computed(() => true)
          },
          {
            id: ProfileNavigation.EXPORT,
            name: 'Экспорт',
            component: shallowRef(ExportPage),
            active: false,
            isReady: false,
            show: computed(() => true),
            async fetch() {
              await exportRoadStore.fetchRoads()
            }
          }
        ],
        active: undefined
      },
      userProfileMenu: {
        items: [
          {
            id: 'edit',
            name: 'Изменить профиль',
            component: shallowRef(EditPage),
            active: true,
            isReady: false,
            show: computed(() => true)
          }
          // {
          //   id: 'changePassword',
          //   name: 'Сменить пароль',
          //   component: shallowRef(ChangePasswordPage),
          //   active: false,
          //   isReady: false,
          //   show: computed(() => true)
          // }
        ],
        active: undefined
      }
    }
  })

  function getNavItems(type: string) {
    return nav.getTree(state.nav[type].items)
  }

  function getActive(type: string) {
    const stateNav = state.nav[type]

    if (!stateNav) return

    const navigation = stateNav.items

    const activeItems = navigation.filter((el) => el.active)

    stateNav.active =
      activeItems.length > 1 ? activeItems.find((el) => el.parentId)! : activeItems.at(0)!

    return stateNav.active
  }

  function changeActive(id: string | undefined, type: string, isRecursive = false) {
    if (!id) return

    const navigation = state.nav[type].items

    if (!isRecursive) nav.clearNavItems(navigation)

    const current = nav.getItemNav(id, navigation)

    if (!current) return

    current.active = true

    const notNested = !current.parentId

    if (notNested && !isRecursive) {
      const sub = state.nav[type].items.find((el) => el.parentId === current.id)

      if (sub) sub.active = true
    }

    changeActive(current.parentId, type, true)
  }

  function setActive(id: string | undefined, type: string, isRecursive = false) {
    if (!id) return

    const navigation = state.nav[type].items
    if (!isRecursive) nav.clearNavItems(navigation)

    const current = nav.getItemNav(id, navigation)
    if (!current) return

    current.active = true
    setActive(current.parentId, type, true)
  }

  return {
    getNavItems,
    getActive,
    changeActive,
    setActive
  }
})
