<script lang="ts" setup="">
import { ComputedRef, computed, reactive, ref, watch } from 'vue'
import { ControlColumnData } from '@/types/ui/ControlTableGeneration'
import { useRoadTableGenerationStore } from '@/stores/road/road-tableGeneration.ts'
import AppCheckbox from '@/components/ui/AppCheckbox.vue'
import ChangeIcon from '@/assets/images/icons/changeElement.svg'
import FulfilledIcon from '@/assets/images/icons/check.svg'
import PreloaderTable from '@/assets/images/preloaderTable.svg'
import { Dictionary } from '@/types/AdminDictionary'

interface Props {
  columnData?: Dictionary[]
  typeId: number | null
}

const props = defineProps<Props>()
const columnName: ComputedRef<ControlColumnData[] | undefined> = computed(() => props.columnData)
const hasChangeNameColumn = ref<boolean>(false)
const changeNameValueField = reactive<{ valueField: string; columnId: number }>({
  valueField: '',
  columnId: 0
})

const tableGenerationStore = useRoadTableGenerationStore()
const isLoading = computed(() => tableGenerationStore.isLoadingTableData)

const changeNameColumn = (value: {
  state: boolean
  valueField: string | undefined
  columnId: number
}) => {
  if (hasChangeNameColumn.value) {
    if (changeNameValueField.valueField === '') return
    tableGenerationStore.changeNameColumn({
      typeId: props.typeId,
      columnId: value.columnId,
      body: { description: changeNameValueField.valueField }
    })
    changeNameValueField.columnId = 0
    columnName.value?.forEach((el: ControlColumnData) => {
      if (el.id === value.columnId) {
        el.description = changeNameValueField.valueField
      }
    })
  } else {
    changeNameValueField.valueField = value.valueField !== undefined ? value.valueField : ''
    changeNameValueField.columnId = value.columnId !== undefined ? value.columnId : 0
    hasChangeNameColumn.value = value.state
  }
}

const checkCheckbox = (action: { columnId: number; typeId: number | null; body: boolean }) => {
  tableGenerationStore.toggleColumn({
    columnId: action.columnId,
    typeId: action.typeId,
    body: action.body
  })

  if (hasChangeNameColumn.value) {
    hasChangeNameColumn.value = false
  }
}

watch(isLoading, () => {
  if (hasChangeNameColumn.value && !isLoading.value) {
    hasChangeNameColumn.value = false
  }
})
</script>

<template>
  <div class="column__wrapper">
    <div v-for="item in columnName" :key="item.id" class="column__item">
      <div
        v-show="item.id !== changeNameValueField.columnId || !hasChangeNameColumn"
        class="column__itemCheck"
      >
        <AppCheckbox
          :disabled="tableGenerationStore.isLoadingTableData"
          :checked="item.visible"
          :name="`${item.description}`"
          :long-text="true"
          @toggle-checkbox="
            checkCheckbox({ columnId: item.id, typeId: props.typeId, body: $event })
          "
        />
        <button
          class="column__item_changeBtn"
          :disabled="hasChangeNameColumn"
          @click="
            changeNameColumn({ state: true, valueField: item.description, columnId: item.id })
          "
        >
          <ChangeIcon class="changeIcon" />
        </button>
      </div>
      <div
        v-show="hasChangeNameColumn && item.id === changeNameValueField.columnId"
        class="column__itemInput"
      >
        <input
          v-model="changeNameValueField.valueField"
          type="text"
          class="column__itemInput_input"
        />
        <button
          class="column__item_changeBtn"
          @click="
            changeNameColumn({ state: false, valueField: item.description, columnId: item.id })
          "
        >
          <PreloaderTable v-show="tableGenerationStore.isLoadingTableData" class="pre__icon" />
          <FulfilledIcon
            v-show="!tableGenerationStore.isLoadingTableData"
            class="fulfilledIcon"
            fill="red"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.column__wrapper {
  padding: 10px 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  background: rgba(255, 255, 255, 0.07);
}

.column__item {
  position: relative;
  padding: 10px;
  border: 1px solid #ccc;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &_changeBtn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
    background: none;
    border: none;
  }

  &:hover {
    .column__item_changeBtn {
      display: flex;
      position: absolute;
      top: 50%;
      right: -2px;
      transform: translate(0%, -50%);
      opacity: 1;
      background: rgba(255, 255, 255, 0.07);
      backdrop-filter: blur(5px);
      border: none;
      border-left: 1px solid #ccc;
      cursor: pointer;
    }
  }
}

.column__itemInput {
  &_input {
    width: 100%;
    background: none;
    border: none;
    color: white;
    font: {
      size: 1rem;
      weight: 700;
      family: Bender;
    }
  }
}

.changeIcon {
  width: 40px;
  height: 39px;
}

.fulfilledIcon {
  @extend .changeIcon;

  .check {
    fill: green;
  }
}

@media (min-width: 768px) {
  .column__wrapper {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (min-width: 1024px) {
  .column__wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}
</style>
