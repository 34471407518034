export enum DiagramTypes {
  DIAGRAM = 'diagram',
  SCALE = 'scale'
}

export enum Directions {
  UP = 'up',
  DOWN = 'down'
}

export enum Positions {
  CENTER = 'center'
}

export enum MapLayers {
  SCHEME = 'composite-custom',
  SATELLITE = 'satellite-custom',
  HYBRID = 'hybrid-custom',
  YANDEX = 'yandex-custom'
}

export enum APP_THEMES {
  DARK = 'theme-dark',
  LIGHT = 'theme-light'
}

export enum RoadObjectsTypeId {
  ROAD_SIGN = 1,
  KILOMETER_POL = 2,
  ROADWAY = 3,
  LAND_LOTS = 4,
  ROAD_MARKS = 5,
  SIDE_WALKS = 6,
  ROAD_REALTY = 7,
  ROAD_LIGHT = 8,
  ROAD_EDGES = 9,
  BRIDGES = 10,
  CURVES = 11,
  CLIMATIC_ZONE = 12,
  BORDERS = 13,
  SEMAPHORES = 14,
  BUMPS = 15,
  WEIGHT_CONTROL = 16,
  CROSSWALKS = 17,
  ROAD_EXIT = 18,
  GUIDE_STAKE_SEGMENTS = 19,
  EXPLUATATION_CATEGORY = 20,
  ROAD_FENCE_SEGMENTS = 21,
  RAIL_WAY_CROSSING = 22,
  LIGHTING_POLES = 23,
  ROAD_FORTIFICATIONS = 24
}

export const layers = [
  'land-lots',
  'roadway',
  'road',
  'side-walks',
  'realty',
  'edges',
  'light',
  'light-fill',
  'light-line',
  'light-circle',
  'light-symbol'
]

export enum DefectTypeId {
  default = 1
}

export enum EgrnLayers {
  BUILDS = 'egrn-custom-builds',
  LANDS = 'egrn-custom-lands',
  CONSTRUCTIONS = 'egrn-custom-constructions',
  CONSTRUCTIONS_LINES = 'egrn-custom-constructions_lines',
  CONSTRUCTIONS_CURVES = 'egrn-custom-constructions_curves'
}

export enum GeometryMapLayers {
  ROAD_ELEMENTS = 'road-custom-elements',
  EGRN_CUSTOM = 'egrn-custom',
  ORTO = 'orto-',
  KERN = 'kern',

  ROAD_LAND_LOTS = 'road-custom-land-lots',
  ROAD_WAY = 'road-custom-way',
  ROAD_MARKS = 'road-custom-marks',
  ROAD_SIDE_WALKS = 'road-custom-side-walks',
  ROAD_REALTY = 'road-custom-realty',
  ROAD_LIGHT = 'road-custom-light',
  ROAD_EDGES = 'road-custom-edges',
  ROAD_BRIDGES = 'road-custom-bridges',
  ROAD_CURVES = 'road-custom-curves',
  ROAD_CLIMATIC = 'road-custom-climatic',
  ROAD_BORDERS = 'road-custom-borders',
  ROAD_SEMAPHORES = 'road-custom-semaphores',
  ROAD_WEIGHT_CONTROL = 'road-custom-weight-control',
  ROAD_EXIT = 'road-custom-exit',
  ROAD_AXIS = 'road-custom-axis',
  ROAD_SIGN = 'road-custom-sign',
  ROAD_BUMPS = 'road-custom-bumps',
  ROAD_CROSSWALKS = 'road-custom-crosswalks',
  ROAD_GUIDE_STAKE_SEGMENTS = 'road-custom-guide-stake-segments',
  ROAD_CATEGORY_SEGMENTS = 'road-custom-category-segments',
  ROAD_FENCE_SEGMENTS = 'road-custom-fence-segments',
  RAILWAY_CROSSING = 'road-custom-railway-crossing',
  EXPLUATATION_CATEGORY = 'road-custom-expluatation-category',
  LIGHTING_POLES = 'road-custom-lighting-poles',
  ROAD_FORTIFICATIONS = 'road-custom-fortifications'
}

export enum MapRoadsLayers {
  ROADS = 'roads'
}

export enum GeometryMapLayerSuffixes {
  CLUSTERS = '-clusters',
  LINES = '-line',
  CIRCLES = '-circle',
  FILLS = '-fill',
  UNCLUSTERED_POINTS = '-unclustered-point',
  Z_INDEX = '-z-index'
}

export enum RoadInfoNames {
  ID = 'Идентификационный номер',
  REGISTRATION = 'Учетный номер',
  CORE_NETWORK = 'Входит в опорную сеть',
  SKDF_NUMBER = 'Номер СКДФ',
  TERRITORY = 'Муниципальное образование',
  REGION = 'Регион',
  CITY = 'Город',
  LOCALITY = 'Населенный пункт',
  AREA = 'Район',
  OWNER = 'Собственник, владелец дороги (балансодержатель)',
  BOOK_VALUE = 'Балансовая стоимость, тыс. руб.',
  RESIDUAL_VALUE = 'Остаточная стоимость, тыс. руб.'
}

export enum RoadDetailNames {
  MEANING = 'Значение автомобильной дороги',
  COMISSIONING_DATE = 'Дата ввода в эксплуатацию',
  TECHNICAL_CATEGORY = 'Техническая категория',
  CLASS = 'Класс',
  TYPES = 'Виды покрытия',
  CATEGORY_SP = 'Категория согласно СП 42.13330',
  INTERNATIONAL_ROAD = 'Международный маршрут',
  TYPE_OF_PERMITTED_USE = 'Вид разрешенного использования',
  ROAD_CLIMATE_ZONE = 'Дорожно-климатическая зона',
  EGRAD_NUMBER = 'Номер ЕГРАД',
  START_ADRESS = 'Адрес начала дороги, км+м',
  END_ADRESS = 'Адрес конца дороги, км+м',
  SERVICE_LIFE_LIMIT = ' Предельный срок эксплуатации, месяц',
  WEAR = 'Износ, %'
}

export enum FilterMaxValueNames {
  max_square = 'max_square',
  max_length = 'max_length',
  max_width = 'max_width'
}

export enum FilterMinValueNames {
  min_square = 'min_square',
  min_width = 'min_width',
  min_length = 'min_length'
}

export enum RoadDefectLayers {
  CRACK = 'crack-defect',
  POTHOLE = 'pothole-defect'
}

export enum AppMapIds {
  GEOMETRY = 'geometry-map',
  MAIN_ROAD_PAGE = 'main-road-page',
  ROAD_WAY_PAGE = 'road-way-page',
  ROAD_DIAGNOSTIC_DEFECTS_PAGE = 'road-diagnostic-defects-page',
  MAP_PAGE = 'map-page'
}

export enum DEFECTS_IDS {
  CRACK = 1,
  POTHOLE = 2
}

export enum ECHARTS_ACTION {
  DOWNPLAY = 'downplay',
  HIGHLIGHT = 'highlight'
}

export enum ProfileNavigation {
  PROFILE = 'profile',
  REFERENCES = 'references',
  IMPORT = 'import',
  EXPORT = 'export'
}
