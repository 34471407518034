import type { MapRenderParams } from '@/types/map/Map'
import type { RoadGeoJSON } from '@/types/Road'

import { ROAD_DEFECTS_SOURCE_NAME } from './consts'

export const kernGeometry: RoadGeoJSON = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [86.62015422270784, 53.87280580678387]
      },
      properties: {
        name: 'Проба №16',
        thickness: '6 см',
        result: 'Соответствует требованиям НТД'
      }
    }
  ]
}

export const defectLayerParams: MapRenderParams = {
  data: {
    type: 'FeatureCollection',
    features: []
  },
  params: {
    showed: true,
    color: '#eb9c52',
    activeColor: '#d16b0a',
    hoveredColor: '#f59131',
    name: ROAD_DEFECTS_SOURCE_NAME
  }
}
