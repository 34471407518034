import { defectDataToGeoJSON } from '@/composition/defects/useMapDefects'
import { defectLayerParams } from '@/utils/consts/geometries'

import type { DataRenderingParams, MapRenderParams } from '@/types/map/Map'

/**
 * Преобразование данных в геометрию на карте дефектов
 * */
export function getDataForRendering(params: DataRenderingParams, roadId: number) {
  if (!params.map) return []

  return getRenderParams(params, roadId)
}

/**
 * Получение настроек отрисовки
 */
function getRenderParams(params: DataRenderingParams, roadId: number) {
  const renderParams: MapRenderParams[] = []
  if (params.renderData) renderParams.push(params.renderData)

  if (!defectLayerParams.data) throw new Error('Не передана геометрия дефектов')

  defectLayerParams.data.features = defectDataToGeoJSON(params.defects, roadId)

  renderParams.push(defectLayerParams)

  return renderParams
}
