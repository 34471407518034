<script setup lang="ts">
import { MapButton } from '@/types/map/Map'
import { computed } from 'vue'

const props = withDefaults(defineProps<MapButton>(), {
  text: 'Перейти на карту',
  animate: false
})

const to = computed(() => props.to || { name: 'Map' })
</script>

<template>
  <RouterLink class="map-btn" :to="to" :aria-label="props.text">
    <span class="map-btn__map-icon"></span>
    {{ props.text }}
    <span class="map-btn__map-arrow"></span>
    <span v-if="props.animate" class="map-btn__bg"></span>
  </RouterLink>
</template>

<style lang="scss" scoped>
.map-btn {
  display: grid;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 45px;
  overflow: hidden;
  transition: color 0.3s $main-animation;

  @include color('fontColor');

  font-family: $f-family-caption;
  font-size: 0.875em;
  text-decoration: none;

  &__map-icon {
    content: '';
    display: grid;
    position: absolute;
    top: 50%;
    left: 20px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    mask-image: url('@/assets/images/icons/map.svg');
    background-color: $c-orange;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }

  &__map-arrow {
    content: '';
    display: grid;
    position: absolute;
    right: 20px;
    width: 32px;
    height: 12px;
    mask-image: url('@/assets/images/icons/arrow.svg');
    background-color: $c-orange;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 100% 100%;
  }

  &__bg {
    position: absolute;
    z-index: -1;
    top: 0;
    left: -20px;
    width: 0;
    height: 100%;
    transform: skew(-35deg);
    transition: width 0.25s ease-in-out;
    background-color: $c-orange-light;
    pointer-events: none;
  }

  &:hover {
    box-shadow: 3px 3px 10px 0 rgba(0 0 0 / 35%);

    @include color('mapBtnHoverColor');

    .map-btn__bg {
      width: calc(100% + 40px);
    }
  }
}
</style>
