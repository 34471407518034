<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import * as echarts from 'echarts'

import type { ISeries } from '@/types/RoadRating'

const props = defineProps<{
  data: ISeries<number[]>[]
  tooltip: Partial<{
    trigger: string
    axisPointer: Partial<{
      type: 'shadow'
    }>
  }>
  colors: string[]
  legend: object
  grid: Partial<{
    left: string
    right: string
    bottom: string
    containLabel: boolean
  }>
  xAxis: Partial<{ type: 'value' }>
  yAxis: object
  label: object
}>()

const data = computed(() => props.data)
const tooltip = computed(() => props.tooltip)
const colors = computed(() => props.colors)
const legendStyle = computed(() => props.legend)
const grid = computed(() => props.grid)
const xAxis = computed(() => props.xAxis)
const yAxis = computed(() => props.yAxis)

let chartStack: echarts.ECharts
const chartStackElement = ref()

const optionStack = computed(() => ({
  tooltip: tooltip.value,
  color: colors.value,
  legend: legendStyle.value,
  grid: grid.value,
  xAxis: xAxis.value,
  yAxis: yAxis.value,
  series: data.value
}))

function resizeChart() {
  if (chartStack) chartStack.resize()
}

onMounted(() => {
  chartStack = echarts.init(chartStackElement.value, null, {
    renderer: 'canvas',
    useDirtyRect: false
  })

  chartStack.setOption(optionStack.value)

  window.addEventListener('resize', resizeChart)
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeChart)
})
</script>

<template>
  <div ref="chartStackElement" class="chart-item__chart" />
</template>

<style lang="scss"></style>
