<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import * as echarts from 'echarts'

const props = defineProps<{
  data: unknown[]
  colors: string[]
  legend?: object
  labelInside?: object
  label?: object
  tooltip: object
  bagel?: boolean
}>()

const colors = computed(() => props.colors)
const legendStyle = computed(() => props.legend)
const data = computed(() => props.data)
const tooltip = computed(() => props.tooltip)
const labelInside = computed(() => props.labelInside)
const label = computed(() => props.label)
const bagel = computed(() => props.bagel)

const chartPieElement = ref()
let chartPie: echarts.ECharts

const optionPie = computed(() => ({
  tooltip: tooltip.value,
  color: colors.value,
  legend: legendStyle.value,
  dataset: [{ source: data.value }],
  series: [
    {
      type: 'pie',
      radius: bagel.value ? ['62%', '70%'] : '70%',
      label: label.value
    },
    {
      name: 'Количество',
      type: 'pie',
      radius: bagel.value ? ['62%', '70%'] : '70%',
      label: { ...labelInside.value, position: 'inside', formatter: '{d}%' },
      emphasis: {
        label: {
          show: true
        },
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}))

function resizeChart() {
  if (chartPie) chartPie.resize()
}

onMounted(() => {
  init()
  window.addEventListener('resize', resizeChart)
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeChart)
})

watch(data, init, {
  deep: true
})

function init() {
  chartPie = echarts.init(chartPieElement.value, null, {
    renderer: 'canvas'
  })

  chartPie.setOption(optionPie.value)
}
</script>

<template>
  <div ref="chartPieElement" class="chart-item__chart"></div>
</template>

<style lang="scss"></style>
