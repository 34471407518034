import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'

import { useRoadStore } from '@/stores'
import { RoadDetail } from '@/types/Road'
import { useRoadWayStore } from './road-way'
import { dateFormat } from '@/utils/date-format'
import { RoadDetailNames } from '@/utils/lists/lists'

interface IRoadDetailStore {
  detail: {
    [key: string]: Array<RoadDetail>
  }
}

const useRoadDetailStore = defineStore('road-detail', () => {
  const roadStore = useRoadStore()
  const roadWay = useRoadWayStore()

  const road = computed(() => roadStore.getRoad)
  const rating = computed(() => roadStore.rating)

  const state: IRoadDetailStore = reactive({
    detail: {
      main: [
        {
          name: RoadDetailNames.MEANING,
          value: computed(() => road.value?.value_of_the_road)
        },
        {
          name: RoadDetailNames.COMISSIONING_DATE,
          value: computed(() => dateFormat(road.value?.date_commissioning))
        },
        {
          name: RoadDetailNames.TECHNICAL_CATEGORY,
          value: computed(() => road.value?.technical_category)
        },
        {
          name: RoadDetailNames.START_ADRESS,
          value: computed(() => road.value?.address_beginning_road)
        },
        {
          name: RoadDetailNames.END_ADRESS,
          value: computed(() => road.value?.address_end_road)
        }
      ],
      class: [
        {
          name: RoadDetailNames.CLASS,
          value: computed(() => road.value?.road_class)
        }
      ],
      type: [
        {
          name: RoadDetailNames.SERVICE_LIFE_LIMIT,
          value: computed(() => road.value?.wearlife)
        },
        {
          name: RoadDetailNames.WEAR,
          value: computed(() => {
            const hasDefectData = road.value?.has_defects || road.value?.has_iri

            const wear = 100 - Math.ceil(rating.value)

            return hasDefectData ? wear : ''
          })
        },
        {
          name: RoadDetailNames.TYPES,
          value: computed(() => roadWay.clothes)
        },
        {
          name: RoadDetailNames.CATEGORY_SP,
          value: computed(() => road.value?.category_snip)
        },
        {
          name: RoadDetailNames.TYPE_OF_PERMITTED_USE,
          value: computed(() => road.value?.resolved_use)
        },
        {
          name: RoadDetailNames.EGRAD_NUMBER,
          value: computed(() => road.value?.egrad_number)
        }
      ]
    }
  })

  const getDetail = computed(() => {
    return state.detail
  })

  return {
    getDetail
  }
})

export default useRoadDetailStore
