import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash'
import { RoadObjectTypes } from '@/types/ui/TableGeneration'
import { fetchWrapper } from '@/api/fetchApi.ts'
import { useRoute } from 'vue-router'

export const useRoadTableGenerationStore = defineStore('road-table-generation', () => {
  const roadObjectTypes = ref<Pick<RoadObjectTypes, 'id' | 'name'>[] | []>([])
  const isLoadingTableData = ref<boolean>(false)

  const route = useRoute()
  const resObjectTypes = async () => {
    isLoadingTableData.value = true

    const response = await fetchWrapper.get('/dicts/object-types/collection', {})

    isLoadingTableData.value = false

    const result = cloneDeep(response) as RoadObjectTypes[]

    roadObjectTypes.value = result.map((type) => {
      return {
        id: type.id,
        name: type.name
      }
    })
  }

  const openTableDataHeader = async (actionTableId: number): Promise<unknown> => {
    isLoadingTableData.value = true

    const responseHeaderTabs = await fetchWrapper.get(
      `/dicts/object-types/${actionTableId}/specs/collection`,
      {}
    )

    isLoadingTableData.value = false

    return responseHeaderTabs
  }

  const openTableData = async (actionTypeId: number, currentPage: number) => {
    const url = computed(() => {
      const road = `/roads/${+route.params.id}`
      const objectTypeId = `/objects/${actionTypeId}`
      const pageNumber = `?page_number=${currentPage}`
      const elementOnPage = `&elements_on_page=10`

      return road + objectTypeId + pageNumber + elementOnPage
    })
    isLoadingTableData.value = true
    const responseDataTable = await fetchWrapper.get(url.value, {})

    isLoadingTableData.value = false
    return responseDataTable
  }

  const toggleColumn = async (actionTableId: {
    columnId: number
    typeId: number | null
    body: boolean
  }) => {
    isLoadingTableData.value = true

    const response = await fetchWrapper.patch(
      `/dicts/object-types/${actionTableId.typeId}/specs/${actionTableId.columnId}`,
      {
        visible: actionTableId.body
      }
    )

    isLoadingTableData.value = false

    return response
  }

  const changeNameColumn = async (actions: {
    typeId: number | null
    columnId: number
    body: { description: string }
  }) => {
    isLoadingTableData.value = true
    const response = await fetchWrapper.patch(
      `/dicts/object-types/${actions.typeId}/specs/${actions.columnId}`,
      {
        description: actions.body.description
      }
    )
    isLoadingTableData.value = false
    return response
  }

  return {
    roadObjectTypes,
    isLoadingTableData,

    resObjectTypes,
    openTableDataHeader,
    openTableData,
    toggleColumn,
    changeNameColumn
  }
})
