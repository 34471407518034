<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

import useDefectPopup from '@/composition/defects/useDefectPopup'

interface InputProps {
  type: 'text' | 'password' | 'number' | 'range' | 'search'
  placeholder: string
  modelValue: string | number | null
  hasError: boolean
  min: number | string
  max: number | string
}

const props = withDefaults(defineProps<Partial<InputProps>>(), {
  type: 'text',
  placeholder: '',
  modelValue: '',
  hasError: false
})

const emits = defineEmits(['onChange', 'update:modelValue', 'mounted'])

const { calcOpacity } = useDefectPopup()

const inputType = computed(() => props.type)
const model = computed(() => (props.modelValue as number) || 0)
const minValue = computed(() => props.min as number)
const maxValue = computed(() => props.max as number)

const activeWidth = computed(() => calcOpacity(model, minValue, maxValue))

const inputRef = ref()

function change(e: Event) {
  emits('onChange', (e.target as HTMLInputElement)?.value)
}

function input(e: Event) {
  let value: string | number | undefined | null = (e.target as HTMLInputElement)?.value

  if (inputType.value === 'number') {
    value = +value
  }

  emits('update:modelValue', value)
}

onMounted(() => {
  emits('mounted', inputRef.value)
})
</script>

<template>
  <input
    v-if="type !== 'range'"
    ref="inputRef"
    :class="{ error: hasError, noEmpty: !!modelValue }"
    :placeholder="placeholder"
    :type="type"
    :value="modelValue"
    :min="min"
    :max="max"
    @change="change"
    @input="input"
  />
  <div v-else class="input-range">
    <input
      type="range"
      :min="min"
      :max="max"
      :value="modelValue"
      class="input-range__input"
      @change="change"
      @input="input"
    />
  </div>
</template>

<style lang="scss" scoped>
input {
  width: 100%;
  height: 4rem;
  padding: 0 1rem;
  transition:
    background-color,
    border 0.5s;
  border: 1px solid $c-gray-40;
  background-color: transparent;
  appearance: none;

  @include color('fontColor');

  font-size: 0.875rem;

  &:focus,
  &.noEmpty {
    transition: border 0.5s;
    border-color: $c-orange;
  }

  &.error {
    transition: background-color 0.5s;
    background-color: rgba($c-orange, 0.1);
  }

  &::placeholder {
    @include color('fontColor');
  }
}

.input-range {
  display: block;
  position: relative;
  height: 100%;

  &__input {
    position: absolute;
    top: 50%;
    height: 5px;
    padding: 0;
    transform: translateY(-50%);
    border-color: transparent;
    outline: none;
    background: linear-gradient(to right, #d2785b v-bind(activeWidth), #fff v-bind(activeWidth));

    &:focus {
      border-color: transparent;
      background-color: $c-white;
    }

    &::-webkit-slider-thumb {
      width: 3px;
      height: 10px;
      background: $c-white;
      cursor: pointer;
      -webkit-appearance: none;
    }
  }
}
</style>
