import type { MapFeatureRendered, IFeature } from '@/types/map/Map'
import type { Map, MapGeoJSONFeature } from 'maplibre-gl'
import type { ChartProps } from '@/types/ui/Chart'

interface LayoutSettings {
  properties: [
    {
      name: string
      property: string
      value: string
    }
  ]
}

export function getLegends(options: Partial<ChartProps>) {
  if (!options.data) return []

  const legends = options.data
    .filter((serie) => serie.name)
    .map((serie) => serie.name)
    .flat()

  return legends.map((legend) => `${options.sign ? `${options.sign} ${legend}` : legend}`)
}

export function toggleEffectOnFeature(
  map: Partial<Map>,
  feature: MapFeatureRendered,
  settings: {
    [key: string]: boolean
  }
) {
  if (feature?.id == undefined) return

  map.setFeatureState?.(feature, settings)
}

export function MapFeature(this: MapFeatureRendered, feature: MapGeoJSONFeature | IFeature) {
  this.id = feature.id
  this.source = feature.source

  if (feature.sourceLayer) {
    this.sourceLayer = feature.sourceLayer
  }
}

export function changeLayoutProperty(map: Map, settings: LayoutSettings) {
  const { properties } = settings

  properties.forEach((layer) => {
    const { name, property, value } = layer

    if (map.getLayer(name)) {
      map.setLayoutProperty(name, property, value)
    }
  })
}
