import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import type { Point } from 'chart.js'
import type { DefectPopupState } from '@/types/Defects'

export const useRoadDefectsPopupStore = defineStore('road-defects-popup', () => {
  const state: DefectPopupState = reactive({
    popupPosition: {
      x: undefined,
      y: undefined
    },
    isOpen: false,
    isDraggable: false
  })

  const isOpen = computed(() => state.isOpen)
  const coordinates = computed(() => state.popupPosition)
  const isDraggable = computed(() => state.isDraggable)

  /**
   * Открыть/закрыть попап
   */
  function changePopupIsOpen(value: boolean) {
    state.isOpen = value
  }

  /**
   * Обновить положение попапа на странице
   */
  function updateCoordinates(coors: Partial<Point>) {
    state.popupPosition = coors
  }

  function updateFromDraggbale(value: boolean) {
    state.isDraggable = value
  }

  return {
    isOpen,
    coordinates,
    isDraggable,

    changePopupIsOpen,
    updateCoordinates,
    updateFromDraggbale
  }
})
