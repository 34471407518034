<script setup lang="ts">
import { CheckboxItem } from '@/types/ui/Checkbox'

const props = withDefaults(defineProps<CheckboxItem>(), {
  id: undefined,
  name: 'Выбрать все',
  checked: false,
  disabled: false,
  hidden: false,
  longText: false /**  проп для контроля тайтла в нейме чекбокса */
})

const emits = defineEmits(['toggle-checkbox'])

const domId = Math.random() * 100_000_000_000

function toggleCheckbox(e: Event) {
  emits('toggle-checkbox', e)
}
</script>

<template>
  <div class="checkbox">
    <input
      :id="'checkbox-item-' + (props.id || domId)"
      :data-id="id"
      class="checkbox__input"
      type="checkbox"
      :checked="props.checked"
      :disabled="props.disabled"
      @change="(e) => toggleCheckbox(e)"
    />
    <label
      :for="'checkbox-item-' + (props.id || domId)"
      class="checkbox__label"
      :class="{
        disabled: props.disabled,
        gap: typeof props.name === 'string' && props.name.length
      }"
    >
      <span
        class="checkbox__fake"
        :class="{ active: props.checked, disabled: props.disabled }"
      ></span>
      <span class="checkbox__name" title="name">
        {{ name }}
      </span>
    </label>
  </div>
</template>

<style scoped lang="scss">
.checkbox {
  position: relative;

  &__fake {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid $c-orange;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      transition: all 0.1s linear;
      opacity: 0;
      background-color: $c-gray-65;
      mask-image: url('@/assets/images/icons/check.svg');
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 100% 100%;
    }

    &.active {
      &::after {
        background-color: $c-orange;
      }
    }

    &.disabled {
      border: 1px solid $c-gray-65;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0;

    &:checked {
      + label .checkbox__fake::after {
        opacity: 1;
        background-color: $c-orange;
      }
    }
  }

  &__label {
    display: grid;
    grid-template-columns: 20px 1fr;
    width: max-content;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }

    &.gap {
      grid-column-gap: 10px;
    }
  }

  &__name {
    display: inline-grid;
    align-items: center;
    font-family: $f-family-base;
    font-size: 1em;
    font-weight: 700;
    text-align: left;

    @include color('fontColor');
  }
}
</style>
