import { SignGost } from '@/utils/lists/sign-road-gost'

import CrossingWithBarrier from '@/assets/images/road-sign/1.1.png'
import CrossingWithoutBarrier from '@/assets/images/road-sign/1.2.png'
import OneWayRailway from '@/assets/images/road-sign/1.3.1.png'
import ManyWayRailway from '@/assets/images/road-sign/1.3.2.png'
import ThreeDistanceRailway from '@/assets/images/road-sign/1.4.1.png'
import TwoDistanceRailway from '@/assets/images/road-sign/1.4.2.png'
import DistanceRailway from '@/assets/images/road-sign/1.4.3.png'
import ThreeDistanceRailway2 from '@/assets/images/road-sign/1.4.4.png'
import TwoDistanceRailway2 from '@/assets/images/road-sign/1.4.5.png'
import DistanceRailway2 from '@/assets/images/road-sign/1.4.6.png'
import IntersectionWithTrailway from '@/assets/images/road-sign/1.5.png'
import IntersectionOfEquivalentRoads from '@/assets/images/road-sign/1.6.png'
import RoundAboutIntersection from '@/assets/images/road-sign/1.7.png'
import TrafficLightRegulation from '@/assets/images/road-sign/1.8.png'
import DrawBridge from '@/assets/images/road-sign/1.9.png'
import DepartureToTheEmbankment from '@/assets/images/road-sign/1.10.png'
import DangerousTurnRight from '@/assets/images/road-sign/1.11.1.png'
import DangerousTurnLeft from '@/assets/images/road-sign/1.11.2.png'
import DangerousTurnsRight from '@/assets/images/road-sign/1.12.1.png'
import DangerousTurnsLeft from '@/assets/images/road-sign/1.12.2.png'
import SteepDescent from '@/assets/images/road-sign/1.13.png'
import SteepClimb from '@/assets/images/road-sign/1.14.png'
import SlipperyRoad from '@/assets/images/road-sign/1.15.png'
import RoughRoad from '@/assets/images/road-sign/1.16.png'
import ArtificialUnevenness from '@/assets/images/road-sign/1.17.png'
import GravelRelease from '@/assets/images/road-sign/1.18.png'
import DangerousRoadside from '@/assets/images/road-sign/1.19.png'
import RoadNarrowsBoth from '@/assets/images/road-sign/1.20.1.png'
import RoadNarrowsRight from '@/assets/images/road-sign/1.20.2.png'
import RoadNarrowsLeft from '@/assets/images/road-sign/1.20.3.png'
import TwoWayTraffic from '@/assets/images/road-sign/1.21.png'
import Precrosswalk from '@/assets/images/road-sign/1.22.png'
import Childrens from '@/assets/images/road-sign/1.23.png'
import IntersectionWithBycicle from '@/assets/images/road-sign/1.24.png'
import RoadWork from '@/assets/images/road-sign/1.25.png'
import CattleDrive from '@/assets/images/road-sign/1.26.png'
import WildAnimals from '@/assets/images/road-sign/1.27.png'
import FallingStones from '@/assets/images/road-sign/1.28.png'
import SideWind from '@/assets/images/road-sign/1.29.png'
import LowFlyingAircraft from '@/assets/images/road-sign/1.30.png'
import TunnelDark from '@/assets/images/road-sign/1.31.png'
import Congestion from '@/assets/images/road-sign/1.32.png'
import OtherHazards from '@/assets/images/road-sign/1.33.png'
import TurningDirectionRight from '@/assets/images/road-sign/1.34.1.png'
import TurningDirectionLeft from '@/assets/images/road-sign/1.34.2.png'
import TurningDirectionT from '@/assets/images/road-sign/1.34.3.png'
import CrossroadsSection from '@/assets/images/road-sign/1.35.png'
import MainRoad from '@/assets/images/road-sign/2.1.png'
import MainRoadEnd from '@/assets/images/road-sign/2.2.png'
import IntersectionwithASecondary from '@/assets/images/road-sign/2.3.1.png'
import JunctionWithSecondaryRoadRight from '@/assets/images/road-sign/2.3.2.png'
import JunctionWithSecondaryRoadLeft from '@/assets/images/road-sign/2.3.3.png'
import JunctionWithSecondaryRoadTopRight from '@/assets/images/road-sign/2.3.4.png'
import JunctionWithSecondaryRoadTopLeft from '@/assets/images/road-sign/2.3.5.png'
import JunctionWithSecondaryRoadBottomRight from '@/assets/images/road-sign/2.3.6.png'
import JunctionWithSecondaryRoadBottomLeft from '@/assets/images/road-sign/2.3.7.png'
import GiveWay from '@/assets/images/road-sign/2.4.png'
import DrivingWithoutStopIsProhibited from '@/assets/images/road-sign/2.5.png'
import TheAdvantageOfOncommingTraffic from '@/assets/images/road-sign/2.6.png'
import AdvantageOverOncomingTraffic from '@/assets/images/road-sign/2.7.png'
import NoEntry from '@/assets/images/road-sign/3.1.png'
import MovementProhibition from '@/assets/images/road-sign/3.2.png'
import MovementOfMotorVehiclesIsProhibited from '@/assets/images/road-sign/3.3.png'
import TruckTrafficIsProhibited from '@/assets/images/road-sign/3.4.png'
import MotorcyclesAreProhibited from '@/assets/images/road-sign/3.5.png'
import TractorTrafficIsProhibitted from '@/assets/images/road-sign/3.6.png'
import DrivingWithATrailerIsProhibited from '@/assets/images/road-sign/3.7.png'
import HorseDrawnCarriagesAreProhibited from '@/assets/images/road-sign/3.8.png'
import ByciclesAreProhibited from '@/assets/images/road-sign/3.9.png'
import NoPedestrians from '@/assets/images/road-sign/3.10.png'
import WeightLimitation from '@/assets/images/road-sign/3.11.png'
import LimitationOfMassPerAxleOfVehicles from '@/assets/images/road-sign/3.12.png'
import HeightLimitation from '@/assets/images/road-sign/3.13.png'
import WidthLimitation from '@/assets/images/road-sign/3.14.png'
import LengthLimitation from '@/assets/images/road-sign/3.15.png'
import MinimumDistanveLimitation from '@/assets/images/road-sign/3.16.png'
import Custom from '@/assets/images/road-sign/3.17.1.png'
import Dangerous from '@/assets/images/road-sign/3.17.2.png'
import Control from '@/assets/images/road-sign/3.17.3.png'
import TurningToTheRightIsProhibited from '@/assets/images/road-sign/3.18.1.png'
import TurningToTheLeftIsProhibited from '@/assets/images/road-sign/3.18.2.png'
import NoTurning from '@/assets/images/road-sign/3.19.png'
import OvertakingIsFrobiden from '@/assets/images/road-sign/3.20.png'
import EndOfTheNoOvertakingZone from '@/assets/images/road-sign/3.21.png'
import OvertakingByATruckIsProhibited from '@/assets/images/road-sign/3.22.png'
import EndOfTheNoOvertakingZoneForTrucks from '@/assets/images/road-sign/3.23.png'
import MaximumSpeedLimit from '@/assets/images/road-sign/3.24.png'
import EndOfMaximumSpeedLimitZone from '@/assets/images/road-sign/3.25.png'
import SoundSignalIsProhibited from '@/assets/images/road-sign/3.26.png'
import StoppingIsProhibited from '@/assets/images/road-sign/3.27.png'
import NoParking from '@/assets/images/road-sign/3.28.png'
import ParkingIsProhibitedOnAddDaysOfTheMonth from '@/assets/images/road-sign/3.29.png'
import ParkingIsProhibitedOnEvenDaysOfTheMonth from '@/assets/images/road-sign/3.30.png'
import TheEndOfZoneOfAllRestrictions from '@/assets/images/road-sign/3.31.png'
import TheMovementWithDangerousGoodsIsProhibited from '@/assets/images/road-sign/3.32.png'
import TheMovementWithDangerousAndFlamableGoodsIsProhibited from '@/assets/images/road-sign/3.33.png'
import BusTrafficIsProhibitted from '@/assets/images/road-sign/3.34.png'
import DrivingOnPersonalMobiltyProhibited from '@/assets/images/road-sign/3.35.png'
import GoStraight from '@/assets/images/road-sign/4.1.1.png'
import MovementToTheRight from '@/assets/images/road-sign/4.1.2.png'
import MovementToTheLeft from '@/assets/images/road-sign/4.1.3.png'
import DrivingStraightOrRight from '@/assets/images/road-sign/4.1.4.png'
import DrivingStraightOrLeft from '@/assets/images/road-sign/4.1.5.png'
import MovingRightOrLeft from '@/assets/images/road-sign/4.1.6.png'
import AvodingObstaclesOnTheRight from '@/assets/images/road-sign/4.2.1.png'
import AvodingObstaclesOnTheLeft from '@/assets/images/road-sign/4.2.2.png'
import AvodingObstaclesOnTheRightOrLeft from '@/assets/images/road-sign/4.2.3.png'
import RoundaboutCirculation from '@/assets/images/road-sign/4.3.png'
import BikeLake from '@/assets/images/road-sign/4.4.1.png'
import EndOfTheBikePath from '@/assets/images/road-sign/4.4.2.png'
import Footpath from '@/assets/images/road-sign/4.5.1.png'
import PedestrianAndBicycleCombined from '@/assets/images/road-sign/4.5.2.png'
import EndPedestrianAndBicycle from '@/assets/images/road-sign/4.5.3.png'
import PedestrianAndBycicleSeparationLeft from '@/assets/images/road-sign/4.5.4.png'
import PedestrianAndBycicleSeparationRight from '@/assets/images/road-sign/4.5.5.png'
import EndPedestrianAndBycicleSeparationRLeft from '@/assets/images/road-sign/4.5.6.png'
import EndPedestrianAndBycicleSeparationRight from '@/assets/images/road-sign/4.5.7.png'
import MinimumSpeedLimit from '@/assets/images/road-sign/4.6.png'
import EndOfMinimumSpeedLimit from '@/assets/images/road-sign/4.7.png'
import DirectionStraightWithDangerousGoods from '@/assets/images/road-sign/4.8.1.png'
import DirectionRightWithDangerousGoods from '@/assets/images/road-sign/4.8.2.png'
import DirectionLeftWithDangerousGoods from '@/assets/images/road-sign/4.8.3.png'
import Motorway from '@/assets/images/road-sign/5.1.png'
import EndMotorway from '@/assets/images/road-sign/5.2.png'
import RoadForCars from '@/assets/images/road-sign/5.3.png'
import EndRoadForCars from '@/assets/images/road-sign/5.4.png'
import OnewayRoad from '@/assets/images/road-sign/5.5.png'
import EndOnewayRoad from '@/assets/images/road-sign/5.6.png'
import EnteringAOneWayRoadRight from '@/assets/images/road-sign/5.7.1.png'
import EnteringAOneWayRoadLeft from '@/assets/images/road-sign/5.7.2.png'
import ReverseMovement from '@/assets/images/road-sign/5.8.png'
import EndReverseMovement from '@/assets/images/road-sign/5.9.png'
import EnteringWithReverseTraffic from '@/assets/images/road-sign/5.10.png'
import RoadWithLineRoute from '@/assets/images/road-sign/5.11.1.png'
import RoadWithLineBicycle from '@/assets/images/road-sign/5.11.2.png'
import EndRoadWithLineRoute from '@/assets/images/road-sign/5.12.1.png'
import EndRoadWithLineBicycle from '@/assets/images/road-sign/5.12.2.png'
import EnteringRoadWithLineRouteLeft from '@/assets/images/road-sign/5.13.1.png'
import EnterinRoadWithLineRouteRight from '@/assets/images/road-sign/5.13.2.png'
import EnteringRoadWithLaneCyclistsLeft from '@/assets/images/road-sign/5.13.3.png'
import EnteringRoadWithLaneCyclistsRight from '@/assets/images/road-sign/5.13.4.png'
import LineForRouteVihicles from '@/assets/images/road-sign/5.14.1.png'
import CyclistLane from '@/assets/images/road-sign/5.14.2.png'
import EndLineForRouteVihicles from '@/assets/images/road-sign/5.14.3.png'
import EndCyclistLane from '@/assets/images/road-sign/5.14.4.png'
import LaneDirections from '@/assets/images/road-sign/5.15.1.png'
import LaneDirections2 from '@/assets/images/road-sign/5.15.2.png'
import StartOfTheStripRight from '@/assets/images/road-sign/5.15.3.png'
import StartOfTheStripLeft from '@/assets/images/road-sign/5.15.4.png'
import EndOfTheStripRight from '@/assets/images/road-sign/5.15.5.png'
import EndOfTheStripLeft from '@/assets/images/road-sign/5.15.6.png'
import LaneDirections3 from '@/assets/images/road-sign/5.15.7.png'
import NumberOfLanes from '@/assets/images/road-sign/5.15.8.png'
import BusStopLocation from '@/assets/images/road-sign/5.16.png'
import TrumStopLocation from '@/assets/images/road-sign/5.17.png'
import TaxiParkingArea from '@/assets/images/road-sign/5.18.png'
import CrosswalkLeft from '@/assets/images/road-sign/5.19.1.png'
import CrosswalkRight from '@/assets/images/road-sign/5.19.2.png'
import ArtificialHump from '@/assets/images/road-sign/5.20.png'
import LivingSector from '@/assets/images/road-sign/5.21.png'
import EndLivingSector from '@/assets/images/road-sign/5.22.png'
import StartLocalityUnknown from '@/assets/images/road-sign/5.23.2.png'
import EndLocalityUnknown from '@/assets/images/road-sign/5.24.2.png'
import RestrictedParkingZone from '@/assets/images/road-sign/5.27.png'
import EndRestrictedParkingZone from '@/assets/images/road-sign/5.28.png'
import RegulatedParkingArea from '@/assets/images/road-sign/5.29.png'
import EndRegulatedParkingArea from '@/assets/images/road-sign/5.30.png'
import MaximumSpeedZone from '@/assets/images/road-sign/5.31.png'
import EndMaximumSpeedZone from '@/assets/images/road-sign/5.32.png'
import PedestrianZone from '@/assets/images/road-sign/5.33.png'
import EndPedestrianZone from '@/assets/images/road-sign/5.34.png'
import ZoneRestrictionsEnvironmentalMotor from '@/assets/images/road-sign/5.35.png'
import EndZoneRestrictionsEnvironmentalMotor from '@/assets/images/road-sign/5.36.png'
import ZoneLimitedEnvironmentalByType from '@/assets/images/road-sign/5.37.png'
import EndZoneLimitedEnvironmentalByType from '@/assets/images/road-sign/5.38.png'
import BicycleZone from '@/assets/images/road-sign/5.39.png'
import EndBicycleZone from '@/assets/images/road-sign/5.40.png'
import GeneralMaximumSpeedLimits from '@/assets/images/road-sign/6.1.png'
import RecommendedSpeed from '@/assets/images/road-sign/6.2.png'
import TurningSpace from '@/assets/images/road-sign/6.3.1.png'
import TurningArea from '@/assets/images/road-sign/6.3.2.png'
import Parking from '@/assets/images/road-sign/6.4.png'
import EmergencyStopStrip from '@/assets/images/road-sign/6.5.png'
import UndergroundPedestrianCrossing from '@/assets/images/road-sign/6.6.png'
import OverheadPedestrianCrossing from '@/assets/images/road-sign/6.7.png'
import DeadEndRight from '@/assets/images/road-sign/6.8.1.png'
import DeadEndOnTheRight from '@/assets/images/road-sign/6.8.2.png'
import DeadEndOnTheLeft from '@/assets/images/road-sign/6.8.3.png'
import TrafficPattern from '@/assets/images/road-sign/6.9.3.png'
import DrivingDirectionForTrucksStraight from '@/assets/images/road-sign/6.15.1.png'
import DrivingDirectionForTrucksRight from '@/assets/images/road-sign/6.15.2.png'
import DrivingDirectionForTrucksLeft from '@/assets/images/road-sign/6.15.3.png'
import StopLine from '@/assets/images/road-sign/6.16.png'
import DetourScheme from '@/assets/images/road-sign/6.17.png'
import DetourSchemeStraight from '@/assets/images/road-sign/6.18.1.png'
import DetourSchemeRight from '@/assets/images/road-sign/6.18.2.png'
import DetourSchemeLeft from '@/assets/images/road-sign/6.18.3.png'
import PreliminaryIndicatorForChangingLines from '@/assets/images/road-sign/6.19.1.png'
import PreliminaryIndicatorForChangingLines2 from '@/assets/images/road-sign/6.19.2.png'
import EmergencyExitLeft from '@/assets/images/road-sign/6.20.1.png'
import EmergencyExitRight from '@/assets/images/road-sign/6.20.2.png'
import DirectionOfMovementToTheEmergencyExit from '@/assets/images/road-sign/6.21.png'
import PhotoAndVideoRecording from '@/assets/images/road-sign/6.22.png'
import MedicalAidStation from '@/assets/images/road-sign/7.1.png'
import Hospital from '@/assets/images/road-sign/7.2.png'
import GasStation from '@/assets/images/road-sign/7.3.png'
import CarMaintenance from '@/assets/images/road-sign/7.4.png'
import CarWah from '@/assets/images/road-sign/7.5.png'
import Phone from '@/assets/images/road-sign/7.6.png'
import FoodPoint from '@/assets/images/road-sign/7.7.png'
import DrinkingWater from '@/assets/images/road-sign/7.8.png'
import Hotel from '@/assets/images/road-sign/7.9.png'
import Camping from '@/assets/images/road-sign/7.10.png'
import RestPlace from '@/assets/images/road-sign/7.11.png'
import DPS from '@/assets/images/road-sign/7.12.png'
import Police from '@/assets/images/road-sign/7.13.png'
import CustomControlPoint from '@/assets/images/road-sign/7.14.1.png'
import TransportControlPoint from '@/assets/images/road-sign/7.14.2.png'
import ReceptionAreaOfRadio from '@/assets/images/road-sign/7.15.png'
import RadioCommunicationAreaWithEmergencyServices from '@/assets/images/road-sign/7.16.png'
import PoolOrBeach from '@/assets/images/road-sign/7.17.png'
import Wc from '@/assets/images/road-sign/7.18.png'
import EmergencyPhoneNumber from '@/assets/images/road-sign/7.19.png'
import FireExtinguisher from '@/assets/images/road-sign/7.20.png'
import GasStationWithElectric from '@/assets/images/road-sign/7.21.png'
import CoverageAreaEnd from '@/assets/images/road-sign/8.2.3.png'
import CoverageAreaContinue from '@/assets/images/road-sign/8.2.4.png'
import DirectionActionRight from '@/assets/images/road-sign/8.3.1.png'
import DirectionActionLeft from '@/assets/images/road-sign/8.3.2.png'
import DirectionActionContinue from '@/assets/images/road-sign/8.3.3.png'
import TypeVehicleTruck from '@/assets/images/road-sign/8.4.1.png'
import TypeVehicleTrailer from '@/assets/images/road-sign/8.4.2.png'
import TypeVehicleAuto from '@/assets/images/road-sign/8.4.3.png'
import TypeVehicleElectro from '@/assets/images/road-sign/8.4.3.1.png'
import TypeVehicleBus from '@/assets/images/road-sign/8.4.4.png'
import TypeVehicleTractor from '@/assets/images/road-sign/8.4.5.png'
import TypeVehicleMoto from '@/assets/images/road-sign/8.4.6.png'
import TypeVehicleBicycle from '@/assets/images/road-sign/8.4.7.png'
import TypeVehicleDanger from '@/assets/images/road-sign/8.4.8.png'
import TypeVehicleScooter from '@/assets/images/road-sign/8.4.7.2.png'
import ExceptTypeVehicleTruck from '@/assets/images/road-sign/8.4.9.png'
import ExceptTypeVehicleAuto from '@/assets/images/road-sign/8.4.10.png'
import ExceptTypeVehicleBus from '@/assets/images/road-sign/8.4.11.png'
import ExceptTypeVehicleMoto from '@/assets/images/road-sign/8.4.12.png'
import ExceptTypeVehicleBicycle from '@/assets/images/road-sign/8.4.13.png'
import ExceptTypeVehicleTaxi from '@/assets/images/road-sign/8.4.14.png'
import ExceptTypeVehicleElectro from '@/assets/images/road-sign/8.4.15.png'
import ExceptTypeVehicleScooter from '@/assets/images/road-sign/8.4.16.png'
import Weekend from '@/assets/images/road-sign/8.5.1.png'
import WorkDays from '@/assets/images/road-sign/8.5.2.png'
import MethodOfParkingAVehicle from '@/assets/images/road-sign/8.6.1.png'
import MethodOfParkingAVehicle2 from '@/assets/images/road-sign/8.6.1.png'
import MethodOfParkingAVehicle3 from '@/assets/images/road-sign/8.6.3.png'
import MethodOfParkingAVehicle4 from '@/assets/images/road-sign/8.6.4.png'
import MethodOfParkingAVehicle5 from '@/assets/images/road-sign/8.6.5.png'
import MethodOfParkingAVehicle6 from '@/assets/images/road-sign/8.6.6.png'
import MethodOfParkingAVehicle7 from '@/assets/images/road-sign/8.6.7.png'
import MethodOfParkingAVehicle8 from '@/assets/images/road-sign/8.6.8.png'
import MethodOfParkingAVehicle9 from '@/assets/images/road-sign/8.6.9.png'
import ParkingWithTheEngineNotRunning from '@/assets/images/road-sign/8.7.png'
import PaidServices from '@/assets/images/road-sign/8.8.png'
import ParkingForPermitHoldersOnly from '@/assets/images/road-sign/8.9.2.png'
import ParkingForDiplomatiocVehiclesOnly from '@/assets/images/road-sign/8.9.3.png'
import VehicleInspectionArea from '@/assets/images/road-sign/8.10.png'
import DangerousRoadsideAdditional from '@/assets/images/road-sign/8.12.png'
import MainRoadDirection from '@/assets/images/road-sign/8.13.png'
import Lane from '@/assets/images/road-sign/8.14.png'
import BlindPedestrians from '@/assets/images/road-sign/8.15.png'
import WetCoating from '@/assets/images/road-sign/8.16.png'
import DisabledPeople from '@/assets/images/road-sign/8.17.png'
import ExceptDisabledPeople from '@/assets/images/road-sign/8.18.png'
import VehicleBoogieType from '@/assets/images/road-sign/8.20.1.png'
import VehicleBoogieType2 from '@/assets/images/road-sign/8.20.2.png'
import TypeOfRouteVehicle from '@/assets/images/road-sign/8.21.1.png'
import TypeOfRouteVehicle2 from '@/assets/images/road-sign/8.21.2.png'
import TypeOfRouteVehicle3 from '@/assets/images/road-sign/8.21.3.png'
import Let from '@/assets/images/road-sign/8.22.1.png'
import Let2 from '@/assets/images/road-sign/8.22.2.png'
import Let3 from '@/assets/images/road-sign/8.22.3.png'
import TowTruckIsWorking from '@/assets/images/road-sign/8.24.png'
import ChargingElectricVehicles from '@/assets/images/road-sign/8.26.png'

import { GEOMETRY_CONSTANTS } from '@/utils/consts/consts'

const LAYER_SIGN_PREFIX = 'sign'

const HEIGHT = 'auto'

export const signData = [
  {
    gost: SignGost.RAILWAY_CROSSING_WITH_BARRIER,
    layerName: `railway-crossing-with-barrier-${LAYER_SIGN_PREFIX}`,
    imageName: 'railway-crossing-with-barrier',
    img: CrossingWithBarrier,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.RAILWAY_CROSSING_WITHOUT_BARRIER,
    layerName: `railway-crossing-without-barrier-${LAYER_SIGN_PREFIX}`,
    imageName: 'railway-crossing-without-barrier',
    img: CrossingWithoutBarrier,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ONE_WAY_RAILWAY,
    layerName: `one-way-railway-${LAYER_SIGN_PREFIX}`,
    imageName: 'one-way-railway',
    img: OneWayRailway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MANY_WAY_RAILWAY,
    layerName: `many-way-railway-${LAYER_SIGN_PREFIX}`,
    imageName: 'many-way-railway',
    img: ManyWayRailway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.THREE_X_DISTANCE_RAILWAY,
    layerName: `three-x-distance-railway-${LAYER_SIGN_PREFIX}`,
    imageName: 'three-x-distance-railway',
    img: ThreeDistanceRailway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.TWO_X_DISTANCE_RAILWAY,
    layerName: `two-x-distance-railway-${LAYER_SIGN_PREFIX}`,
    imageName: 'two-x-distance-railway',
    img: TwoDistanceRailway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.DISTANCE_RAILWAY,
    layerName: `distance-railway-${LAYER_SIGN_PREFIX}`,
    imageName: 'distance-railway',
    img: DistanceRailway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.THREE_X_DISTANCE_RAILWAY_2,
    layerName: `three-x-distance-railway-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'three-x-distance-railway-2',
    img: ThreeDistanceRailway2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.TWO_X_DISTANCE_RAILWAY_2,
    layerName: `two-x-distance-railway-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'two-x-distance-railway-2',
    img: TwoDistanceRailway2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.DISTANCE_RAILWAY_2,
    layerName: `distance-railway-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'distance-railway-2',
    img: DistanceRailway2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.INTERSECTION_WITH_TRAM_FABRIC,
    layerName: `intersection-with-tram-${LAYER_SIGN_PREFIX}`,
    imageName: 'intersection-with-tram',
    img: IntersectionWithTrailway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.INTERSECTION_OF_EQUIVALENT_ROADS,
    layerName: `intersection-of-equivalent-roads-${LAYER_SIGN_PREFIX}`,
    imageName: 'intersection-of-equivalent-roads',
    img: IntersectionOfEquivalentRoads,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROUNDABOUT_INTERSECTION,
    layerName: `roundabout-intersection-${LAYER_SIGN_PREFIX}`,
    imageName: 'round-about-intersection',
    img: RoundAboutIntersection,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TRAFFIC_LIGHT_REGULATION,
    layerName: `traffic-light-regulation-${LAYER_SIGN_PREFIX}`,
    imageName: 'traffic-light-regulation',
    img: TrafficLightRegulation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DRAWBRIDGE,
    layerName: `drawbridge-${LAYER_SIGN_PREFIX}`,
    imageName: 'drawbridge',
    img: DrawBridge,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DEPARTURE_TO_THE_EMBANKMENT,
    layerName: `departure-to-the-embankment-${LAYER_SIGN_PREFIX}`,
    imageName: 'departure-to-the-embankment',
    img: DepartureToTheEmbankment,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DANGEROUS_TURN_RIGHT,
    layerName: `dangerous-turn-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous-turn-right',
    img: DangerousTurnRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DANGEROUS_TURN_LEFT,
    layerName: `dangerous-turn-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous-turn-left',
    img: DangerousTurnLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DANGEROUS_TURNS_RIGHT,
    layerName: `dangerous-turns-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous-turns-right',
    img: DangerousTurnsRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DANGEROUS_TURNS_LEFT,
    layerName: `dangerous-turns-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous-turns-left',
    img: DangerousTurnsLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.STEEP_DESCENT,
    layerName: `steep-descent-${LAYER_SIGN_PREFIX}`,
    imageName: 'steep-descent',
    img: SteepDescent,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'steep-descent'
  },
  {
    gost: SignGost.THE_STEEP_CLIMB,
    layerName: `the-steep-climb-${LAYER_SIGN_PREFIX}`,
    imageName: 'the-steep-climb',
    img: SteepClimb,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'the-steep-climb'
  },
  {
    gost: SignGost.SLIPPERY_ROAD,
    layerName: `slippery-road-${LAYER_SIGN_PREFIX}`,
    imageName: 'slippery-road',
    img: SlipperyRoad,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROUGH_ROAD,
    layerName: `rough-road-${LAYER_SIGN_PREFIX}`,
    imageName: 'rough-road',
    img: RoughRoad,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ARTIFICIAL_UNEVENNESS,
    layerName: `artificial-unevenness-${LAYER_SIGN_PREFIX}`,
    imageName: 'artificial-unevenness',
    img: ArtificialUnevenness,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.GRAVEL_RELEASE,
    layerName: `gravel-release-${LAYER_SIGN_PREFIX}`,
    imageName: 'gravel-release',
    img: GravelRelease,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DANGEROUS_ROADSIDE,
    layerName: `dangerous-roadside-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous-roadside',
    img: DangerousRoadside,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROAD_NARROWS_BOTH,
    layerName: `road-narrows-both-${LAYER_SIGN_PREFIX}`,
    imageName: 'road-narrows-both',
    img: RoadNarrowsBoth,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROAD_NARROWS_RIGHT,
    layerName: `road-narrows-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'road-narrows-right',
    img: RoadNarrowsRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROAD_NARROWS_LEFT,
    layerName: `road-narrows-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'road-narrows-left',
    img: RoadNarrowsLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TWO_WAY_TRAFFIC,
    layerName: `two-way-traffic-${LAYER_SIGN_PREFIX}`,
    imageName: 'two-way-traffic',
    img: TwoWayTraffic,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PRE_CROSSWALK,
    layerName: `pre-crosswalk-${LAYER_SIGN_PREFIX}`,
    imageName: 'pre-crosswalk',
    img: Precrosswalk,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CHILDRENS,
    layerName: `childrens-${LAYER_SIGN_PREFIX}`,
    imageName: 'childrens',
    img: Childrens,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.INTERSECTION_WITH_BYCICLE,
    layerName: `intersection-with-bycicle-${LAYER_SIGN_PREFIX}`,
    imageName: 'intersection-with-bycicle',
    img: IntersectionWithBycicle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROAD_WORK,
    layerName: `road-work-${LAYER_SIGN_PREFIX}`,
    imageName: 'road-work',
    img: RoadWork,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CATTLE_DRIVE,
    layerName: `cattle-drive-${LAYER_SIGN_PREFIX}`,
    imageName: 'cattle-drive',
    img: CattleDrive,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.WILD_ANIMALS,
    layerName: `wild-animals-${LAYER_SIGN_PREFIX}`,
    imageName: 'wild-animals',
    img: WildAnimals,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.FALLING_STONES,
    layerName: `falling-stones-${LAYER_SIGN_PREFIX}`,
    imageName: 'falling-stones',
    img: FallingStones,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.SIDE_WIND,
    layerName: `side-wind-${LAYER_SIGN_PREFIX}`,
    imageName: 'side-wind',
    img: SideWind,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LOW_FLYING_AIRCRAFT,
    layerName: `low-flying-aircraft-${LAYER_SIGN_PREFIX}`,
    imageName: 'low-flying-aircraft',
    img: LowFlyingAircraft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TUNNEL,
    layerName: `tunnel-dark-${LAYER_SIGN_PREFIX}`,
    imageName: 'tunnel-dark',
    img: TunnelDark,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CONGESTION,
    layerName: `congestion-${LAYER_SIGN_PREFIX}`,
    imageName: 'congestion',
    img: Congestion,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.OTHER_HAZARDS,
    layerName: `other-hazards-${LAYER_SIGN_PREFIX}`,
    imageName: 'other-hazards',
    img: OtherHazards,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TURNING_DIRECTION_RIGHT,
    layerName: `turning-direction-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'turning-direction-right',
    img: TurningDirectionRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.TURNING_DIRECTION_LEFT,
    layerName: `turning-direction-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'turning-direction-left',
    img: TurningDirectionLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.TURNING_DIRECTION_T,
    layerName: `turning-direction-t-${LAYER_SIGN_PREFIX}`,
    imageName: 'turning-direction-t',
    img: TurningDirectionT,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.CROSSROADS_SECTION,
    layerName: `crossroads-section-${LAYER_SIGN_PREFIX}`,
    imageName: 'crossroads-section',
    img: CrossroadsSection,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MAIN_ROAD,
    img: MainRoad,
    layerName: `main-road-${LAYER_SIGN_PREFIX}`,
    imageName: 'main-road',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MAIN_ROAD_END,
    layerName: `main-road-end-${LAYER_SIGN_PREFIX}`,
    imageName: 'main-road-end',
    img: MainRoadEnd,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.INTERSECTION_WITH_A_SECONDARY_ROAD,
    layerName: `intersection-with-a-secondary-${LAYER_SIGN_PREFIX}`,
    imageName: 'intersection-with-a-secondary',
    img: IntersectionwithASecondary,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.JUNCTION_WITH_A_SECONDARY_ROAD_RIGHT,
    layerName: `junction-with-a-secondary-road-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'junction-with-a-secondary-road-right',
    img: JunctionWithSecondaryRoadRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.JUNCTION_WITH_A_SECONDARY_ROAD_LEFT,
    layerName: `junction-with-a-secondary-road-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'junction-with-a-secondary-road-left',
    img: JunctionWithSecondaryRoadLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.JUNCTION_WITH_A_SECONDARY_ROAD_TOP_RIGHT,
    layerName: `junction-with-a-secondary-road-top-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'junction-with-a-secondary-road-top-right',
    img: JunctionWithSecondaryRoadTopRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.JUNCTION_WITH_A_SECONDARY_ROAD_TOP_LEFT,
    layerName: `junction-with-a-secondary-road-top-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'junction-with-a-secondary-road-top-left',
    img: JunctionWithSecondaryRoadTopLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.JUNCTION_WITH_A_SECONDARY_ROAD_BOTTOM_RIGHT,
    layerName: `junction-with-a-secondary-road-bottom-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'junction-with-a-secondary-road-bottom-right',
    img: JunctionWithSecondaryRoadBottomRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.JUNCTION_WITH_A_SECONDARY_ROAD_BOTTOM_LEFT,
    layerName: `junction-with-a-secondary-road-bottom-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'junction-with-a-secondary-road-bottom-left',
    img: JunctionWithSecondaryRoadBottomLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.GIVE_WAY,
    img: GiveWay,
    layerName: `give-way-${LAYER_SIGN_PREFIX}`,
    imageName: 'give-way',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DRIVING_WITHOUT_STOPPING_IS_PROHIBITED,
    layerName: `stop-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'stop-prohibited',
    img: DrivingWithoutStopIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.THE_ADVANTAGE_OF_ONCOMIMG_TRAFFIC,
    layerName: `the-advantage-of-oncoming-traffic-${LAYER_SIGN_PREFIX}`,
    imageName: 'the-advantage-of-oncoming-traffic',
    img: TheAdvantageOfOncommingTraffic,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ADVANTAGE_OVER_ONCOMING_TRAFFIC,
    layerName: `advantage-over-oncoming-traffic-${LAYER_SIGN_PREFIX}`,
    imageName: 'advantage-over-oncoming-traffic',
    img: AdvantageOverOncomingTraffic,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.NO_ENTRY,
    layerName: `no-entry-${LAYER_SIGN_PREFIX}`,
    imageName: 'no-entry',
    img: NoEntry,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MOVEMENT_PROHIBITION,
    layerName: `movement-prohibition-${LAYER_SIGN_PREFIX}`,
    imageName: 'movement-prohibition',
    img: MovementProhibition,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MOVEMENT_OF_MOTOR_VIHICLES_IS_PROHIBITED,
    layerName: `movement-of-motor-vihicles-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'movement-of-motor-vihicles-is-prohibited',
    img: MovementOfMotorVehiclesIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TRUCK_TRAFFIC_IS_PROHIBITED,
    layerName: `sign-truck-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'sign-truck-prohibited',
    img: TruckTrafficIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'truck-traffic-is-prohibited'
  },
  {
    gost: SignGost.MOTORCYCLES_ARE_PROHIBITED,
    layerName: `motorcycles-are-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'motorcycles-are-prohibited',
    img: MotorcyclesAreProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TRACTOR_TRAFFIC_IS_PROHIBITED,
    layerName: `tractor-traffic-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'tractor-traffic-is-prohibited',
    img: TractorTrafficIsProhibitted,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DRIVING_WITH_A_TRAILER_IS_PROHIBITED,
    layerName: `driving-with-a-trailer-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'driving-with-a-trailer-is-prohibited',
    img: DrivingWithATrailerIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.HORSE_DRAWN_CARRIAGES_ARE_PROHIBITED,
    layerName: `horse-dawn-carriages-are-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'horse-dawn-carriages-are-prohibited',
    img: HorseDrawnCarriagesAreProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.BICYCLES_ARE_PROHIBITED,
    layerName: `bicycles-are-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'bicycles-are-prohibited',
    img: ByciclesAreProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.NO_PEDESTRIANS,
    layerName: `no-pedestrians-${LAYER_SIGN_PREFIX}`,
    imageName: 'no-pedestrians',
    img: NoPedestrians,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.WEIGHT_LIMITATION,
    layerName: `weight-limitation-${LAYER_SIGN_PREFIX}`,
    imageName: 'weight-limitation',
    img: WeightLimitation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'weight-limitation'
  },
  {
    gost: SignGost.LIMITATION_OF_MASS_PER_AXLE_OF_VEHICLES,
    layerName: `limitation-of-mass-per-axle-of-vehicles-${LAYER_SIGN_PREFIX}`,
    imageName: 'limitation-of-mass-per-axle-of-vehicles',
    img: LimitationOfMassPerAxleOfVehicles,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'limitation-of-mass-per-axle-of-vehicles'
  },
  {
    gost: SignGost.HEIGHT_LIMITATION,
    layerName: `height-limitation-${LAYER_SIGN_PREFIX}`,
    imageName: 'height-limitation',
    img: HeightLimitation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'height-limitation'
  },
  {
    gost: SignGost.WIDTH_LIMITATION,
    layerName: `width-limitation-${LAYER_SIGN_PREFIX}`,
    imageName: 'width-limitation',
    img: WidthLimitation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'width-limitation'
  },
  {
    gost: SignGost.LENGTH_LIMITATION,
    layerName: `length-limitation-${LAYER_SIGN_PREFIX}`,
    imageName: 'length-limitation',
    img: LengthLimitation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'length-limitation'
  },
  {
    gost: SignGost.MINIMUM_DISTANCE_LIMITATION,
    layerName: `minimum-distance-limitation-${LAYER_SIGN_PREFIX}`,
    imageName: 'minimum-distance-limitation',
    img: MinimumDistanveLimitation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'minimum-distance-limitation'
  },
  {
    gost: SignGost.CUSTOM,
    layerName: `custom-${LAYER_SIGN_PREFIX}`,
    imageName: 'custom',
    img: Custom,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DANGEROUS,
    layerName: `dangerous-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous',
    img: Dangerous,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CONTROL,
    layerName: `control-${LAYER_SIGN_PREFIX}`,
    imageName: 'control',
    img: Control,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TURNING_TO_THE_RIGHT_IS_PROHIBITED,
    layerName: `turning-to-the-right-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'turning-to-the-right-is-prohibited',
    img: TurningToTheRightIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TURNING_TO_THE_LEFT_IS_PROHIBITED,
    layerName: `turning-to-the-left-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'turning-to-the-left-is-prohibited',
    img: TurningToTheLeftIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.NO_TURNING,
    layerName: `no-turning-${LAYER_SIGN_PREFIX}`,
    imageName: 'no-turning',
    img: NoTurning,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.OVERTAKING_IS_FORBIDDEN,
    layerName: `overtaking-is-forbiden-${LAYER_SIGN_PREFIX}`,
    imageName: 'overtaking-is-forbiden',
    img: OvertakingIsFrobiden,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_OF_THE_NO_OVERTAKING_ZONE,
    layerName: `end-of-the-no-overtaking-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-of-the-no-overtaking-zone',
    img: EndOfTheNoOvertakingZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.OVERTAKING_BY_A_TRUCK_IS_PROHIBITED,
    layerName: `overtaking-by-a-truck-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'overtaking-by-a-truck-is-prohibited',
    img: OvertakingByATruckIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_OF_THE_NO_OVERTAKING_ZONE_FOR_TRUCKS,
    layerName: `end-of-the-no-overtaking-zone-for-trucks-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-of-the-no-overtaking-zone-for-trucks',
    img: EndOfTheNoOvertakingZoneForTrucks,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MAXIMUM_SPEED_LIMIT,
    layerName: `maximum-speed-limit-${LAYER_SIGN_PREFIX}`,
    imageName: 'maximum-speed-limit',
    img: MaximumSpeedLimit,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'maximum-speed-limit'
  },
  {
    gost: SignGost.END_OF_MAXIMUM_SPEED_LIMIT_ZONE,
    layerName: `end-of-maximum-speed-limit-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-of-maximum-speed-limit-zone',
    img: EndOfMaximumSpeedLimitZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'end-of-maximum-speed-limit-zone'
  },
  {
    gost: SignGost.SOUND_SIGNAL_IS_PROHIBITED,
    layerName: `sound-signal-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'sound-signal-is-prohibited',
    img: SoundSignalIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.STOPPING_IS_PROHIBITED,
    layerName: `stopping-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'stopping-is-prohibited',
    img: StoppingIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.NO_PARKING,
    layerName: `no-parking-${LAYER_SIGN_PREFIX}`,
    imageName: 'no-parking',
    img: NoParking,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PARKING_IS_PROHIBITED_ON_ODD_DAYS_OF_THE_MONTH,
    layerName: `parking-is-prohibited-on-odd-days-of-the-month-${LAYER_SIGN_PREFIX}`,
    imageName: 'parking-is-prohibited-on-odd-days-of-the-month',
    img: ParkingIsProhibitedOnAddDaysOfTheMonth,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PARKING_IS_PROHIBITED_ON_EVEN_DAYS_OF_THE_MONTH,
    layerName: `parking-is-prohibited-on-even-days-of-the-month-${LAYER_SIGN_PREFIX}`,
    imageName: 'parking-is-prohibited-on-even-days-of-the-month',
    img: ParkingIsProhibitedOnEvenDaysOfTheMonth,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.THE_END_OF_THE_ZONE_OF_ALL_RESTRICTIONS,
    layerName: `the-end-of-the-zone-of-all-restrictions-${LAYER_SIGN_PREFIX}`,
    imageName: 'the-end-of-the-zone-of-all-restrictions',
    img: TheEndOfZoneOfAllRestrictions,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.THE_MOVEMENT_OF_VEHICLES_WITH_DANGEROUS_GOODS_IS_PROHIBITED,
    layerName: `the-movement-of-vehickes-with-dangerous-goods-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'the-movement-of-vehickes-with-dangerous-goods-is-prohibited',
    img: TheMovementWithDangerousGoodsIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.THE_MOVEMENT_OF_VEHICLES_WITH_EXPLOSIVE_AND_FLAMMABLE_CARGO_IS_PROHIBITED,
    layerName: `the-movement-of-vehicles-with-explosive-and-flammable-cargo-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'the-movement-of-vehicles-with-explosive-and-flammable-cargo-is-prohibited',
    img: TheMovementWithDangerousAndFlamableGoodsIsProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.BUS_TRAFFIC_IS_PROHIBITTED,
    layerName: `bus-traffic-is-prohibitted-${LAYER_SIGN_PREFIX}`,
    imageName: 'bus-traffic-is-prohibitted',
    img: BusTrafficIsProhibitted,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DRIVING_ON_PERSONAL_MOBILITY_DEVICES_IS_PROHIBITED,
    layerName: `driving-on-personal-mobility-device-is-prohibited-${LAYER_SIGN_PREFIX}`,
    imageName: 'driving-on-personal-mobility-device-is-prohibited',
    img: DrivingOnPersonalMobiltyProhibited,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.GO_STRAIGHT,
    layerName: `go-straight-${LAYER_SIGN_PREFIX}`,
    imageName: 'go-straight',
    img: GoStraight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MOVEMENT_TO_THE_RIGHT,
    layerName: `movement-to-the-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'movement-to-the-right',
    img: MovementToTheRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MOVEMENT_TO_THE_LEFT,
    layerName: `movement-to-the-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'movement-to-the-left',
    img: MovementToTheLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DRIVING_STRAIGHT_OR_RIGHT,
    layerName: `driving-straignt-or-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'driving-straight-or-right',
    img: DrivingStraightOrRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DRIVING_STRAIGHT_OR_LEFT,
    layerName: `driving-straight-or-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'driving-straight-or-left',
    img: DrivingStraightOrLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MOVING_RIGHT_OR_LEFT,
    layerName: `moving-right-or-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'moving-right-or-left',
    img: MovingRightOrLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.AVODING_OBSTACLES_ON_THE_RIGHT,
    layerName: `avoding-obstacles-on-the-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'avoding-obstacles-on-the-right',
    img: AvodingObstaclesOnTheRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.AVODING_OBSTACLES_ON_THE_LEFT,
    layerName: `avoding-obstacles-on-the-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'avoding-obstacles-on-the-left',
    img: AvodingObstaclesOnTheLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROUNDABOUT_CIRCULATION,
    layerName: `roundabout-circulation-${LAYER_SIGN_PREFIX}`,
    imageName: 'roundabout-circulation',
    img: RoundaboutCirculation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.BIKE_LAKE,
    layerName: `bike-lake-${LAYER_SIGN_PREFIX}`,
    imageName: 'bike-lake',
    img: BikeLake,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_OF_THE_BIKE_PATH,
    layerName: `end-of-the-bike-path-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-of-the-bike-path',
    img: EndOfTheBikePath,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.FOOTPATH,
    layerName: `footpath-${LAYER_SIGN_PREFIX}`,
    imageName: 'footpath',
    img: Footpath,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PEDESTRIAN_AND_BICYCLE_COMBINED,
    layerName: `pedestrian-and-bicycle-combined-${LAYER_SIGN_PREFIX}`,
    imageName: 'pedestrian-and-bicycle-combined',
    img: PedestrianAndBicycleCombined,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_PEDESTRIAN_AND_BICYCLE_COMBINED,
    layerName: `end-pedestrian-and-bicycle-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-pedestrian-and-bicycle',
    img: EndPedestrianAndBicycle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PEDESTRIAN_AND_BICYCLE_SEPARATION_LEFT,
    layerName: `pedestrian-and-bicycle-separation-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'pedestrian-and-bicycle-separation-left',
    img: PedestrianAndBycicleSeparationLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PEDESTRIAN_AND_BICYCLE_SEPARATION_RIGHT,
    layerName: `pedestrian-and-bicycle-separation-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'pedestrian-and-bicycle-separation-right',
    img: PedestrianAndBycicleSeparationRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_PEDESTRIAN_AND_BICYCLE_SEPARATION_LEFT,
    layerName: `end-pedestrian-and-bicycle-separation-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-pedestrian-and-bicycle-separation-left',
    img: EndPedestrianAndBycicleSeparationRLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_PEDESTRIAN_AND_BICYCLE_SEPARATION_RIGHT,
    layerName: `end-pedestrian-and-bicycle-separation-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-pedestrian-and-bicycle-separation-right',
    img: EndPedestrianAndBycicleSeparationRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MINIMUM_SPEED_LIMIT,
    layerName: `minimum-speed-limit-${LAYER_SIGN_PREFIX}`,
    imageName: 'minimum-speed-limit',
    img: MinimumSpeedLimit,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'minimum-speed-limit'
  },
  {
    gost: SignGost.END_MINIMUM_SPEED_LIMIT,
    layerName: `end-minimum-speed-limit-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-minimum-speed-limit',
    img: EndOfMinimumSpeedLimit,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'end-minimum-speed-limit'
  },
  {
    gost: SignGost.DIRECTION_STRAIGHT_WHITH_DANGEROUS_GOODS,
    layerName: `direction-straight-with-dangerous-goods-${LAYER_SIGN_PREFIX}`,
    imageName: 'direction-straight-with-dangerous-goods',
    img: DirectionStraightWithDangerousGoods,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DIRECTION_RIGHT_WHITH_DANGEROUS_GOODS,
    layerName: `direction-right-with-dangerous-goods-${LAYER_SIGN_PREFIX}`,
    imageName: 'direction-right-with-dangerous-goods',
    img: DirectionRightWithDangerousGoods,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DIRECTION_LEFT_WHITH_DANGEROUS_GOODS,
    layerName: `direction-left-with-dangerous-goods-${LAYER_SIGN_PREFIX}`,
    imageName: 'direction-left-with-dangerous-goods',
    img: DirectionLeftWithDangerousGoods,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MOTORWAY,
    layerName: `motorway-${LAYER_SIGN_PREFIX}`,
    imageName: 'motorway',
    img: Motorway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_MOTORWAY,
    layerName: `end-motorway-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-motorway',
    img: EndMotorway,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROAD_FOR_CARS,
    layerName: `road-for-cars-${LAYER_SIGN_PREFIX}`,
    imageName: 'road-for-cars',
    img: RoadForCars,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_ROAD_FOR_CARS,
    layerName: `end-road-for-cars-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-road-for-cars',
    img: EndRoadForCars,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ONE_WAY_ROAD,
    layerName: `one-way-road-${LAYER_SIGN_PREFIX}`,
    imageName: 'one-way-road',
    img: OnewayRoad,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_ONE_WAY_ROAD,
    layerName: `end-one-way-road-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-one-way-road',
    img: EndOnewayRoad,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ENTERING_A_ONE_WAY_ROAD_RIGHT,
    layerName: `entering-a-one-way-road-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'entering-a-one-way-road-right',
    img: EnteringAOneWayRoadRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.ENTERING_A_ONE_WAY_ROAD_LEFT,
    layerName: `entering-a-one-way-road-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'entering-a-one-way-road-left',
    img: EnteringAOneWayRoadLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.REVERSE_MOVEMENT,
    layerName: `reverse-movement-${LAYER_SIGN_PREFIX}`,
    imageName: 'reverse-movement',
    img: ReverseMovement,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_REVERSE_MOVEMENT,
    layerName: `end-reverse-movement-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-reverse-movement',
    img: EndReverseMovement,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ENTERING_WITH_REVERSE_TRAFFIC,
    layerName: `entering-with-reverse-traffic-${LAYER_SIGN_PREFIX}`,
    imageName: 'entering-with-reverse-traffic',
    img: EnteringWithReverseTraffic,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROAD_WITH_LINE_ROUTE,
    layerName: `road-with-line-route-${LAYER_SIGN_PREFIX}`,
    imageName: 'road-with-line-route',
    img: RoadWithLineRoute,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ROAD_WITH_LINE_BICYCLE,
    layerName: `road-with-line-bicycle-${LAYER_SIGN_PREFIX}`,
    imageName: 'road-with-line-bicycle',
    img: RoadWithLineBicycle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_ROAD_WITH_LINE_ROUTE,
    layerName: `end-road-with-line-route-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-road-with-line-route',
    img: EndRoadWithLineRoute,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_ROAD_WITH_LINE_BICYCLE,
    layerName: `end-road-with-line-bicycle-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-road-with-line-bicycle',
    img: EndRoadWithLineBicycle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ENTERING_ROAD_WITH_LINE_ROUTE_LEFT,
    layerName: `entering-road-with-line-route-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'entering-road-with-line-route-left',
    img: EnteringRoadWithLineRouteLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ENTERING_ROAD_WITH_LINE_ROUTE_RIGHT,
    layerName: `entering-road-with-line-route-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'entering-road-with-line-route-right',
    img: EnterinRoadWithLineRouteRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ENTERING_ROAD_WITH_LANE_CYCLISTS_LEFT,
    layerName: `entering-road-with-lane-cyclists-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'entering-road-with-lane-cyclists-left',
    img: EnteringRoadWithLaneCyclistsLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ENTERING_ROAD_WITH_LANE_CYCLISTS_RIGHT,
    layerName: `entering-road-with-lane-cyclists-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'entering-road-with-lane-cyclists-right',
    img: EnteringRoadWithLaneCyclistsRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LINE_FOR_ROUTE_VIHICLES,
    layerName: `line-for-route-vihicles-${LAYER_SIGN_PREFIX}`,
    imageName: 'line-for-route-vihicles',
    img: LineForRouteVihicles,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CYCLIST_LANE,
    layerName: `cyclist-lane-${LAYER_SIGN_PREFIX}`,
    imageName: 'cyclist-lane',
    img: CyclistLane,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_LINE_FOR_ROUTE_VIHICLES,
    layerName: `end-line-for-route-vihicles-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-line-for-route-vihicles',
    img: EndLineForRouteVihicles,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_CYCLIST_LANE,
    layerName: `end-cyclist-lane-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-cyclist-lane',
    img: EndCyclistLane,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LANE_DIRECTIONS,
    layerName: `lane-directions-${LAYER_SIGN_PREFIX}`,
    imageName: 'lane-directions',
    img: LaneDirections,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.LANE_DIRECTIONS_2,
    layerName: `lane-directions-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'lane-directions-2',
    img: LaneDirections2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.START_OF_THE_STRIP_RIGHT,
    layerName: `start-of-the-strip-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'start-of-the-strip-right',
    img: StartOfTheStripRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.START_OF_THE_STRIP_LEFT,
    layerName: `start-of-the-strip-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'start-of-the-strip-left',
    img: StartOfTheStripLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_OF_THE_STRIP_RIGHT,
    layerName: `end-of-the-strip-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-of-the-strip-right',
    img: EndOfTheStripRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_OF_THE_STRIP_LEFT,
    layerName: `end-of-the-strip-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-of-the-strip-left',
    img: EndOfTheStripLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LANE_DIRECTIONS_3,
    layerName: `lane-directions-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'lane-directions-2',
    img: LaneDirections3,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.NUMBER_OF_LANES,
    layerName: `number-of-lanes-${LAYER_SIGN_PREFIX}`,
    imageName: 'number-of-lanes',
    img: NumberOfLanes,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.BUS_STOP_LOCATION,
    layerName: `bus-stop-location-${LAYER_SIGN_PREFIX}`,
    imageName: 'bus-stop-location',
    img: BusStopLocation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TRUM_STOP_LOCATION,
    layerName: `trum-stop-location-${LAYER_SIGN_PREFIX}`,
    imageName: 'trum-stop-location',
    img: TrumStopLocation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TAXI_PARKING_AREA,
    layerName: `taxi-parking-area-${LAYER_SIGN_PREFIX}`,
    imageName: 'taxi-parking-area',
    img: TaxiParkingArea,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CROSSWALK_LEFT,
    layerName: `crosswalk-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'crosswalk-left',
    img: CrosswalkLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CROSSWALK_RIGHT,
    layerName: `crosswalk-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'crosswalk-right',
    img: CrosswalkRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ARTIFICIAL_HUMP,
    layerName: `artificial-hump-${LAYER_SIGN_PREFIX}`,
    imageName: 'artificial-hump',
    img: ArtificialHump,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LIVING_SECTOR,
    className: `living-sector-${LAYER_SIGN_PREFIX}`,
    imageName: 'living-sector',
    img: LivingSector,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_LIVING_SECTOR,
    layerName: `end-living-sector-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-living-sector',
    img: EndLivingSector,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.START_LOCALITY,
    layerName: `start-locality-${LAYER_SIGN_PREFIX}`,
    imageName: 'start-locality',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'start-locality'
  },
  {
    gost: SignGost.START_LOCALITY_UNKNOWN,
    layerName: `start-locality-unknown-${LAYER_SIGN_PREFIX}`,
    imageName: 'start-locality',
    img: StartLocalityUnknown,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.END_LOCALITY,
    layerName: `end-locality-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-locality',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'end-locality'
  },
  {
    gost: SignGost.END_LOCALITY_UNKNOWN,
    layerName: `end-locality-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-locality',
    img: EndLocalityUnknown,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.BEGINNING_SETTLEMENT,
    layerName: `beginning-settlement-${LAYER_SIGN_PREFIX}`,
    imageName: 'beginning-settlement',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'beginning-settlement'
  },
  {
    gost: SignGost.END_SETTLEMENT,
    layerName: `end-settlement-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-settlement',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'end-settlement'
  },
  {
    gost: SignGost.RESTRICTED_PARKING_ZONE,
    layerName: `restricted-parking-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'restricted-parking-zone',
    img: RestrictedParkingZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_RESTRICTED_PARKING_ZONE,
    layerName: `end-restricted-parking-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-restricted-parking-zone',
    img: EndRestrictedParkingZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.REGULATED_PARKING_AREA,
    layerName: `regulated-parking-area-${LAYER_SIGN_PREFIX}`,
    imageName: 'regulated-parking-area',
    img: RegulatedParkingArea,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_REGULATED_PARKING_AREA,
    layerName: `end-regulated-parking-area-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-regulated-parking-area',
    img: EndRegulatedParkingArea,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MAXIMUM_SPEED_ZONE,
    layerName: `maximum-speed-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'maximum-speed-zone',
    img: MaximumSpeedZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'maximum-speed-zone'
  },
  {
    gost: SignGost.END_MAXIMUM_SPEED_ZONE,
    layerName: `end-maximum-speed-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-maximum-speed-zone',
    img: EndMaximumSpeedZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'end-maximum-speed-zone'
  },
  {
    gost: SignGost.PEDESTRIAN_ZONE,
    layerName: `pedestrian-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'pedestrian-zone',
    img: PedestrianZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_PEDESTRIAN_ZONE,
    layerName: `end-pedestrian-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-pedestrian-zone',
    img: EndPedestrianZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ZONE_RESTRICTIONS_ENVIRONMENTAL_MOTOR,
    layerName: `zone-restrictions-environmental-motor-${LAYER_SIGN_PREFIX}`,
    imageName: 'zone-restrictions-environmental-motor',
    img: ZoneRestrictionsEnvironmentalMotor,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_ZONE_RESTRICTIONS_ENVIRONMENTAL_MOTOR,
    layerName: `end-zone-restrictions-environmental-motor-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-zone-restrictions-environmental-motor',
    img: EndZoneRestrictionsEnvironmentalMotor,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ZONE_LIMITED_ENVIRONMENTAL_BY_TYPE,
    layerName: `zone-limited-environmental-by-type-${LAYER_SIGN_PREFIX}`,
    imageName: 'zone-limited-environmental-by-type',
    img: ZoneLimitedEnvironmentalByType,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_ZONE_LIMITED_ENVIRONMENTAL_BY_TYPE,
    layerName: `end-zone-limited-environmental-by-type-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-zone-limited-environmental-by-type',
    img: EndZoneLimitedEnvironmentalByType,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.BICYCLE_ZONE,
    layerName: `bicycle-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'bicycle-zone',
    img: BicycleZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.END_BICYCLE_ZONE,
    layerName: `end-bicycle-zone-${LAYER_SIGN_PREFIX}`,
    imageName: 'end-bicycle-zone',
    img: EndBicycleZone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.GENERAL_MAXIMUM_SPEED_LIMITS,
    layerName: `general-maximum-speed-limits-${LAYER_SIGN_PREFIX}`,
    imageName: 'general-maximum-speed-limits',
    img: GeneralMaximumSpeedLimits,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.RECOMMENDED_SPEED,
    layerName: `recommended-speed-${LAYER_SIGN_PREFIX}`,
    imageName: 'recommended-speed',
    img: RecommendedSpeed,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'recommended-speed'
  },
  {
    gost: SignGost.TURNING_SPACE,
    layerName: `turning-space-${LAYER_SIGN_PREFIX}`,
    imageName: 'turning-space',
    img: TurningSpace,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TURNING_AREA,
    layerName: `turning-area-${LAYER_SIGN_PREFIX}`,
    imageName: 'turning-area',
    img: TurningArea,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'turning-area'
  },
  {
    gost: SignGost.PARKING,
    layerName: `parking-${LAYER_SIGN_PREFIX}`,
    imageName: 'parking',
    img: Parking,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EMERGENCY_STOP_STRIP,
    layerName: `emergency-stop-strip-${LAYER_SIGN_PREFIX}`,
    imageName: 'emergency-stop-strip',
    img: EmergencyStopStrip,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.UNDEGROUND_PEDESTRIAN_CROSSING,
    layerName: `underground-pedestrian-crossing-${LAYER_SIGN_PREFIX}`,
    imageName: 'underground-pedestrian-crossing',
    img: UndergroundPedestrianCrossing,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.OVERHEAD_PEDESTRIAN_CROSSING,
    layerName: `overhead-pedestrian-crossing-${LAYER_SIGN_PREFIX}`,
    imageName: 'overhead-pedestrian-crossing',
    img: OverheadPedestrianCrossing,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DEAD_END_RIGHT,
    layerName: `dead-end-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'dead-end-right',
    img: DeadEndRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DEAD_END_ON_THE_RIGHT,
    layerName: `dead-end-on-the-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'dead-end-on-the-right',
    img: DeadEndOnTheRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DEAD_END_ON_THE_LEFT,
    layerName: `dead-end-on-the-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'dead-end-on-the-left',
    img: DeadEndOnTheLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ADVANCE_DIRECTION_INDICATOR,
    layerName: `advance-direction-indicator-${LAYER_SIGN_PREFIX}`,
    imageName: 'advance-direction-indicator',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'advance-direction-indicator'
  },
  {
    gost: SignGost.ADVANCE_DIRECTION_INDICATOR_2,
    layerName: `advance-direction-indicator-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'advance-direction-indicator',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'advance-direction-indicator-2'
  },
  {
    gost: SignGost.TRAFFIC_PATTERN,
    layerName: `traffic-pattern-${LAYER_SIGN_PREFIX}`,
    imageName: 'traffic-pattern',
    img: TrafficPattern,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DIRECTIONS_INDICATOR,
    layerName: `directions-indicator-${LAYER_SIGN_PREFIX}`,
    imageName: 'directions-indicator',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'advance-direction-indicator-3'
  },
  {
    gost: SignGost.DIRECTIONS_INDICATOR_2,
    layerName: `directions-indicator-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'directions-indicator-2',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'directions-indicator-2'
  },
  {
    gost: SignGost.OBJECT_NAME,
    layerName: `object-name-${LAYER_SIGN_PREFIX}`,
    imageName: 'object-name',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'object-name'
  },
  {
    gost: SignGost.DISTANCE_INDICATOR,
    layerName: `distance-indicator-${LAYER_SIGN_PREFIX}`,
    imageName: 'distance-indicator',
    width: 76,
    height: 32,
    name: 'distance-indicator'
  },
  {
    gost: SignGost.KILOMETER_SIGN,
    layerName: `kilometer-${LAYER_SIGN_PREFIX}`,
    imageName: 'kilometer',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'kilometer'
  },
  {
    gost: SignGost.ROUTE_NUMBER,
    layerName: `route-number-${LAYER_SIGN_PREFIX}`,
    imageName: 'route-number',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'route-number'
  },
  {
    gost: SignGost.ROUTE_NUMBER_2,
    layerName: `route-number-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'route-number-2',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'route-number-2'
  },
  {
    gost: SignGost.DRIVING_DIRECTION_FOR_TRUCKS_STRAIGHT,
    layerName: `driving-direction-for-trucks-straight-${LAYER_SIGN_PREFIX}`,
    imageName: 'driving-direction-for-trucks-straight',
    img: DrivingDirectionForTrucksStraight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.DRIVING_DIRECTION_FOR_TRUCKS_RIGHT,
    layerName: `driving-direction-for-trucks-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'driving-direction-for-trucks-right',
    img: DrivingDirectionForTrucksRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.DRIVING_DIRECTION_FOR_TRUCKS_LEFT,
    layerName: `driving-direction-for-trucks-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'driving-direction-for-trucks-left',
    img: DrivingDirectionForTrucksLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.STOP_LINE,
    layerName: `stop-line-${LAYER_SIGN_PREFIX}`,
    imageName: 'stop-line',
    img: StopLine,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DETOUR_SCHEME,
    layerName: `detour-scheme-${LAYER_SIGN_PREFIX}`,
    imageName: 'detour-scheme',
    img: DetourScheme,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DETOUR_DIRECTION_STRAIGHT,
    layerName: `detour-direction-straight-${LAYER_SIGN_PREFIX}`,
    imageName: 'detour-direction-straight',
    img: DetourSchemeStraight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.DETOUR_DIRECTION_RIGHT,
    layerName: `detour-direction-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'detour-direction-right',
    img: DetourSchemeRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.DETOUR_DIRECTION_LEFT,
    layerName: `detour-direction-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'detour-direction-left',
    img: DetourSchemeLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.PRELIMINARY_INDICTOR_FOR_CHANGING_LINES,
    layerName: `preliminary-indicator-for-changing-lines-${LAYER_SIGN_PREFIX}`,
    imageName: 'preliminary-indicator-for-changing-lines',
    img: PreliminaryIndicatorForChangingLines,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PRELIMINARY_INDICTOR_FOR_CHANGING_LINES_2,
    layerName: `preliminary-indicator-for-changing-lines-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'preliminary-indicator-for-changing-lines-2',
    img: PreliminaryIndicatorForChangingLines2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EMERGENCY_EXIT_LEFT,
    layerName: `emergency-exit-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'emergency-exit-left',
    img: EmergencyExitLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EMERGENCY_EXIT_RIGHT,
    layerName: `emergency-exit-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'emergency-exit-right',
    img: EmergencyExitRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DIRECTION_OF_MOVEMENT_TO_THE_EMERGENCY_EXIT,
    layerName: `direction-of-movement-to-the-emergency-exit-${LAYER_SIGN_PREFIX}`,
    imageName: 'direction-of-movement-to-the-emergency-exit',
    img: DirectionOfMovementToTheEmergencyExit,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT
  },
  {
    gost: SignGost.PHOTO_AND_VIDEO_RECORDING,
    layerName: `photo-and-video-recording-${LAYER_SIGN_PREFIX}`,
    imageName: 'photo-and-video-recording',
    img: PhotoAndVideoRecording,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MEDICAL_AID_STATION,
    layerName: `medical-aid-station-${LAYER_SIGN_PREFIX}`,
    imageName: 'medical-aid-station',
    img: MedicalAidStation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.HOSPITAL,
    layerName: `hospital-${LAYER_SIGN_PREFIX}`,
    imageName: 'hospital',
    img: Hospital,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.GAS_STATION,
    layerName: `gas-station-${LAYER_SIGN_PREFIX}`,
    imageName: 'gas-station',
    img: GasStation,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CAR_MAINTENANCE,
    layerName: `car-maintenance-${LAYER_SIGN_PREFIX}`,
    imageName: 'car-maintenance',
    img: CarMaintenance,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CAR_WASH,
    layerName: `car-wash-${LAYER_SIGN_PREFIX}`,
    imageName: 'car-wash',
    img: CarWah,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PHONE,
    layerName: `phone-${LAYER_SIGN_PREFIX}`,
    imageName: 'phone',
    img: Phone,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.FOOD_POINT,
    layerName: `food-point-${LAYER_SIGN_PREFIX}`,
    imageName: 'food-point',
    img: FoodPoint,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DRINKING_WATER,
    layerName: `drinking-water-${LAYER_SIGN_PREFIX}`,
    imageName: 'drinking-water',
    img: DrinkingWater,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.HOTEL,
    layerName: `hotel-${LAYER_SIGN_PREFIX}`,
    imageName: 'hotel',
    img: Hotel,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CAMPING,
    layerName: `camping-${LAYER_SIGN_PREFIX}`,
    imageName: 'camping',
    img: Camping,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.REST_PLACE,
    layerName: `rest-place-${LAYER_SIGN_PREFIX}`,
    imageName: 'rest-place',
    img: RestPlace,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DPS,
    layerName: `dps-${LAYER_SIGN_PREFIX}`,
    imageName: 'dps',
    img: DPS,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.POLICE,
    layerName: `police-${LAYER_SIGN_PREFIX}`,
    imageName: 'police',
    img: Police,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.CUSTOMS_CONTROL_POINT,
    layerName: `customs-control-point-${LAYER_SIGN_PREFIX}`,
    imageName: 'customs-control-point',
    img: CustomControlPoint,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TRANSPORT_CONTROL_POINT,
    layerName: `transport-control-point-${LAYER_SIGN_PREFIX}`,
    imageName: 'transport-control-point',
    img: TransportControlPoint,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.RECEPTION_AREA_OF_RADIO,
    layerName: `reception-area-of-radio-${LAYER_SIGN_PREFIX}`,
    imageName: 'reception-area-of-radio',
    img: ReceptionAreaOfRadio,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.RADIO_COMMUNICATION_AREA_WITH_EMERGENCY_SERVICES,
    layerName: `radio-communication-area-with-emergency-services-${LAYER_SIGN_PREFIX}`,
    imageName: 'radio-communication-area-with-emergency-services',
    img: RadioCommunicationAreaWithEmergencyServices,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.POOL_OR_BEACH,
    layerName: `pool-or-beach-${LAYER_SIGN_PREFIX}`,
    imageName: 'pool-or-beach',
    img: PoolOrBeach,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.WC,
    layerName: `wc-${LAYER_SIGN_PREFIX}`,
    imageName: 'wc',
    img: Wc,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EMERGENCY_PHONE_NUMBER,
    layerName: `emergency-phone-number-${LAYER_SIGN_PREFIX}`,
    imageName: 'emergency-phone-number',
    img: EmergencyPhoneNumber,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.FIRE_EXTINGUISHER,
    layerName: `fire-extinguisher-${LAYER_SIGN_PREFIX}`,
    imageName: 'fire-extinguisher',
    img: FireExtinguisher,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.GAS_STATION_WITH_ELECTRIC,
    layerName: `gas-station-with-electric-${LAYER_SIGN_PREFIX}`,
    imageName: 'gas-station-with-electric',
    img: GasStationWithElectric,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DISTANCE_TO_OBJECT,
    layerName: `distance-to-object-${LAYER_SIGN_PREFIX}`,
    imageName: 'distance-to-object',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'distance-to-object'
  },
  {
    gost: SignGost.DISTANCE_TO_OBJECT_2,
    layerName: `distance-to-object-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'distance-to-object-2',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'distance-to-object-2'
  },
  {
    gost: SignGost.DISTANCE_TO_OBJECT_RIGHT,
    layerName: `distance-to-object-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'distance-to-object-right',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'distance-to-object-right'
  },
  {
    gost: SignGost.DISTANCE_TO_OBJECT_LEFT,
    layerName: `distance-to-object-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'distance-to-object-left',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'distance-to-object-left'
  },
  {
    gost: SignGost.COVERAGE_AREA,
    layerName: `coverage-area-${LAYER_SIGN_PREFIX}`,
    imageName: 'coverage-area',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'coverage-area'
  },
  {
    gost: SignGost.COVERAGE_AREA_2,
    layerName: `coverage-area-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'coverage-area-2',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'coverage-area-2'
  },
  {
    gost: SignGost.COVERAGE_AREA_END,
    layerName: `coverage-area-end-${LAYER_SIGN_PREFIX}`,
    imageName: 'coverage-area-end',
    img: CoverageAreaEnd,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.COVERAGE_AREA_CONTINUE,
    layerName: `coverage-area-continue-${LAYER_SIGN_PREFIX}`,
    imageName: 'coverage-area-continue',
    img: CoverageAreaContinue,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.COVERAGE_AREA_RIGHT,
    className: `coverage-area-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'coverage-area-right',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'coverage-area-right'
  },
  {
    gost: SignGost.COVERAGE_AREA_LEFT,
    layerName: `coverage-area-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'coverage-area-left',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'coverage-area-left'
  },
  {
    gost: SignGost.DIRECTION_OF_ACTION_RIGHT,
    layerName: `direction-of-action-right-${LAYER_SIGN_PREFIX}`,
    imageName: 'direction-action-right',
    img: DirectionActionRight,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DIRECTION_OF_ACTION_LEFT,
    layerName: `direction-of-action-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'direction--action-left',
    img: DirectionActionLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DIRECTION_OF_ACTION_CONTINUE,
    layerName: `direction-of-action-continue-${LAYER_SIGN_PREFIX}`,
    imageName: 'direction-of-action-continue',
    img: DirectionActionContinue,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_TRUCK,
    layerName: `type-vehicle-truck-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-truck',
    img: TypeVehicleTruck,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_TRAILER,
    layerName: `type-vehicle-trailer-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-trailer',
    img: TypeVehicleTrailer,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_AUTO,
    layerName: `type-vehicle-auto-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-auto',
    img: TypeVehicleAuto,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_ELECTRO,
    layerName: `type-vehicle-electro-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-electro',
    img: TypeVehicleElectro,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_BUS,
    layerName: `type-vehicle-bus-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-bus',
    img: TypeVehicleBus,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_TRACTOR,
    layerName: `type-vehicle-tractor-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-tractor',
    img: TypeVehicleTractor,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_MOTO,
    layerName: `type-vehicle-moto-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-moto',
    img: TypeVehicleMoto,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_BICYCLE,
    layerName: `type-vehicle-bicycle-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-bicycle',
    img: TypeVehicleBicycle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_DANGER,
    layerName: `type-vehicle-danger-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-danger',
    img: TypeVehicleDanger,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_VEHICLE_SCOOTER,
    layerName: `type-vehicle-scooter-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-vehicle-scooter',
    img: TypeVehicleScooter,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_AUTO,
    layerName: `except-type-vehicle-auto-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-auto',
    img: ExceptTypeVehicleAuto,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_BUS,
    layerName: `except-type-vehicle-bus-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-bus',
    img: ExceptTypeVehicleBus,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },

  {
    gost: SignGost.MAIN_ROAD_DIRECTION,
    layerName: `main-road-direction-${LAYER_SIGN_PREFIX}`,
    imageName: 'main-road-direction',
    img: MainRoadDirection,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.AVODING_OBSTACLES_ON_THE_RIGHT_OR_LEFT,
    layerName: `avoding-obstacles-on-the-right-or-left-${LAYER_SIGN_PREFIX}`,
    imageName: 'avoding-obstacles-on-the-right-or-left',
    img: AvodingObstaclesOnTheRightOrLeft,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },

  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_TRUCK,
    layerName: `except-type-vehicle-truck-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-truck',
    img: ExceptTypeVehicleTruck,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_MOTO,
    layerName: `except-type-vehicle-moto-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-moto',
    img: ExceptTypeVehicleMoto,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_BICYCLE,
    layerName: `except-type-vehicle-bicycle-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-bicycle',
    img: ExceptTypeVehicleBicycle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_TAXI,
    layerName: `except-type-vehicle-taxi-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-taxi',
    img: ExceptTypeVehicleTaxi,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_ELECTRO,
    layerName: `except-type-vehicle-electro-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-electro',
    img: ExceptTypeVehicleElectro,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_TYPE_VEHICLE_SCOOTER,
    layerName: `except-type-vehicle-scooter-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-type-vehicle-scooter',
    img: ExceptTypeVehicleScooter,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.WEEKEND,
    layerName: `weekend-${LAYER_SIGN_PREFIX}`,
    imageName: 'weekend',
    img: Weekend,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.WORK_DAYS,
    layerName: `work-days-${LAYER_SIGN_PREFIX}`,
    imageName: 'work-days',
    img: WorkDays,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DAYS_OF_THE_WEEK,
    layerName: `days-of-the-week-${LAYER_SIGN_PREFIX}`,
    imageName: 'days-of-the-week',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'days-of-the-week'
  },
  {
    gost: SignGost.TIME_OF_ACTION,
    layerName: `time-of-action-${LAYER_SIGN_PREFIX}`,
    imageName: 'time-of-action',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'time-of-action'
  },
  {
    gost: SignGost.WEEKEND_TIME,
    layerName: `weekend-time-${LAYER_SIGN_PREFIX}`,
    imageName: 'weekend-time',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'weekend-time'
  },
  {
    gost: SignGost.WORK_DAYS_TIME,
    layerName: `work-days-time-${LAYER_SIGN_PREFIX}`,
    imageName: 'work-days-time',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'work-days-time'
  },
  {
    gost: SignGost.DAYS_OF_THE_WEEK_TIME,
    layerName: `days-of-the-week-time-${LAYER_SIGN_PREFIX}`,
    imageName: 'days-of-the-week-time',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'days-of-the-week-time'
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE,
    layerName: `method-of-parking-a-vehicle-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle',
    img: MethodOfParkingAVehicle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_2,
    layerName: `method-of-parking-a-vehicle-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle-2',
    img: MethodOfParkingAVehicle2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_3,
    layerName: `method-of-parking-a-vehicle-3-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle-3',
    img: MethodOfParkingAVehicle3,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_4,
    layerName: `method-of-parking-a-vehicle-4-${LAYER_SIGN_PREFIX}`,
    imgName: 'method-of-parking-a-vehicle-4',
    img: MethodOfParkingAVehicle4,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_5,
    layerName: `method-of-parking-a-vehicle-5-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle-5',
    img: MethodOfParkingAVehicle5,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_6,
    layerName: `method-of-parking-a-vehicle-6-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle-6',
    img: MethodOfParkingAVehicle6,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_7,
    layerName: `method-of-parking-a-vehicle-7-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle-7',
    img: MethodOfParkingAVehicle7,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_8,
    layerName: `method-of-parking-a-vehicle-8-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle-8',
    img: MethodOfParkingAVehicle8,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.METHOD_OF_PARKING_A_VEHICLE_9,
    layerName: `method-of-parking-a-vehicle-9-${LAYER_SIGN_PREFIX}`,
    imageName: 'method-of-parking-a-vehicle-9',
    img: MethodOfParkingAVehicle9,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PARKING_WITH_THE_ENGINE_NOT_RUNNING,
    layerName: `parking-with-the-engine-not-running-${LAYER_SIGN_PREFIX}`,
    imageName: 'parking-with-the-engine-not-running',
    img: ParkingWithTheEngineNotRunning,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PAID_SERVICES,
    layerName: `paid-services-${LAYER_SIGN_PREFIX}`,
    imageName: 'paid-services',
    img: PaidServices,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PARKING_DURATION_LIMITATION,
    layerName: `parking-duration-limitation-${LAYER_SIGN_PREFIX}`,
    imageName: 'parking-duration-limitation',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'parking-duration-limitation'
  },
  {
    gost: SignGost.PARKING_FOR_PERMIT_HOLDERS_ONLY,
    layerName: `parking-for-permit-holders-only-${LAYER_SIGN_PREFIX}`,
    imageName: 'parking-for-permit-holders-only',
    img: ParkingForPermitHoldersOnly,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.PARKING_FOR_DIPLOMATIC_VEHICLES_ONLY,
    layerName: `parking-for-diplomatioc-vehicles-only-${LAYER_SIGN_PREFIX}`,
    imageName: 'parking-for-diplomatioc-vehicles-only',
    img: ParkingForDiplomatiocVehiclesOnly,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.VEHICLE_INSPECTION_AREA,
    layerName: `vehicle-inspection-area-${LAYER_SIGN_PREFIX}`,
    imageName: 'vehicle-inspection-area',
    img: VehicleInspectionArea,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.MAXIMUM_WEIGHT_LIMIT,
    layerName: `maximum-weight-limit-${LAYER_SIGN_PREFIX}`,
    imageName: 'maximum-weight-limit',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'maximum-weight-limit'
  },
  {
    gost: SignGost.DANGEROUS_ROADSIDE_ADDITIONAL,
    layerName: `dangerous-roadside-additional-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous-roadside-additional',
    img: DangerousRoadsideAdditional,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LANE,
    layerName: `lane-${LAYER_SIGN_PREFIX}`,
    imageName: 'lane',
    img: Lane,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.BLIND_PEDESTRIANS,
    layerName: `blind-pedestrians-${LAYER_SIGN_PREFIX}`,
    imageName: 'blind-pedestrians',
    img: BlindPedestrians,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.WET_COATING,
    layerName: `wet-coating-${LAYER_SIGN_PREFIX}`,
    imageName: 'wet-coating',
    img: WetCoating,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DISABLED_PEOPLE,
    layerName: `disabled-people-${LAYER_SIGN_PREFIX}`,
    imageName: 'disabled-people',
    img: DisabledPeople,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.EXCEPT_DISABLED_PEOPLE,
    layerName: `except-disabled-people-${LAYER_SIGN_PREFIX}`,
    imageName: 'except-disabled-people',
    img: ExceptDisabledPeople,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.DANGEROUS_GOODS_CLASS,
    layerName: `dangerous-goods-class-${LAYER_SIGN_PREFIX}`,
    imageName: 'dangerous-goods-class',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT,
    name: 'dangerous-goods-class'
  },
  {
    gost: SignGost.VEHICLE_BOGIE_TYPE,
    layerName: `vehicle-bogie-type-${LAYER_SIGN_PREFIX}`,
    imageName: 'vehicle-bogie-type',
    img: VehicleBoogieType,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.VEHICLE_BOGIE_TYPE_2,
    layerName: `vehicle-bogie-type-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'vehicle-bogie-type-2',
    img: VehicleBoogieType2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_OF_ROUTE_VEHICLE,
    layerName: `type-of-route-vehicle-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-of-route-vehicle',
    img: TypeOfRouteVehicle,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_OF_ROUTE_VEHICLE_2,
    layerName: `type-of-route-vehicle-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-of-route-vehicle-2',
    img: TypeOfRouteVehicle2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.TYPE_OF_ROUTE_VEHICLE_3,
    layerName: `type-of-route-vehicle-3-${LAYER_SIGN_PREFIX}`,
    imageName: 'type-of-route-vehicle-3',
    img: TypeOfRouteVehicle3,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LET,
    layerName: `let-${LAYER_SIGN_PREFIX}`,
    imageName: 'let',
    img: Let,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LET_2,
    layerName: `let-2-${LAYER_SIGN_PREFIX}`,
    imageName: 'let-2',
    img: Let2,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.LET_3,
    layerName: `let-3-${LAYER_SIGN_PREFIX}`,
    imageName: 'let-3',
    img: Let3,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.A_TOW_TRUCK_IS_WORKING,
    layerName: `a-tow-truck-is-working-${LAYER_SIGN_PREFIX}`,
    imageName: 'a-tow-truck-is-working',
    img: TowTruckIsWorking,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  },
  {
    gost: SignGost.ECOLOGICAL_CLASS_OF_THE_VEHICLE,
    layerName: `ecological-class-of-the-vehicle-${LAYER_SIGN_PREFIX}`,
    imageName: 'ecological-class-of-the-vehicle',
    width: GEOMETRY_CONSTANTS.BOX_TYPE_2,
    height: HEIGHT,
    name: 'ecological-class-of-the-vehicle'
  },
  {
    gost: SignGost.CHARGING_ELECTRIC_VEHICLES,
    layerName: `charging-electric-vehicles-${LAYER_SIGN_PREFIX}`,
    imageName: 'charging-electric-vehicles',
    img: ChargingElectricVehicles,
    width: GEOMETRY_CONSTANTS.BOX_TYPE_1,
    height: HEIGHT
  }
]
